import React, {Component} from 'react'
import {Button, Card, Col, DatePicker, Popover, Row, Form, Modal, Select} from "antd"
import {CalendarOutlined} from "@ant-design/icons"
import dayjs from "dayjs"

class RangeDate extends Component {
    constructor(props) {
        super(props)
        this.initState = {
            openPopOver: false,
            dateType: "CUSTOM",
            showRangeDate: true,
            startDate: dayjs(),
            endDate: dayjs(),
        }
        this.state = {...this.initState}
    }

    async componentDidMount() {
        this.fillComponent()
    }

    componentDidUpdate(prevProps) {
        if ((this.props?.startDate !== prevProps?.startDate) || (this.props?.endDate !== prevProps?.endDate)) {
            this.fillComponent()
        }
    }

    fillComponent = () => {
        this.setState({
            startDate: this.props.startDate,
            endDate: this.props.endDate,
        })
    }

    handleOpenChange = (newOpen) => {
        this.setState({openPopOver: newOpen})
    }

    applyChange = () => {
        if (this.state.endDate.isBefore(this.state.startDate)) {
            return Modal.error({title: 'Error', content: 'End date cannot be earlier than the start date'})
        }

        this.props.formChildAction({startDate: this.state.startDate, endDate: this.state.endDate})
        this.setState({openPopOver: false})
    }

    onFieldChange = (target, value) => {
        const currentState = {...this.state}
        switch (target) {
            case "startDate":
                if (value) {
                    currentState.startDate = value
                }
                break
            case "endDate":
                if (value) {
                    currentState.endDate = value
                }
                break
            case "dateType":
                currentState.dateType = value
                if (value === "TODAY") {
                    currentState.startDate = dayjs()
                    currentState.endDate = dayjs()
                    currentState.showRangeDate = false
                }
                if (value === "YESTERDAY") {
                    currentState.startDate = dayjs().subtract(1, 'day')
                    currentState.endDate = dayjs().subtract(1, 'day')
                    currentState.showRangeDate = false
                }
                if (value === "THISWEEK") {
                    currentState.startDate = dayjs().subtract(7, 'day')
                    currentState.endDate = dayjs()
                    currentState.showRangeDate = false
                }
                if (value === "THISMONTH") {
                    currentState.startDate = dayjs().date(1)
                    currentState.endDate = dayjs()
                    currentState.showRangeDate = false
                }
                if (value === "PREVMONTH") {
                    currentState.startDate = dayjs().subtract(1, 'month').date(1)
                    currentState.endDate = dayjs().subtract(1, 'month').endOf('month')
                    currentState.showRangeDate = false
                }
                if (value === "CUSTOM") {
                    currentState.showRangeDate = true
                }
                break
            default:
                break
        }
        this.setState({
            ...currentState,
        })
    }

    render() {
        return (
            <>
                <Popover
                    placement="bottom"
                    trigger="click"
                    open={this.state.openPopOver}
                    onOpenChange={this.handleOpenChange}
                    content={(
                        <div style={{width: 280}}>
                            <Row className={`mb-20`}>
                                <Col span={12}>
                                    <Button size={"small"} onClick={() => this.setState({openPopOver: false})}>Cancel</Button>
                                </Col>
                                <Col span={12} style={{textAlign: "right"}}>
                                    <Button onClick={this.applyChange} type={`primary`} size={"small"}>Apply</Button>
                                </Col>
                            </Row>
                            <Form.Item>
                                <i className={`extra-small-text-grey`}>Date</i><br/>
                                <Select
                                    onChange={value => this.onFieldChange("dateType", value)}
                                    value={this.state.dateType}
                                    style={{width: "100%"}}
                                    placeholder={`Date`}
                                >
                                    <Select.Option key={`CUSTOM`} value={`CUSTOM`}><CalendarOutlined/> Custom Range</Select.Option>
                                    <Select.Option key={`TODAY`} value={`TODAY`}><CalendarOutlined/> Today</Select.Option>
                                    <Select.Option key={`YESTERDAY`} value={`YESTERDAY`}><CalendarOutlined/> Yetserday</Select.Option>
                                    <Select.Option key={`THISWEEK`} value={`THISWEEK`}><CalendarOutlined/> Last 7 Days</Select.Option>
                                    <Select.Option key={`THISMONTH`} value={`THISMONTH`}><CalendarOutlined/> This Month</Select.Option>
                                    <Select.Option key={`PREVMONTH`} value={`PREVMONTH`}><CalendarOutlined/> Previous Month</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item style={this.state.showRangeDate ? {} : {display: 'none'}}>
                                <i className={`extra-small-text-grey`}>Start Date</i><br/>
                                <DatePicker value={dayjs(this.state.startDate, 'YYYY-MM-DD')} onChange={event => this.onFieldChange("startDate", event)} format={'YYYY-MM-DD'} style={{width: "100%"}}/>
                            </Form.Item>
                            <Form.Item style={this.state.showRangeDate ? {} : {display: 'none'}}>
                                <i className={`extra-small-text-grey`}>End Date</i><br/>
                                <DatePicker value={dayjs(this.state.endDate, 'YYYY-MM-DD')} onChange={event => this.onFieldChange("endDate", event)} format={'YYYY-MM-DD'} style={{width: "100%"}}/>
                            </Form.Item>
                        </div>
                    )}>
                    <Card bodyStyle={{padding: 5, textAlign: "center"}} size="small" hoverable={true}>
                        <span className={`small-text-grey`}> <CalendarOutlined/> {dayjs(this.props.startDate).format("YYYY/MM/DD")} - {dayjs(this.props.endDate).format("YYYY/MM/DD")}</span>
                    </Card>
                </Popover>
            </>
        )
    }
}

export default RangeDate