import ExcelJS from 'exceljs'
import {saveAs} from 'file-saver'

export default async function exportExcel(params) {
    const workbook = new ExcelJS.Workbook()
    const worksheet = workbook.addWorksheet(params.sheetName)

    const titleCell = worksheet.getCell('A1')
    titleCell.value = params?.title
    titleCell.alignment = {horizontal: 'center'}
    const widthLength = numberToAlphabet(params.data[1].length)
    worksheet.mergeCells(`A1:${widthLength}1`)

    const dateCell = worksheet.getCell('A2')
    dateCell.value = params.generatedTime
    dateCell.alignment = {horizontal: 'right'}
    dateCell.font = {size: 10}
    worksheet.mergeCells(`A2:${widthLength}2`)

    params.data.forEach((row, rowIndex) => {
        row.forEach((value, colIndex) => {
            worksheet.getCell(rowIndex + 3, colIndex + 1).value = value
        })
    })

    const buffer = await workbook.xlsx.writeBuffer()
    const dataBlob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
    saveAs(dataBlob, `${params.fileName}.xlsx`)
}

function numberToAlphabet(number) {
    if (typeof number !== 'number' || isNaN(number) || number < 1 || !Number.isInteger(number)) {
        return 'Invalid input'
    }
    const baseCharCode = 'A'.charCodeAt(0) - 1
    const alphabetValue = number % 26 || 26
    return String.fromCharCode(baseCharCode + alphabetValue)
}