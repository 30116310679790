import React, {Component} from 'react'
import {Buffer} from "buffer"
import "./Login.css"
import {Layout, Button, Form, Input, Modal} from "antd"
import {LockOutlined, MailOutlined} from "@ant-design/icons"
import Cookies from 'universal-cookie'
import {withRouter} from "../../hoc/withRouter"
import {appVersion} from "../../config/appConfig"
import {kioskApiService} from "../../services/wiloKiosk/KioskApiService"

const {Footer} = Layout

class Login extends Component {
    constructor(props) {
        super(props)

        this.cookies = new Cookies()
        this.initState = {
            processing: false,
            captchaSuccess: false,
            password: "",
            email: "",
        }

        this.state = {...this.initState}
    }

    onFieldChange = (target, value) => {
        const currentState = {...this.state}
        switch (target) {
            case "email":
                currentState.email = value
                break
            case "password":
                currentState.password = value
                break
            default:
                break
        }
        this.setState({
            ...currentState,
        })
    }

    submit = async () => {
        await this.setState({
            processing: true,
        })

        try {
            const login = await kioskApiService.login({
                email: this.state.email,
                password: this.state.password
            })

            const tokenExpirationDays = login.data.data.expiration_days
            const tokenExpirationDate = new Date()
            tokenExpirationDate.setDate(tokenExpirationDate.getDate() + tokenExpirationDays)
            this.cookies.set('_wk-t', Buffer.from(JSON.stringify(login.data.data.token)).toString('base64'), {
                expires: tokenExpirationDate,
            })
            this.cookies.set('_wk-e', Buffer.from(JSON.stringify(login.data.data.email)).toString('base64'))
            this.cookies.set('_wk-n', Buffer.from(JSON.stringify(login.data.data.name)).toString('base64'))
            this.cookies.set('_wk-l', Buffer.from(JSON.stringify(login.data.data.admin_level)).toString('base64'))
            this.cookies.set('_wk-m', Buffer.from(JSON.stringify(login.data.data.admin_menu)).toString('base64'))

            this.setState({
                processing: false,
            })

            this.props.history.push('/')
        } catch (e) {
            Modal.error({
                title: 'Login Failed',
                content: e,
            })
            this.setState({
                processing: false,
            })
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="login-page">
                    <div className="login-box">
                        <div className="illustration-wrapper">
                            <img src="/image/login.png" alt="Login"/>
                        </div>
                        <Form name="login-form" initialValues={{remember: true}}>
                            <img alt={`logo`} style={{height: 60}} src={`/image/logo.png`}/>
                            <p>Login to Wilo-Water-Kiosk</p>

                            <Form.Item>
                                <Input prefix={<MailOutlined/>} placeholder={'Email'} value={this.state.email} onChange={event => this.onFieldChange("email", event.target.value)}/>
                            </Form.Item>
                            <Form.Item>
                                <Input prefix={<LockOutlined/>} type={'password'} placeholder={'Password'} value={this.state.password} onChange={event => this.onFieldChange("password", event.target.value)}/>
                            </Form.Item>

                            <div style={{margin: "25px 0"}}>
                                <Button onClick={this.submit} loading={this.state.processing} type={'primary'} htmlType={'submit'}> Login</Button>
                            </div>
                        </Form>
                    </div>
                </div>
                <Footer style={{textAlign: "center", backgroundColor: "#f3f3f3", height: "9vh"}}>
                    Wilo Water Kiosk ©{new Date().getFullYear()}
                    <br/>
                    v-{appVersion}
                </Footer>
            </React.Fragment>
        )
    }
}

export default withRouter(Login)