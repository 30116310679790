import React, {Component} from 'react'
import Cookies from 'universal-cookie'
import {Buffer} from "buffer"
import withAuth from "../../hoc/withAuth"
import MainLayout from "../../components/layout/MainLayout"
import {Alert, Button, Divider, message, Modal, Table} from "antd"
import {kioskApiService} from "../../services/wiloKiosk/KioskApiService"
import currencyFormat from "../../utils/currencyFormat";

class Refund extends Component {
    constructor(props) {
        super(props)
        this.cookies = new Cookies()
        this.initState = {
            token: (this.cookies.get('_wk-t')) ? JSON.parse(Buffer.from(this.cookies.get('_wk-t'), 'base64').toString()) : '',
            fetchProcessing: true,
            tableData: [],
            rf_transactionId: "",
            rf_transactionCode: "",
            showModalForm: false,
            showModalManualRefundForm: false,
            loadingRefundConfirm: false
        }
        this.state = {...this.initState}
    }

    fetchData = async () => {
        await this.setState({
            fetchProcessing: true,
            tableData: []
        })
        try {
            await kioskApiService.setToken(this.state.token)
            const locationData = await kioskApiService.readListRefund()
            this.setState({
                fetchProcessing: false,
                tableData: locationData.data.data
            })
        } catch (e) {
            this.setState({
                fetchProcessing: false,
                tableData: [],
            })
            message.info(e)
        }
    }

    confirmManualRefund = async () => {
        await this.promisedSetState({
            loadingRefundConfirm: true,
        })

        try {
            await kioskApiService.doManualRefund({
                transaction_code: this.state.rf_transactionCode
            })
            Modal.success({title: 'Refund Success', content: 'Refund Success'})
            await this.fetchData()
            await this.setState({
                loadingRefundConfirm: false,
                showModalForm: false,
                showModalManualRefundForm: false
            })
        } catch (e) {
            this.setState({loadingRefundConfirm: false})
            return Modal.error({title: 'Refund Failed', content: e})
        }
    }

    confirmRefund = async () => {
        await this.promisedSetState({
            loadingRefundConfirm: true,
        })

        try {
            await kioskApiService.doRefund({
                transaction_code: this.state.rf_transactionCode
            })
            Modal.success({title: 'Refund Success', content: 'Refund Success'})
            await this.fetchData()
            await this.setState({
                loadingRefundConfirm: false,
                showModalForm: false,
                showModalManualRefundForm: false
            })
        } catch (e) {
            this.setState({loadingRefundConfirm: false})
            await this.fetchData()
            return Modal.error({title: 'Refund Failed', content: e})
        }
    }

    async componentDidMount() {
        await this.fetchData()
    }

    promisedSetState = (newState) => new Promise((resolve) => this.setState(newState, resolve))

    render() {
        return (
            <MainLayout titlePage={`Refund`} fullWidth={true} breadcrumbPath={`Refund`} breadcrumbMenu={`/ Refund`} defaultSelectedKeys={['m-refund']} defaultOpenKeys={['sm-refund']}>
                <Alert message="Warning" description="This menu will only display a list of transactions that have made payments, but there have been failures in the filling process. Transactions that have successfully reached the filling level will not appear on this menu." type="warning" showIcon closable style={{marginTop: 10, marginBottom: 30}}/>
                <Table
                    size="small"
                    rowKey={`transaction_code`}
                    pagination={false}
                    scroll={{x: 'calc(500px + 50%)'}}
                    dataSource={this.state.tableData}
                    loading={this.state.fetchProcessing}
                    columns={[
                        {
                            title: 'Transaction Time',
                            dataIndex: 'transaction_created_at',
                            render: ((value, row) => {
                                return <>{value?.replace("T", " ")?.substring(0, 19)}</>
                            })
                        }, {
                            title: 'Kiosk',
                            dataIndex: 'kiosk_name',
                            render: ((value, row) => {
                                return <>{value}</>
                            })
                        }, {
                            title: 'Transaction Code',
                            dataIndex: 'transaction_code',
                            render: ((value, row) => {
                                return <>{value}</>
                            })
                        }, {
                            title: 'Ticket Code',
                            dataIndex: 'transaction_ticket',
                            render: ((value, row) => {
                                return <>{value}</>
                            })
                        }, {
                            title: 'Latest State',
                            dataIndex: 'transaction_state',
                            render: ((value, row) => {
                                return <>{value}</>
                            })
                        }, {
                            title: 'PG Amount',
                            dataIndex: 'transaction_amount',
                            render: ((value, row) => {
                                return <>{currencyFormat(value)}</>
                            })
                        }, {
                            title: 'Payment Status',
                            dataIndex: 'transaction_status',
                            render: ((value, row) => {
                                return <>{value}</>
                            })
                        }, {
                            title: 'Action',
                            dataIndex: 'transaction_id',
                            align: "center",
                            fixed: 'right',
                            render: ((value, row) => {
                                return <>
                                    <Button style={(row.refund_failed_time) ? {display: 'none'} : {}} size={"small"} className={`m-10`} danger={true} onClick={() => this.setState({rf_transactionId: value, rf_transactionCode: row.transaction_code, showModalForm: true, loadingRefundConfirm: false})}> Refund</Button>
                                    <Button style={(!row.refund_failed_time) ? {display: 'none'} : {}} size={"small"} className={`m-10`} danger={true} onClick={() => this.setState({rf_transactionId: value, rf_transactionCode: row.transaction_code, showModalManualRefundForm: true, loadingRefundConfirm: false})}> Manual Refund</Button>
                                </>
                            })
                        }
                    ]}
                />
                <Modal
                    open={this.state.showModalForm}
                    title={`Refund ${this.state.rf_transactionCode}`}
                    style={{top: 20}}
                    onCancel={() => this.setState({showModalForm: false})}
                    footer={[
                        <Button loading={this.state.loadingRefundConfirm} type={"primary"} danger={true} key="1" onClick={this.confirmRefund}>Refund</Button>,
                        <Button key="2" onClick={() => this.setState({showModalForm: false})}>Close</Button>,
                    ]}
                >
                    <Divider/>
                    Are you sure you want to proceed with refunding this transaction?
                    <Divider/>
                </Modal>

                <Modal
                    open={this.state.showModalManualRefundForm}
                    title={`Manual Refund ${this.state.rf_transactionCode}`}
                    style={{top: 20}}
                    onCancel={() => this.setState({showModalManualRefundForm: false})}
                    footer={[
                        <Button loading={this.state.loadingRefundConfirm} type={"primary"} danger={true} key="1" onClick={this.confirmManualRefund}>Manual Refund</Button>,
                        <Button key="2" onClick={() => this.setState({showModalManualRefundForm: false})}>Close</Button>,
                    ]}
                >
                    <Divider/>
                    Are you sure you want to proceed with MANUAL REFUND for this transaction?
                    <Divider/>
                </Modal>
            </MainLayout>
        )
    }
}

export default withAuth(Refund, 18)