import React, {Component} from 'react'
import Cookies from 'universal-cookie'
import {Buffer} from "buffer"
import withAuth from "../../hoc/withAuth"
import {EnvironmentOutlined, EditOutlined, MoreOutlined} from '@ant-design/icons'
import MainLayout from "../../components/layout/MainLayout"
import {Button, Dropdown, message, Modal, Table} from "antd"
import {kioskApiService} from "../../services/wiloKiosk/KioskApiService"
import MasterLocationForm from "../../components/form/MasterLocationForm";

class MasterLocation extends Component {
    constructor(props) {
        super(props)
        this.cookies = new Cookies()
        this.initState = {
            token: (this.cookies.get('_wk-t')) ? JSON.parse(Buffer.from(this.cookies.get('_wk-t'), 'base64').toString()) : '',
            fetchProcessing: true,
            tableData: [],
            locationUpdate: {},
            showModalForm: false,
            modalTitle: "",
            modalType: "",
        }
        this.state = {...this.initState}
    }

    fetchData = async () => {
        try {
            await kioskApiService.setToken(this.state.token)
            const locationData = await kioskApiService.readMasterLocation()
            this.setState({
                fetchProcessing: false,
                tableData: locationData.data.data
            })
        } catch (e) {
            this.setState({
                processing: false,
                tableData: [],
            })
            message.info(e)
        }
    }

    formChildAction = (data) => {
        if (data.reloadData) {
            this.fetchData()
        }
        if (data.closeModal) {
            this.setState({showModalForm: false})
        }
    }

    async componentDidMount() {
        await this.fetchData()
    }

    render() {
        return (
            <MainLayout fullWidth={true} titlePage={`Master Location`} breadcrumbPath={`Setting`} breadcrumbMenu={`/ Master Location`} defaultSelectedKeys={['m-master-location']} defaultOpenKeys={['sm-setting']}>
                <Button className={`m-10`} type={`primary`} onClick={() => this.setState({locationUpdate: {active: true}, showModalForm: true, modalTitle: "Add New Location", modalType: "CREATE"})}> <EnvironmentOutlined /> Add New Location</Button>
                <Table
                    size="small"
                    rowKey={`id`}
                    scroll={{x: 'calc(500px + 50%)'}}
                    dataSource={this.state.tableData}
                    loading={this.state.fetchProcessing}
                    columns={[
                        {
                            title: 'Location Name',
                            dataIndex: 'location_name',
                            width: 320,
                            render: ((value, row) => {
                                return <>{value}</>
                            })
                        },
                        {
                            title: 'City',
                            dataIndex: 'city_code',
                            width: 320,
                            render: ((value, row) => {
                                return <>{row.city_name}<br/><i className={`small-text-grey`}>{value}</i></>
                            })
                        }, {
                            title: 'Location Address',
                            dataIndex: 'location_address',
                            width: 320,
                            render: ((value, row) => {
                                return <>{value}</>
                            })
                        }, {
                            title: 'Status',
                            dataIndex: 'active',
                            width: 120,
                            render: ((value, row) => {
                                return <>{(value.toString() === "true") ? "Enable" : "Disable"}</>
                            })
                        }, {
                            title: 'Action',
                            dataIndex: 'id',
                            align: "center",
                            fixed: 'right',
                            width: 100,
                            render: ((value, row) => {
                                const items = [
                                    {key: '1', label: (<span onClick={() => this.setState({locationUpdate: row, showModalForm: true, modalTitle: "Update Location", modalType: "UPDATE"})}> <EditOutlined/> Update Location</span>)},
                                ]
                                return <>
                                    <span className={`font-90`}>
                                        <Dropdown menu={{items}} placement="bottomLeft" arrow>
                                            <MoreOutlined style={{fontWeight: "bolder", cursor: "pointer"}}/>
                                        </Dropdown>
                                    </span>
                                </>
                            })
                        }
                    ]}
                />

                <Modal
                    open={this.state.showModalForm}
                    title={this.state.modalTitle}
                    style={{top: 20}}
                    onCancel={() => this.setState({showModalForm: false})}
                    footer={[]}
                >
                    <MasterLocationForm formChildAction={this.formChildAction} type={this.state.modalType} locationData={this.state.locationUpdate} token={this.state.token}/>
                </Modal>
            </MainLayout>
        )
    }
}

export default withAuth(MasterLocation, 9)