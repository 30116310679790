import React, {Component} from 'react'
import Cookies from 'universal-cookie'
import {Buffer} from "buffer"
import withAuth from "../../hoc/withAuth"
import {Card, Col, DatePicker, Row, Table, Tabs, Divider, message} from 'antd'
import {CloudDownloadOutlined} from '@ant-design/icons'
import MainLayout from "../../components/layout/MainLayout"
import {kioskApiService} from "../../services/wiloKiosk/KioskApiService"
import dayjs from "dayjs"
import {Chart as ChartJS, CategoryScale, ArcElement, Tooltip, Legend, LinearScale, PointElement, LineElement, BarElement, Title} from 'chart.js'
import {Pie} from 'react-chartjs-2'
import {Bar} from 'react-chartjs-2'
import {Line} from 'react-chartjs-2'
import currencyFormat from "../../utils/currencyFormat"

ChartJS.register(ArcElement, CategoryScale, Tooltip, Legend, LinearScale, PointElement, LineElement, BarElement, Title)

class LogUser extends Component {
    constructor(props) {
        super(props)
        this.pieRef = React.createRef()
        this.bar1Ref = React.createRef()
        this.bar2Ref = React.createRef()
        this.lineRef = React.createRef()
        this.cookies = new Cookies()
        this.initState = {
            token: (this.cookies.get('_wk-t')) ? JSON.parse(Buffer.from(this.cookies.get('_wk-t'), 'base64').toString()) : '',
            fetchProcessing: true,
            fetchProcessing2: true,
            startDate: dayjs(),
            tableData: [],

            pieLabel: [],
            pieData: [],
            stackedCountLabel: [],
            stackedCountData: [],
            stackedAmountLabel: [],
            stackedAmountData: [],
            lineAmountLabel: [],
            lineAmountData: []
        }
        this.state = {...this.initState}
    }

    fetchDataYear = async () => {
        try {
            await kioskApiService.setToken(this.state.token)
            const kioskData = await kioskApiService.readReportSummarySales()
            const lineAmountLabel = []
            const lineAmountData = []

            for (const element of kioskData.data.data) {
                lineAmountLabel.push(element.date)
                lineAmountData.push(element.amount_transaction)
            }

            this.setState({
                fetchProcessing2: false,
                lineAmountLabel: lineAmountLabel,
                lineAmountData: lineAmountData,
            })
        } catch (e) {
            this.setState({
                fetchProcessing2: false,
                lineAmountLabel: [],
                lineAmountData: [],
            })
            message.info(e)
        }
    }

    fetchDataMonth = async () => {
        try {
            await kioskApiService.setToken(this.state.token)
            const kioskData = await kioskApiService.readReportPerformance({
                month: dayjs(this.state.startDate).format("YYYY-MM")
            })

            /* PIE */
            const summaryPie = {}
            const pieLabel = []
            const pieData = []
            kioskData.data.data.forEach(item => {
                const productName = item.product_name
                const count = item.count_succeeded_transaction
                if (summaryPie[productName]) {
                    summaryPie[productName] += count
                } else {
                    summaryPie[productName] = count
                }
            })
            for (const productName in summaryPie) {
                pieLabel.push(productName)
                pieData.push(summaryPie[productName])
            }

            /* STACKED */
            let stackedCountLabel = []
            let stackedCountData = []
            let stackedAmountLabel = []
            let stackedAmountData = []
            let listColor = ["#009c82", "#ffb400", "#3cba9f", "#e8c3b9", "#c45850"]
            const uniqueKiosk = []
            const uniqueProduct = []
            let resumeStack = {}
            kioskData.data.data.forEach(item => {
                const productName = item.product_name
                const kioskName = item.kiosk_name
                const count = item.count_succeeded_transaction
                const amount = item.amount_succeeded_transaction

                if (!uniqueKiosk.includes(kioskName)) {
                    uniqueKiosk.push(kioskName)
                }
                if (!uniqueProduct.includes(productName)) {
                    uniqueProduct.push(productName)
                }

                if (!resumeStack[productName]) {
                    resumeStack[productName] = []
                }
                resumeStack[productName].push({
                    kioskName: kioskName,
                    count: count,
                    amount: amount
                })
            })

            stackedCountLabel = uniqueKiosk
            stackedAmountLabel = uniqueKiosk
            let i = 0
            for (const productName of uniqueProduct) {
                const dataQtyProduct = []
                const dataAmountProduct = []
                for (const item of resumeStack[productName]) {
                    dataQtyProduct.push(item.count)
                    dataAmountProduct.push(item.amount)
                }
                stackedCountData.push({
                    label: productName,
                    data: dataQtyProduct,
                    backgroundColor: listColor[i]
                })

                stackedAmountData.push({
                    label: productName,
                    data: dataAmountProduct,
                    backgroundColor: listColor[i]
                })

                i++
            }

            this.setState({
                fetchProcessing: false,
                tableData: kioskData.data.data,
                pieLabel: pieLabel,
                pieData: pieData,
                stackedCountLabel: stackedCountLabel,
                stackedCountData: stackedCountData,
                stackedAmountLabel: stackedAmountLabel,
                stackedAmountData: stackedAmountData,
            })
        } catch (e) {
            this.setState({
                fetchProcessing: false,
                tableData: [],
                pieLabel: [],
                pieData: [],
                stackedCountLabel: [],
                stackedCountData: [],
                stackedAmountLabel: [],
                stackedAmountData: [],
            })
            message.info(e)
        }
    }

    onFieldChange = async (target, value) => {
        const currentState = {...this.state}
        switch (target) {
            case "startDate":
                currentState.startDate = value
                break
            default:
                break
        }
        this.setState({
            ...currentState,
        })

        await this.fetchDataMonth()
    }

    componentDidMount() {
        this.fetchDataMonth()
        this.fetchDataYear()
    }

    downloadPieImage = () => {
        const link = document.createElement('a')
        link.download = "pie_accumulated_sales.png"
        link.href = this.pieRef.current.toBase64Image('image/png', 1)
        link.click()
    }

    downloadLineImage = () => {
        const link = document.createElement('a')
        link.download = "line_accumulated_resume_amount.png"
        link.href = this.lineRef.current.toBase64Image('image/png', 1)
        link.click()
    }

    downloadBar1Image = () => {
        const link = document.createElement('a')
        link.download = "bar_accumulated_qty.png"
        link.href = this.bar1Ref.current.toBase64Image('image/png', 1)
        link.click()
    }

    downloadBar2Image = () => {
        const link = document.createElement('a')
        link.download = "bar_accumulated_amount.png"
        link.href = this.bar2Ref.current.toBase64Image('image/png', 1)
        link.click()
    }

    render() {
        const dataPie = {
            labels: this.state.pieLabel,
            datasets: [
                {
                    label: 'Sales',
                    data: this.state.pieData,
                    backgroundColor: ["#009c82", "#ffb400"],
                    borderWidth: 1,
                },
            ],
        }

        const dataBarCount = {labels: this.state.stackedCountLabel, datasets: this.state.stackedCountData}
        const optionsBarCount = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {x: {stacked: true}, y: {stacked: true}},
            plugins: {title: {display: true, text: 'Accumulated Qty based on Kiosk', font: {size: 15}}}
        }

        const dataBarAmount = {labels: this.state.stackedAmountLabel, datasets: this.state.stackedAmountData}
        const optionsBarAmount = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {x: {stacked: true}, y: {stacked: true}},
            plugins: {title: {display: true, text: 'Accumulated Amount based on Kiosk', font: {size: 15}}}
        }

        const dataLine = {
            labels: this.state.lineAmountLabel,
            datasets: [
                {
                    label: 'Sales Amount',
                    data: this.state.lineAmountData,
                    borderColor: '#009c82',
                    backgroundColor: '#009c82',
                },
            ],
        }
        const optionsLine = {
            responsive: true,
            plugins: {
                title: {display: true, text: 'Accumulated Resume Amount based on Kiosk', font: {size: 15}}
            },
        }


        return (
            <MainLayout titlePage={`Sales Chart Report`} breadcrumbPath={`Report`} breadcrumbMenu={`/ Chart Report`} defaultSelectedKeys={['m-report-chart']} defaultOpenKeys={['sm-report']}>
                <Tabs
                    items={[
                        {
                            label: 'Monthly Report',
                            key: '1',
                            children:
                                <>

                                    <div className={`m-10`}>
                                        <Row>
                                            <Col xl={4} xs={24}>
                                                <i className={`extra-small-text-grey`}> Month Period</i><br/>
                                                <DatePicker picker="month" defaultValue={dayjs(this.state.startDate, 'YYYY-MM')} onChange={event => this.onFieldChange("startDate", event)} format={'YYYY-MM'}/>
                                            </Col>
                                        </Row>
                                    </div>

                                    <Divider className={`divider-thin`}/>

                                    <Row>
                                        <Col xs={24} xl={8} style={{padding: 10}}>
                                            <Card size="small" hoverable={true}>
                                                <div style={{paddingBottom: 5}}>
                                                    Pie Chart Accumulated Sales <span style={{float: "right", cursor: "pointer"}}><CloudDownloadOutlined onClick={this.downloadPieImage}/></span>
                                                </div>
                                                <Divider style={{padding: 10, margin: 0}}/>
                                                <div style={{maxWidth: '320px', minHeight: '320px', margin: '0 auto', textAlign: 'center'}}>
                                                    <Pie ref={this.pieRef} data={dataPie} options={{
                                                        responsive: true, maintainAspectRatio: false, plugins: {title: {display: true, text: 'Accumulated sales based on product', font: {size: 15}}}
                                                    }}/>
                                                </div>
                                            </Card>
                                            <Divider className={`divider-thin`}/>
                                            <Card size="small" hoverable={true}>
                                                <Table
                                                    size="small"
                                                    rowKey={`key`}
                                                    pagination={false}
                                                    // scroll={{x: 'calc(500px + 50%)'}}
                                                    dataSource={this.state.tableData}
                                                    loading={this.state.fetchProcessing}
                                                    columns={[
                                                        {
                                                            width: 260,
                                                            title: 'Kiosk Code - Name',
                                                            dataIndex: 'kiosk_code',
                                                            render: ((value, row) => {
                                                                return <>{row.kiosk_name} ({value}) <br/><i className={`extra-small-text-grey`}> {row.product_code}</i></>
                                                            })
                                                        },
                                                        {
                                                            title: 'Succeeded Transaction',
                                                            dataIndex: 'count_active_transaction',
                                                            render: ((value, row) => {
                                                                return <>{(row.count_succeeded_transaction)}<br/><i className={`extra-small-text-grey`}> {(currencyFormat(row.amount_succeeded_transaction))}</i></>
                                                            })
                                                        }
                                                    ]}
                                                />
                                            </Card>
                                        </Col>
                                        <Col xs={24} xl={16} style={{padding: 10}}>
                                            <Card size="small" hoverable={true}>
                                                <div style={{paddingBottom: 5}}>
                                                    Accumulated Qty based on Kiosk <span style={{float: "right", cursor: "pointer"}}><CloudDownloadOutlined onClick={this.downloadBar1Image}/></span>
                                                </div>
                                                <Divider style={{padding: 10, margin: 0}}/>
                                                <div style={{height: 320, width: "100%"}}>
                                                    <Bar ref={this.bar1Ref} data={dataBarCount} options={optionsBarCount}/>
                                                </div>
                                            </Card>
                                            <Divider className={`divider-thin`}/>
                                            <Card size="small" hoverable={true}>
                                                <div style={{paddingBottom: 5}}>
                                                    Accumulated Amount based on Kiosk <span style={{float: "right", cursor: "pointer"}}><CloudDownloadOutlined onClick={this.downloadBar2Image}/></span>
                                                </div>
                                                <Divider style={{padding: 10, margin: 0}}/>
                                                <div style={{height: 320, width: "100%"}}>
                                                    <Bar ref={this.bar2Ref} data={dataBarAmount} options={optionsBarAmount}/>
                                                </div>
                                            </Card>
                                        </Col>
                                    </Row>
                                </>
                        },
                        {
                            label: 'Resume Report',
                            key: '2',
                            children:
                                <>
                                    <Card size="small" hoverable={true}>
                                        <div style={{paddingBottom: 5}}>
                                            Accumulated Resume Amount based on Kiosk <span style={{float: "right", cursor: "pointer"}}><CloudDownloadOutlined onClick={this.downloadLineImage}/></span>
                                        </div>
                                        <Divider style={{padding: 10, margin: 0}}/>
                                        <div style={{height: 420, width: "100%"}}>
                                            <Line ref={this.lineRef} data={dataLine} options={optionsLine}/>
                                        </div>
                                    </Card>
                                </>
                        }
                    ]}
                />
            </MainLayout>
        )
    }
}

export default withAuth(LogUser, 15)