import React, {Component} from 'react'
import {Button, Divider, Form, Input, Modal, Select} from "antd"
import {kioskApiService} from "../../services/wiloKiosk/KioskApiService"
import {cityKemendagri} from "../../config/cityData"

class MasterLocationForm extends Component {
    constructor(props) {
        super(props)
        this.initState = {
            processing: false,
            type: "",
            cityCode: null,
            cityName: null,
            locationName: "",
            locationAddress: "",
            activeStatus: "ACTIVE",
        }
        this.state = {...this.initState}
    }

    onFieldChange = (target, value) => {
        const currentState = {...this.state}
        switch (target) {
            case "cityCode":
                currentState.cityCode = value?.split(" - ")?.[1]
                currentState.cityName = value?.split(" - ")?.[0]
                break
            case "locationName":
                currentState.locationName = value
                break
            case "locationAddress":
                currentState.locationAddress = value
                break
            case "activeStatus":
                currentState.activeStatus = value
                break
            default:
                break
        }
        this.setState({
            ...currentState,
        })
    }

    componentDidMount() {
        this.fillComponent()
    }

    componentDidUpdate(prevProps) {
        if (this.props.type !== prevProps.type || (this.props.locationData && this.props.locationData.location_name !== prevProps.locationData?.location_name)) {
            this.fillComponent()
        }
    }

    fillComponent = () => {
        this.setState({
            processing: false,
            type: this.props.type,
            cityCode: this.props.locationData.city_code ?? null,
            cityName: this.props.locationData.city_name ?? null,
            locationName: this.props.locationData.location_name ?? "",
            locationAddress: this.props.locationData.location_address ?? "",
        })
    }

    closeAction = () => {
        this.props.formChildAction({closeModal: true, reloadData: false})
    }

    saveAction = async () => {
        const {locationName, locationAddress, type, cityCode, activeStatus} = this.state
        if (!locationName.trim() || !locationAddress.trim() || !cityCode) {
            return Modal.error({title: 'Error', content: 'Please fill in all required fields'})
        }

        const cityData = cityKemendagri.find(city => city.city_code === cityCode)
        const cityName = cityData.city_name

        await kioskApiService.setToken(this.props.token)
        if (type === "CREATE") {
            try {
                await kioskApiService.createMasterLocation({
                    city_code: cityCode,
                    city_name: cityName,
                    location_name: locationName.trim(),
                    location_address: locationAddress.trim()
                })
                Modal.success({title: 'Register Success', content: 'Register new location Success'})
            } catch (e) {
                this.setState({
                    processing: false,
                    type: "",
                    cityCode: null,
                    cityName: null,
                    locationName: "",
                    locationAddress: "",
                    activeStatus: "ACTIVE",
                })
                return Modal.error({title: 'Register Failed', content: e})
            }
        } else if (type === "UPDATE") {
            try {
                await kioskApiService.updateMasterLocation({
                    id: this.props.locationData.id,
                    city_code: cityCode,
                    city_name: cityName,
                    location_name: locationName.trim(),
                    location_address: locationAddress.trim(),
                    active: (activeStatus === "ACTIVE")
                })
                Modal.success({title: 'Update Success', content: 'Update new location Success'})
            } catch (e) {
                this.setState({processing: false})
                return Modal.error({title: 'Register Failed', content: e})
            }
        } else {
            return Modal.error({title: 'App Error', content: "Error, Please reload this page"})
        }

        this.props.formChildAction({closeModal: true, reloadData: true})
    }

    render() {
        return (
            <>
                <Divider/>
                <Form.Item>
                    <i className={`small-text-grey`}>City</i><br/>
                    <Select
                        disabled={(this.props.type === "UPDATE")}
                        onChange={value => this.onFieldChange("cityCode", value)}
                        value={(this.state.cityCode) ? `${this.state.cityName} - ${this.state.cityCode}` : null}
                        style={{width: "100%"}}
                        placeholder={`City`}
                        showSearch
                    >
                        {cityKemendagri.map((item, i) => (
                            <Select.Option key={item?.city_code} value={`${item?.city_name} - ${item?.city_code}`}>{item?.city_name} - {item?.city_code}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item>
                    <i className={`small-text-grey`}>Location Name</i><br/>
                    <Input disabled={(this.props.type === "UPDATE")} value={this.state.locationName} placeholder={`Input Location Name`} onChange={event => this.onFieldChange("locationName", event.target.value)}/>
                </Form.Item>
                <Form.Item>
                    <i className={`small-text-grey`}>Location Address</i><br/>
                    <Input disabled={(this.props.type === "UPDATE")} value={this.state.locationAddress} placeholder={`Input Location Name`} onChange={event => this.onFieldChange("locationAddress", event.target.value)}/>
                </Form.Item>
                <Form.Item>
                    <i className={`small-text-grey`}>Active Status</i><br/>
                    <Select
                        onChange={value => this.onFieldChange("activeStatus", value)}
                        value={this.state.activeStatus}
                        style={{width: "100%"}}
                        placeholder={`User Active`}
                    >
                        <Select.Option key={`ACTIVE`} value={`ACTIVE`}>{`Enable`}</Select.Option>
                        <Select.Option key={`UNACTIVE`} value={`UNACTIVE`}>{`Disable`}</Select.Option>
                    </Select>
                </Form.Item>
                <Divider/>
                <div style={{textAlign: "right"}}>
                    <Button className={`m-5`} onClick={this.closeAction}> Cancel</Button>
                    <Button className={`m-5`} onClick={this.saveAction} loading={this.state.processing} type={'primary'}> Save</Button>
                </div>
            </>
        )
    }
}

export default MasterLocationForm