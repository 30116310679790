import React, {Component} from 'react'
import {Button, DatePicker, Divider, Form, Input, Modal, Tag} from "antd"
import {kioskApiService} from "../../services/wiloKiosk/KioskApiService"
import dayjs from "dayjs";

class MasterKioskForm extends Component {
    constructor(props) {
        super(props)
        this.initState = {
            processing: false,
            code: "",
            name: "",
            reminderFilterDate: dayjs(),
        }
        this.state = {...this.initState}
    }

    onFieldChange = (target, value) => {
        const currentState = {...this.state}
        switch (target) {
            case "reminderFilterDate":
                if (value) {
                    currentState.reminderFilterDate = value
                }
                break
            default:
                break
        }
        this.setState({
            ...currentState,
        })
    }

    async componentDidMount() {
        this.fillComponent()
    }

    componentDidUpdate(prevProps) {
        if ((this.props.kioskData && this.props.kioskData.code !== prevProps.kioskData?.code)) {
            this.fillComponent()
        }
    }

    fillComponent = () => {
        this.setState({
            processing: false,
            name: this.props.kioskData.name,
            code: this.props.kioskData.code,
            reminderFilterDate: this.props.kioskData.reminder_filter,
        })
    }

    setFilterDate = (month) => {
        this.setState({
            reminderFilterDate: dayjs().add(month, 'month')
        })
    }

    closeAction = () => {
        this.props.formChildAction({closeModal: true, reloadData: false})
    }

    saveAction = async () => {
        const {reminderFilterDate} = this.state

        await kioskApiService.setToken(this.props.token)
        try {
            await kioskApiService.updateMasterKiosk({
                id: this.props.kioskData.id,
                reminder_filter: dayjs(reminderFilterDate).format("YYYY-MM-DD")
            })
            Modal.success({title: 'Update Success', content: 'Update kiosk Success'})
        } catch (e) {
            this.setState({processing: false})
            return Modal.error({title: 'Update kiosk Failed', content: e})
        }

        this.props.formChildAction({closeModal: true, reloadData: true})
    }

    render() {
        return (
            <>
                <Divider/>
                <Form.Item>
                    <i className={`small-text-grey`}>Code</i><br/>
                    <Input disabled={true} value={this.state.code} placeholder={`Kiosk Code`}/>
                </Form.Item>
                <Form.Item>
                    <i className={`small-text-grey`}>Name</i><br/>
                    <Input disabled={true} value={this.state.name} placeholder={`Kiosk Name`}/>
                </Form.Item>
                <Form.Item>
                    <i className={`extra-small-text-grey`}>Filter Reminder</i><br/>
                    <DatePicker value={dayjs(this.state.reminderFilterDate, 'YYYY-MM-DD')} onChange={event => this.onFieldChange("reminderFilterDate", event)} format={'YYYY-MM-DD'} style={{width: "100%"}}/>
                </Form.Item>

                <Tag onClick={() => this.setFilterDate(3)} bordered={false} color="geekblue" style={{cursor: "pointer"}}>set next 3 month</Tag>
                <Tag onClick={() => this.setFilterDate(6)} bordered={false} color="geekblue" style={{cursor: "pointer"}}>set next 6 month</Tag>

                <Divider/>
                <div style={{textAlign: "right"}}>
                    <Button className={`m-5`} onClick={this.closeAction}> Cancel</Button>
                    <Button className={`m-5`} onClick={this.saveAction} loading={this.state.processing} type={'primary'}> Save</Button>
                </div>
            </>
        )
    }
}

export default MasterKioskForm