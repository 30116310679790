import React, {Component} from 'react'
import Cookies from 'universal-cookie'
import {Buffer} from "buffer"
import withAuth from "../../hoc/withAuth"
import {Card, Col, DatePicker, Row, Table, Divider, message} from 'antd'
import MainLayout from "../../components/layout/MainLayout"
import {kioskApiService} from "../../services/wiloKiosk/KioskApiService"
import dayjs from "dayjs"
import {Chart as ChartJS, CategoryScale, ArcElement, Tooltip, Legend, LinearScale, PointElement, LineElement, BarElement, Title} from 'chart.js'
import {Pie} from 'react-chartjs-2'
import {Bar} from 'react-chartjs-2'
import currencyFormat from "../../utils/currencyFormat"
import {ShoppingOutlined} from "@ant-design/icons";
import numberFormat from "../../utils/numberFormat";

ChartJS.register(ArcElement, CategoryScale, Tooltip, Legend, LinearScale, PointElement, LineElement, BarElement, Title)

class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.pieRef = React.createRef()
        this.bar1Ref = React.createRef()
        this.bar2Ref = React.createRef()
        this.lineRef = React.createRef()
        this.cookies = new Cookies()
        this.initState = {
            token: (this.cookies.get('_wk-t')) ? JSON.parse(Buffer.from(this.cookies.get('_wk-t'), 'base64').toString()) : '',
            fetchProcessing: true,
            fetchProcessing2: true,
            startDate: dayjs(),
            tableData: [],
            pieLabel: [],
            pieData: [],
            lineAmountLabel: [],
            lineAmountData: [],
            resumeCountSucceededTransaction: 0,
            resumeTotalSucceededTransaction: 0
        }
        this.state = {...this.initState}
    }

    fetchDataYear = async () => {
        try {
            await kioskApiService.setToken(this.state.token)
            const kioskData = await kioskApiService.readReportSummarySales2()
            const lineAmountLabel = []
            const lineAmountData = []

            for (const element of kioskData.data.data) {
                lineAmountLabel.push(element.date)
                lineAmountData.push(element.amount_transaction)
            }

            this.setState({
                fetchProcessing2: false,
                lineAmountLabel: lineAmountLabel,
                lineAmountData: lineAmountData,
                resumeCountSucceededTransaction: kioskData.data.data?.[2]?.count_transaction,
                resumeTotalSucceededTransaction: kioskData.data.data?.[2]?.amount_transaction
            })
        } catch (e) {
            this.setState({
                fetchProcessing2: false,
                lineAmountLabel: [],
                lineAmountData: [],
            })
            message.info(e)
        }
    }

    fetchDataMonth = async () => {
        try {
            await kioskApiService.setToken(this.state.token)
            const kioskData = await kioskApiService.readReportPerformance({
                month: dayjs(this.state.startDate).format("YYYY-MM")
            })

            /* PIE */
            const summaryPie = {}
            const pieLabel = []
            const pieData = []
            kioskData.data.data.forEach(item => {
                const productName = item.product_name
                const count = item.count_succeeded_transaction
                if (summaryPie[productName]) {
                    summaryPie[productName] += count
                } else {
                    summaryPie[productName] = count
                }
            })
            for (const productName in summaryPie) {
                pieLabel.push(productName)
                pieData.push(summaryPie[productName])
            }

            /* STACKED */
            const uniqueKiosk = []
            const uniqueProduct = []
            let resumeStack = {}
            kioskData.data.data.forEach(item => {
                const productName = item.product_name
                const kioskName = item.kiosk_name
                const count = item.count_succeeded_transaction
                const amount = item.amount_succeeded_transaction

                if (!uniqueKiosk.includes(kioskName)) {
                    uniqueKiosk.push(kioskName)
                }
                if (!uniqueProduct.includes(productName)) {
                    uniqueProduct.push(productName)
                }

                if (!resumeStack[productName]) {
                    resumeStack[productName] = []
                }
                resumeStack[productName].push({
                    kioskName: kioskName,
                    count: count,
                    amount: amount
                })
            })

            this.setState({
                fetchProcessing: false,
                tableData: kioskData.data.data,
                pieLabel: pieLabel,
                pieData: pieData,
            })
        } catch (e) {
            this.setState({
                fetchProcessing: false,
                tableData: [],
                pieLabel: [],
                pieData: [],
            })
            message.info(e)
        }
    }

    onFieldChange = async (target, value) => {
        const currentState = {...this.state}
        switch (target) {
            case "startDate":
                currentState.startDate = value
                break
            default:
                break
        }
        this.setState({
            ...currentState,
        })

        await this.fetchDataMonth()
    }

    componentDidMount() {
        this.fetchDataMonth()
        this.fetchDataYear()
    }

    render() {
        const dataPie = {
            labels: this.state.pieLabel,
            datasets: [
                {
                    label: 'Sales',
                    data: this.state.pieData,
                    backgroundColor: ["#009c82", "#ffb400"],
                    borderWidth: 1,
                },
            ],
        }

        const dataLine = {
            labels: this.state.lineAmountLabel,
            datasets: [
                {
                    label: 'Sales Amount',
                    data: this.state.lineAmountData,
                    borderColor: '#009c82',
                    backgroundColor: '#009c82',
                },
            ],
        }
        const optionsLine = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                title: {display: true, text: '', font: {size: 15}}
            },
        }

        return (
            <MainLayout titlePage={`Dashboard`} breadcrumbPath={``} breadcrumbMenu={`Dashboard`} defaultSelectedKeys={['m-dashboard']}>
                <>
                    <div className={`m-10`} style={{display: "none"}}>
                        <Row>
                            <Col xl={4} xs={24}>
                                <i className={`extra-small-text-grey`}> Month Period</i><br/>
                                <DatePicker picker="month" defaultValue={dayjs(this.state.startDate, 'YYYY-MM')} onChange={event => this.onFieldChange("startDate", event)} format={'YYYY-MM'}/>
                            </Col>
                        </Row>
                    </div>

                    <Row className={`mb-20`}>
                        <Col xs={24} xl={5} style={{padding: 10}}>
                            <Card bodyStyle={{padding: 5}} size="small" hoverable={true}>
                                <b className={`extra-small-text-grey`}><ShoppingOutlined/> Count Transaction This Month </b>
                                <br/>
                                <b style={{fontSize: "160%", paddingLeft: 20}}>{numberFormat(this.state.resumeCountSucceededTransaction ?? 0)}</b>
                                <i style={{fontSize: "85%", color: "gray"}}> Succeeded Transaction</i>
                            </Card>
                        </Col>
                        <Col xs={24} xl={5} style={{padding: 10}}>
                            <Card bodyStyle={{padding: 5}} size="small" hoverable={true}>
                                <b className={`extra-small-text-grey`}><ShoppingOutlined/> Total Transaction This Month </b>
                                <br/>
                                <b style={{fontSize: "160%", paddingLeft: 20}}>{numberFormat(this.state.resumeTotalSucceededTransaction ?? 0)}</b>
                                <i style={{fontSize: "85%", color: "gray"}}> Rupiah</i>
                            </Card>
                        </Col>
                    </Row>

                    <Divider className={`divider-thin`}/>

                    <Row>
                        <Col xs={24} xl={8} style={{padding: 10}}>
                            <Card size="small" hoverable={true}>
                                <div style={{paddingBottom: 5}}>
                                    Pie Chart Product
                                </div>
                                <Divider style={{padding: 10, margin: 0}}/>
                                <div style={{maxWidth: '320px', minHeight: '320px', margin: '0 auto', textAlign: 'center'}}>
                                    <Pie ref={this.pieRef} data={dataPie} options={{
                                        responsive: true, maintainAspectRatio: false, plugins: {title: {display: true, text: 'Accumulated sales based on product', font: {size: 15}}}
                                    }}/>
                                </div>
                            </Card>
                        </Col>
                        <Col xs={24} xl={8} style={{padding: 10}}>
                            <Card size="small" hoverable={true}>
                                <Table
                                    size="small"
                                    rowKey={`key`}
                                    pagination={false}
                                    // scroll={{x: 'calc(500px + 50%)'}}
                                    dataSource={this.state.tableData}
                                    loading={this.state.fetchProcessing}
                                    columns={[
                                        {
                                            width: 260,
                                            title: 'Kiosk Code - Name',
                                            dataIndex: 'kiosk_code',
                                            render: ((value, row) => {
                                                return <>{row.kiosk_name} ({value}) <br/><i className={`extra-small-text-grey`}> {row.product_code}</i></>
                                            })
                                        },
                                        {
                                            title: 'Succeeded Transaction',
                                            dataIndex: 'count_active_transaction',
                                            render: ((value, row) => {
                                                return <>{(row.count_succeeded_transaction)}<br/><i className={`extra-small-text-grey`}> {(currencyFormat(row.amount_succeeded_transaction))}</i></>
                                            })
                                        }
                                    ]}
                                />
                            </Card>

                        </Col>
                        <Col xs={24} xl={8} style={{padding: 10}}>

                            <Card size="small" hoverable={true}>
                                <div style={{paddingBottom: 5}}>
                                    Accumulated sales Last 3 months
                                </div>
                                <Divider style={{padding: 10, margin: 0}}/>
                                <div style={{height: 420, width: "100%"}}>
                                    <Bar ref={this.lineRef} data={dataLine} options={optionsLine}/>
                                </div>
                            </Card>

                        </Col>
                    </Row>
                </>
            </MainLayout>
        )
    }
}

export default withAuth(Dashboard, 1)