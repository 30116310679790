import React, {Component} from 'react'
import {Button, Divider, Form, Input, Modal, Select} from "antd"
import {kioskApiService} from "../../services/wiloKiosk/KioskApiService"

class MasterKioskForm extends Component {
    constructor(props) {
        super(props)
        this.initState = {
            processing: false,
            locationData: [],
            locationFetch: false,
            type: "",
            code: "",
            name: "",
            locationId: 0,
            activeStatus: "ACTIVE",
        }
        this.state = {...this.initState}
    }

    onFieldChange = (target, value) => {
        const currentState = {...this.state}
        switch (target) {
            case "activeStatus":
                currentState.activeStatus = value
                break
            case "locationId":
                currentState.locationId = value
                break
            case "name":
                currentState.name = value
                break
            default:
                break
        }
        this.setState({
            ...currentState,
        })
    }

    fetchLocationData = async () => {
        this.setState({locationFetch: true})
        try {
            await kioskApiService.setToken(this.props.token)
            const locationData = await kioskApiService.readMasterLocation()
            this.setState({
                locationFetch: false,
                locationData: locationData.data.data
            })
        } catch (e) {
            this.setState({
                locationFetch: false,
                locationData: [],
            })
        }
    }

    async componentDidMount() {
        await this.fetchLocationData()
        this.fillComponent()
    }

    componentDidUpdate(prevProps) {
        if (this.props.type !== prevProps.type || (this.props.kioskData && this.props.kioskData.code !== prevProps.kioskData?.code)) {
            this.fillComponent()
        }
    }

    fillComponent = () => {
        this.setState({
            processing: false,
            type: this.props.type,
            name: this.props.kioskData.name,
            code: this.props.kioskData.code,
            locationId: this.props.kioskData.location_id,
            activeStatus: (!this.props.kioskData.active) ? "UNACTIVE" : "ACTIVE",
        })
    }

    closeAction = () => {
        this.props.formChildAction({closeModal: true, reloadData: false})
    }

    saveAction = async () => {
        const {type, activeStatus, locationId, name} = this.state
        if (!locationId) {
            return Modal.error({title: 'Error', content: 'Please fill in all required fields'})
        }

        await kioskApiService.setToken(this.props.token)
        if (type === "CREATE") {
            alert("error cant create kiosk")
        } else if (type === "UPDATE") {
            try {
                await kioskApiService.updateMasterKiosk({
                    id: this.props.kioskData.id,
                    active: (activeStatus === "ACTIVE"),
                    location_id: locationId,
                    name: name
                })
                Modal.success({title: 'Update Success', content: 'Update kiosk Success'})
            } catch (e) {
                this.setState({processing: false})
                return Modal.error({title: 'Update kiosk Failed', content: e})
            }
        } else {
            return Modal.error({title: 'App Error', content: "Error, Please reload this page"})
        }

        this.props.formChildAction({closeModal: true, reloadData: true})
    }

    render() {
        return (
            <>
                <Divider/>
                <Form.Item>
                    <i className={`small-text-grey`}>Code</i><br/>
                    <Input disabled={(this.props.type === "UPDATE")} value={this.state.code} placeholder={`Kiosk Code`}/>
                </Form.Item>
                <Form.Item>
                    <i className={`small-text-grey`}>Name</i><br/>
                    <Input value={this.state.name} placeholder={`Kiosk Name`} onChange={event => this.onFieldChange("name", event.target.value)}/>
                </Form.Item>
                <Form.Item>
                    <i className={`small-text-grey`}>Active Status</i><br/>
                    <Select
                        onChange={value => this.onFieldChange("activeStatus", value)}
                        value={this.state.activeStatus}
                        style={{width: "100%"}}
                        placeholder={`User Active`}
                    >
                        <Select.Option key={`ACTIVE`} value={`ACTIVE`}>{`Enable`}</Select.Option>
                        <Select.Option key={`UNACTIVE`} value={`UNACTIVE`}>{`Disable`}</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item>
                    <i className={`small-text-grey`}>Kiosk Location</i><br/>
                    <Select
                        onChange={value => this.onFieldChange("locationId", value)}
                        value={this.state.locationId}
                        style={{width: "100%"}}
                        placeholder={`Kiosk Location`}
                    >
                        {this.state.locationData.map((item, i) =>
                            <Select.Option key={i} value={item.id}>{item?.location_name} - {item?.city_name}</Select.Option>
                        )}
                    </Select>
                </Form.Item>
                <Divider/>
                <div style={{textAlign: "right"}}>
                    <Button className={`m-5`} onClick={this.closeAction}> Cancel</Button>
                    <Button className={`m-5`} onClick={this.saveAction} loading={this.state.processing} type={'primary'}> Save</Button>
                </div>
            </>
        )
    }
}

export default MasterKioskForm