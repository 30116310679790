import React, {Component} from 'react'
import {Buffer} from "buffer"
import "./MainLayout.css"
import {Avatar, Layout, Menu, Typography, Dropdown, Row} from "antd"
import {LogoutOutlined, MenuUnfoldOutlined, ExportOutlined, MenuFoldOutlined, UserOutlined, HomeOutlined, SettingOutlined, CodeOutlined, FileTextOutlined, FundViewOutlined} from '@ant-design/icons'
import {Link} from "react-router-dom"
import Cookies from "universal-cookie"
import {appName, appVersion} from '../../config/appConfig'
import Col from "antd/lib/col";
import SubMenu from "antd/es/menu/SubMenu";

const {Header, Sider, Content, Footer} = Layout

class MainLayout extends Component {
    constructor(props) {
        super(props)

        this.initState = {
            collapsed: false,
            viewClass: 'desktop',
            user: "",
            adminLevel: "",
            listMenu: [],
        }
        this.state = {...this.initState}
    }

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed
        })
    }

    logOut = () => {
        const cookies = new Cookies()
        cookies.remove('_wk-t')
        cookies.remove('_wk-e')
        cookies.remove('_wk-n')
        cookies.remove('_wk-l')
        cookies.remove('_wk-m')
        window.location.href = "/login"
    }

    updateDimensions = () => {
        if (window.innerWidth < 1000) {
            this.setState({
                collapsed: true,
                viewClass: 'mobile',
            })
        } else {
            this.setState({
                collapsed: false,
                viewClass: 'desktop',
            })
        }
    }

    componentDidMount() {
        let user = "", accessMenu = [], adminLevel = ""
        const cookies = new Cookies()
        const token = cookies.get('_wk-t')
        if (token) {
            if (cookies?.get('_wk-n')) {
                user = JSON?.parse(Buffer?.from(cookies?.get('_wk-n'), "base64")?.toString())
                accessMenu = JSON.parse(Buffer.from(cookies.get('_wk-m'), "base64").toString())
                adminLevel = JSON.parse(Buffer.from(cookies.get('_wk-l'), "base64").toString())
            } else {
                this.logOut()
            }
        }

        this.setState({
            user: user,
            listMenu: accessMenu,
            adminLevel: adminLevel
        })

        this.updateDimensions()
        window.addEventListener('resize', this.updateDimensions)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions)
    }

    render() {
        const items = [
            {
                key: '0',
                label: <Link to={`/`}>
                    <div><HomeOutlined/> Dashboard</div>
                </Link>,
            },
            {
                key: '1',
                label: <Link to={`/my-account`}>
                    <div><UserOutlined/> My Account</div>
                </Link>,
            },
            {
                type: 'divider',
            },
            {
                key: '2',
                label: <div onClick={this.logOut}><LogoutOutlined/> Logout</div>,
            }
        ]

        return (
            <React.Fragment>
                <Layout>
                    <Sider style={this.props.hideMainMenu ? {display: 'none'} : {}} className={`sider`} trigger={null} collapsible collapsed={this.state.collapsed}>
                        <div className="app-main-header"><Avatar src={"/image/favicon.png"}/> <b style={this.state.collapsed ? {display: 'none'} : {fontSize: "115%"}}> Wilo Kiosk <i style={{fontWeight: "normal", fontSize: "50%"}}> v-{appVersion}</i></b></div>
                        <Menu defaultSelectedKeys={this.props.defaultSelectedKeys} defaultOpenKeys={this.props.defaultOpenKeys} mode="inline" theme="dark">
                            <Menu.Item key="m-dashboard" icon={<HomeOutlined/>}><Link to={`/`}>Dashboard</Link></Menu.Item>
                            {(this.state.listMenu.includes("2")) &&
                                <SubMenu key="sm-monitoring" icon={<FundViewOutlined/>} title="Live Monitoring">
                                    {this.state.listMenu.includes("2") && <Menu.Item key="m-monitoring-kiosk"><Link to={`/monitoring/kiosk`}>Kiosk Monitoring</Link></Menu.Item>}
                                </SubMenu>
                            }
                            {(this.state.listMenu.includes("18")) &&
                                <SubMenu key="sm-refund" icon={<ExportOutlined/>} title="Refund">
                                    {this.state.listMenu.includes("18") && <Menu.Item key="m-refund"><Link to={`/refund/refund`}>Refund</Link></Menu.Item>}
                                </SubMenu>
                            }
                            {(this.state.listMenu.includes("3") || this.state.listMenu.includes("4") || this.state.listMenu.includes("15") || this.state.listMenu.includes("17") || this.state.listMenu.includes("21")) &&
                                <SubMenu key="sm-report" icon={<FileTextOutlined/>} title="Report">
                                    {this.state.listMenu.includes("3") && <Menu.Item key="m-report-sales"><Link to={`/report/sales`}>Sales Report</Link></Menu.Item>}
                                    {this.state.listMenu.includes("4") && <Menu.Item key="m-report-performance"><Link to={`/report/performance`}>Performance Report</Link></Menu.Item>}
                                    {this.state.listMenu.includes("15") && <Menu.Item key="m-report-chart"><Link to={`/report/chart`}>Sales Chart Report</Link></Menu.Item>}
                                    {this.state.listMenu.includes("21") && <Menu.Item key="m-report-iot-chart"><Link to={`/report/iot-chart`}>IoT Chart Report</Link></Menu.Item>}
                                    {this.state.listMenu.includes("17") && <Menu.Item key="m-report-rating"><Link to={`/report/customer-rating`}>Rating Report</Link></Menu.Item>}
                                </SubMenu>
                            }
                            {/*{(this.state.listMenu.includes("19") || this.state.listMenu.includes("20")) &&
                                <SubMenu key="sm-pro-report" icon={<FileTextOutlined/>} title="Productivity Report">
                                    {this.state.listMenu.includes("19") && <Menu.Item key="m-report-water-flow"><Link to={`/report/water-flow`}>Water Flow</Link></Menu.Item>}
                                    {this.state.listMenu.includes("20") && <Menu.Item key="m-report-water-waste"><Link to={`/report/water-waste`}>Water Waste</Link></Menu.Item>}
                                </SubMenu>
                            }*/}
                            {(this.state.listMenu.includes("5") || this.state.listMenu.includes("6") || this.state.listMenu.includes("7") || this.state.listMenu.includes("8")) &&
                                <SubMenu key="sm-log" icon={<CodeOutlined/>} title="Log Activity">
                                    {this.state.listMenu.includes("5") && <Menu.Item key="m-log-kiosk"><Link to={`/log/kiosk`}>Log Kiosk</Link></Menu.Item>}
                                    {this.state.listMenu.includes("6") && <Menu.Item key="m-log-iot"><Link to={`/log/iot`}>Log IoT</Link></Menu.Item>}
                                    {this.state.listMenu.includes("7") && <Menu.Item key="m-log-user"><Link to={`/log/user`}>Log User</Link></Menu.Item>}
                                    {this.state.listMenu.includes("8") && <Menu.Item key="m-log-paymentgateway"><Link to={`/log/payment-gateway`}>Log Payment Gateway</Link></Menu.Item>}
                                </SubMenu>
                            }
                            {(this.state.listMenu.includes("9") || this.state.listMenu.includes("10") || this.state.listMenu.includes("11") || this.state.listMenu.includes("12") || this.state.listMenu.includes("13") || this.state.listMenu.includes("14") || this.state.listMenu.includes("16")) &&
                                <SubMenu key="sm-setting" icon={<SettingOutlined/>} title="Setting">
                                    {this.state.listMenu.includes("9") && <Menu.Item key="m-master-location"><Link to={`/master/location`}>Master Location</Link></Menu.Item>}
                                    {this.state.listMenu.includes("10") && <Menu.Item key="m-master-kiosk"><Link to={`/master/kiosk`}>Master Kiosk</Link></Menu.Item>}
                                    {this.state.listMenu.includes("11") && <Menu.Item key="m-master-product"><Link to={`/master/product`}>Master Product</Link></Menu.Item>}
                                    {this.state.listMenu.includes("16") && <Menu.Item key="m-master-calibration"><Link to={`/master/calibration`}>Master Calibration</Link></Menu.Item>}
                                    {/*{this.state.listMenu.includes("12") && <Menu.Item key="m-master-ad"><Link to={`/master/ad`}>Master Ads</Link></Menu.Item>}*/}
                                    {this.state.listMenu.includes("14") && <Menu.Item key="m-master-app"><Link to={`/master/app`}>Master App</Link></Menu.Item>}
                                    {this.state.listMenu.includes("13") && <Menu.Item key="m-master-user"><Link to={`/master/user`}>Master User</Link></Menu.Item>}
                                </SubMenu>
                            }
                        </Menu>
                    </Sider>
                    <Layout className="site-layout">
                        <Header className="site-layout-background">
                            <span style={this.props.hideMainMenu ? {display: 'none'} : {fontSize: "140%", marginLeft: -25}}>
                                {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                    className: 'trigger',
                                    onClick: this.toggle,
                                })}
                            </span>

                            <img alt={`logo`} style={!this.props.hideMainMenu ? {display: 'none'} : {height: 50, paddingTop: 10}} src={`/image/logo.png`}/>

                            <Dropdown className={'header-right'} menu={{items}}>
                                <span style={{cursor: "pointer"}}>
                                    <Avatar icon={<UserOutlined/>}/>
                                    <Typography.Text style={this.state.collapsed ? {display: 'none'} : {}}> {this.state.user}</Typography.Text>
                                </span>
                            </Dropdown>
                        </Header>

                        <div className={'breadcrumb-padding'}>
                            <Row>
                                <Col style={{textAlign: "left", fontSize: "125%", fontWeight: "500", color: "#009c82"}} xs={24} xl={12}>
                                    {this.props.titlePage}
                                </Col>
                                <Col style={{textAlign: "right", fontSize: '90%'}} xs={24} xl={12}>
                                    <span style={this.state.viewClass !== "mobile" ? {textAlign: "right"} : {display: 'none'}}>
                                        / {this.props.breadcrumbPath} {this.props.breadcrumbMenu}
                                    </span>
                                </Col>
                            </Row>
                        </div>

                        <Content className="site-layout-background" style={this.props.fullWidth ? {margin: '0px 16px -10px', padding: 0, minHeight: '85vh'} : {margin: '0px 16px -10px', padding: 15, minHeight: '85vh'}}>
                            <div>{this.props.children}</div>
                        </Content>
                        <Footer className={'footer-admin'}>{appName} ©{new Date().getFullYear()} v-{appVersion} </Footer>
                    </Layout>
                </Layout>
            </React.Fragment>
        )
    }
}

export default MainLayout