import axios from "axios"
import {baseUrlBackendKiosk} from "../../config/apiConfig"

class KioskApiService {
    constructor() {
        this.axios = axios.create({
            baseURL: baseUrlBackendKiosk,
        })
    }

    setToken(token) {
        this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
    }

    async login(params) {
        try {
            return await this.axios.post('/auth/login-email', params)
        } catch (e) {
            throw e?.response?.data?.message ?? "Error Server"
        }
    }

    async getMyProfile(params) {
        try {
            return await this.axios.get('/user/profile')
        } catch (e) {
            throw e?.response?.data?.message ?? "Error Server"
        }
    }

    async checkPage(pageCode) {
        try {
            return await this.axios.get(`/auth/check?page_code=${pageCode}`)
        } catch (e) {
            throw e?.response?.data?.message ?? "Error Server"
        }
    }

    async updateMyPassword(params) {
        try {
            return await this.axios.put('/user/profile/password', params)
        } catch (e) {
            throw e?.response?.data?.message ?? "Error Server"
        }
    }

    async readKioskState(params) {
        try {
            return await this.axios.get('/admin/kiosk-status')
        } catch (e) {
            throw e?.response?.data?.message ?? "Error Server"
        }
    }

    async readReportSales(params) {
        const queries = {
            ...(params?.startDate && {
                start_date: params.startDate,
            }),
            ...(params?.endDate && {
                end_date: params.endDate,
            }),
            ...(params?.kioskId && {
                kiosk_id: params.kioskId,
            }),
            ...(params?.status && {
                status: params.status,
            })
        }

        try {
            return await this.axios.get(`/admin/report-sales`, {
                params: {...queries,}
            })
        } catch (e) {
            throw e?.response?.data?.message ?? "Error Server"
        }
    }

    async readReportSummarySales() {
        try {
            return await this.axios.get(`/admin/report-year-summary-sales`,)
        } catch (e) {
            throw e?.response?.data?.message ?? "Error Server"
        }
    }

    async readReportSummarySales2() {
        try {
            return await this.axios.get(`/admin/report-year-summary-sales?quarterly=true`,)
        } catch (e) {
            throw e?.response?.data?.message ?? "Error Server"
        }
    }

    async readReportPerformance(params) {
        const queries = {
            ...(params?.month && {
                month: params.month,
            })
        }

        try {
            return await this.axios.get(`/admin/report-performance`, {
                params: {...queries,}
            })
        } catch (e) {
            throw e?.response?.data?.message ?? "Error Server"
        }
    }


    async readMasterUser(params) {
        try {
            return await this.axios.get('/master/user')
        } catch (e) {
            throw e?.response?.data?.message ?? "Error Server"
        }
    }

    async createMasterUser(params) {
        try {
            return await this.axios.post('/master/user', params)
        } catch (e) {
            throw e?.response?.data?.message ?? "Error Server"
        }
    }

    async updateMasterUser(params) {
        try {
            return await this.axios.put('/master/user', params)
        } catch (e) {
            throw e?.response?.data?.message ?? "Error Server"
        }
    }

    async updateMasterApp(params) {
        try {
            return await this.axios.put('/master/app', params)
        } catch (e) {
            throw e?.response?.data?.message ?? "Error Server"
        }
    }

    async readMasterApp(params) {
        try {
            return await this.axios.get('/app')
        } catch (e) {
            throw e?.response?.data?.message ?? "Error Server"
        }
    }

    async readMasterLocation(params) {
        try {
            return await this.axios.get('/master/kiosk-location')
        } catch (e) {
            throw e?.response?.data?.message ?? "Error Server"
        }
    }

    async readMasterAds(params) {
        try {
            return await this.axios.get('/master/ad')
        } catch (e) {
            throw e?.response?.data?.message ?? "Error Server"
        }
    }

    async createMasterAds(params) {
        try {
            return await this.axios.post('/master/ad', params)
        } catch (e) {
            throw e?.response?.data?.message ?? "Error Server"
        }
    }

    async updateMasterAds(params) {
        try {
            return await this.axios.put('/master/ad', params)
        } catch (e) {
            throw e?.response?.data?.message ?? "Error Server"
        }
    }

    async createMasterLocation(params) {
        try {
            return await this.axios.post('/master/kiosk-location', params)
        } catch (e) {
            throw e?.response?.data?.message ?? "Error Server"
        }
    }

    async updateMasterLocation(params) {
        try {
            return await this.axios.put('/master/kiosk-location', params)
        } catch (e) {
            throw e?.response?.data?.message ?? "Error Server"
        }
    }

    async readMasterProduct(params) {
        try {
            return await this.axios.get('/master/product')
        } catch (e) {
            throw e?.response?.data?.message ?? "Error Server"
        }
    }

    async updateMasterProduct(params) {
        try {
            return await this.axios.put('/master/product', params)
        } catch (e) {
            throw e?.response?.data?.message ?? "Error Server"
        }
    }

    async readMasterKiosk(params) {
        try {
            return await this.axios.get('/master/kiosk')
        } catch (e) {
            throw e?.response?.data?.message ?? "Error Server"
        }
    }

    async readMasterCalibration(params) {
        try {
            return await this.axios.get('/master/kiosk-product-calibration')
        } catch (e) {
            throw e?.response?.data?.message ?? "Error Server"
        }
    }

    async updateMasterCalibration(params) {
        try {
            return await this.axios.put('/master/kiosk-product-calibration', params)
        } catch (e) {
            throw e?.response?.data?.message ?? "Error Server"
        }
    }

    async updateMasterKiosk(params) {
        try {
            return await this.axios.put('/master/kiosk', params)
        } catch (e) {
            throw e?.response?.data?.message ?? "Error Server"
        }
    }

    async readLogKiosk(params) {
        const queries = {
            ...(params?.startDate && {
                start_date: params.startDate,
            }),
            ...(params?.endDate && {
                end_date: params.endDate,
            }),
            ...(params?.kioskId && {
                kiosk_id: params.kioskId,
            })
        }

        try {
            return await this.axios.get(`/log/kiosk`, {
                params: {...queries,}
            })
        } catch (e) {
            throw e?.response?.data?.message ?? "Error Server"
        }
    }

    async readLogUser(params) {
        const queries = {
            ...(params?.startDate && {
                start_date: params.startDate,
            }),
            ...(params?.endDate && {
                end_date: params.endDate,
            })
        }

        try {
            return await this.axios.get(`/log/user`, {
                params: {...queries,}
            })
        } catch (e) {
            throw e?.response?.data?.message ?? "Error Server"
        }
    }

    async readLogIot(params) {
        const queries = {
            ...(params?.startDate && {
                start_date: params.startDate,
            }),
            ...(params?.endDate && {
                end_date: params.endDate,
            }),
            ...(params?.startTime && {
                start_time: params.startTime,
            }),
            ...(params?.endTime && {
                end_time: params.endTime,
            }),
            ...(params?.kioskId && {
                kiosk_id: params.kioskId,
            })
        }

        try {
            return await this.axios.get(`/log/iot`, {
                params: {...queries,}
            })
        } catch (e) {
            throw e?.response?.data?.message ?? "Error Server"
        }
    }

    async readLogPaymentGateway(params) {
        const queries = {
            ...(params?.startDate && {
                start_date: params.startDate,
            }),
            ...(params?.endDate && {
                end_date: params.endDate,
            })
        }

        try {
            return await this.axios.get(`/log/payment-callback`, {
                params: {...queries,}
            })
        } catch (e) {
            throw e?.response?.data?.message ?? "Error Server"
        }
    }

    async readReportRating(params) {
        const queries = {
            ...(params?.startDate && {
                start_date: params.startDate,
            }),
            ...(params?.endDate && {
                end_date: params.endDate,
            }),
            ...(params?.kioskId && {
                kiosk_id: params.kioskId,
            })
        }

        try {
            return await this.axios.get(`/admin/customer-rating`, {
                params: {...queries,}
            })
        } catch (e) {
            throw e?.response?.data?.message ?? "Error Server"
        }
    }

    async readListRefund() {
        const queries = {}
        try {
            return await this.axios.get(`/refund`, {
                params: {...queries,}
            })
        } catch (e) {
            throw e?.response?.data?.message ?? "Error Server"
        }
    }

    async doRefund(params) {
        try {
            return await this.axios.post('/refund', params)
        } catch (e) {
            throw e?.response?.data?.message ?? "Error Server"
        }
    }

    async doManualRefund(params) {
        try {
            return await this.axios.post('/refund-manual', params)
        } catch (e) {
            throw e?.response?.data?.message ?? "Error Server"
        }
    }

    async resetIotValue(params) {
        try {
            return await this.axios.post('/master/reset-kiosk-value', params)
        } catch (e) {
            throw e?.response?.data?.message ?? "Error Server"
        }
    }

    async readReportWaterFlowWaste(params) {
        const queries = {
            ...(params?.type && {
                type: params.type,
            }),
            ...(params?.startDate && {
                start_date: params.startDate,
            }),
            ...(params?.endDate && {
                end_date: params.endDate,
            }),
            ...(params?.kioskId && {
                kiosk_id: params.kioskId,
            })
        }

        try {
            return await this.axios.get(`/admin/kiosk-productivity`, {
                params: {...queries,}
            })
        } catch (e) {
            throw e?.response?.data?.message ?? "Error Server"
        }
    }

    async readReportIot(params) {
        const queries = {
            ...(params?.startDate && {
                start_date: params.startDate,
            }),
            ...(params?.endDate && {
                end_date: params.endDate,
            }),
            ...(params?.kioskId && {
                kiosk_id: params.kioskId,
            }),
            ...(params?.waterLevel && {
                water_level: params.waterLevel,
            })
        }

        try {
            return await this.axios.get(`/admin/iot-report`, {
                params: {...queries,}
            })
        } catch (e) {
            throw e?.response?.data?.message ?? "Error Server"
        }
    }
}

export const kioskApiService = new KioskApiService()