import React, {Component} from 'react'
import Cookies from 'universal-cookie'
import {Buffer} from "buffer"
import {kioskApiService} from "../../services/wiloKiosk/KioskApiService"
import withAuth from "../../hoc/withAuth"
import {Card, Col, DatePicker, Row, Select, Spin} from 'antd'
import MainLayout from "../../components/layout/MainLayout"
import {Divider, message, Form} from "antd"
import dayjs from "dayjs"
import KioskFilter from "../../components/general/KioskFilter"
import {Chart as ChartJS, CategoryScale, ArcElement, Tooltip, Legend, LinearScale, PointElement, LineElement, BarElement, Title} from 'chart.js'
import {Line} from 'react-chartjs-2'
import {CloudDownloadOutlined} from "@ant-design/icons";

ChartJS.register(ArcElement, CategoryScale, Tooltip, Legend, LinearScale, PointElement, LineElement, BarElement, Title)

class ReportIotChart extends Component {
    constructor(props) {
        super(props)
        this.linePt1 = React.createRef()
        this.linePt2 = React.createRef()
        this.linePt3 = React.createRef()
        this.linePh = React.createRef()
        this.lineTds = React.createRef()
        this.cookies = new Cookies()
        this.initState = {
            token: (this.cookies.get('_wk-t')) ? JSON.parse(Buffer.from(this.cookies.get('_wk-t'), 'base64').toString()) : '',
            fetchProcessing: false,
            startDate: dayjs().subtract(13, 'day'),
            endDate: dayjs(),
            kioskFilter: 0,
            kioskSelect: "ALL",
            kioskName: "ALL",
            lineLabel: [],
            avgPt1: [],
            maxPt1: [],
            minPt1: [],
            avgPt2: [],
            maxPt2: [],
            minPt2: [],
            avgPt3: [],
            maxPt3: [],
            minPt3: [],
            avgPh: [],
            maxPh: [],
            minPh: [],
            avgTds: [],
            maxTds: [],
            minTds: [],
            waterLevel: "ALL"
        }
        this.state = {...this.initState}
    }

    linePt1DownloadImage = () => {
        const link = document.createElement('a')
        link.download = "pt1.png"
        link.href = this.linePt1.current.toBase64Image('image/png', 1)
        link.click()
    }
    linePt2DownloadImage = () => {
        const link = document.createElement('a')
        link.download = "pt2.png"
        link.href = this.linePt2.current.toBase64Image('image/png', 1)
        link.click()
    }
    linePt3DownloadImage = () => {
        const link = document.createElement('a')
        link.download = "pt3.png"
        link.href = this.linePt3.current.toBase64Image('image/png', 1)
        link.click()
    }
    linePhDownloadImage = () => {
        const link = document.createElement('a')
        link.download = "tds.png"
        link.href = this.linePh.current.toBase64Image('image/png', 1)
        link.click()
    }
    lineTdsDownloadImage = () => {
        const link = document.createElement('a')
        link.download = "ph.png"
        link.href = this.lineTds.current.toBase64Image('image/png', 1)
        link.click()
    }

    fetchData = async () => {
        if (this.state.kioskSelect === "ALL") {
            message.info("Please choose kiosk at filter")
            return
        }

        await this.setState({
            fetchProcessing: true,
        })

        try {
            await kioskApiService.setToken(this.state.token)
            const chartData = await kioskApiService.readReportIot({
                startDate: dayjs(this.state.startDate).format("YYYY-MM-DD"),
                endDate: dayjs(this.state.endDate).format("YYYY-MM-DD"),
                kioskId: this.state.kioskSelect,
                waterLevel: this.state.waterLevel
            })

            const lineLabel = []
            const avgPt1 = []
            const maxPt1 = []
            const minPt1 = []
            const avgPt2 = []
            const maxPt2 = []
            const minPt2 = []
            const avgPt3 = []
            const maxPt3 = []
            const minPt3 = []
            const avgPh = []
            const maxPh = []
            const minPh = []
            const avgTds = []
            const maxTds = []
            const minTds = []
            for (const element of chartData.data.data) {
                lineLabel.push(element.created_date)
                avgPt1.push(element.avg_pt1)
                maxPt1.push(element.max_pt1)
                minPt1.push(element.min_pt1)
                avgPt2.push(element.avg_pt2)
                maxPt2.push(element.max_pt2)
                minPt2.push(element.min_pt2)
                avgPt3.push(element.avg_pt3)
                maxPt3.push(element.max_pt3)
                minPt3.push(element.min_pt3)
                avgPh.push(element.avg_ph)
                maxPh.push(element.max_ph)
                minPh.push(element.min_ph)
                avgTds.push(element.avg_tds)
                maxTds.push(element.max_tds)
                minTds.push(element.min_tds)
            }

            this.setState({
                fetchProcessing: false,
                lineLabel: lineLabel,
                avgPt1: avgPt1,
                maxPt1: maxPt1,
                minPt1: minPt1,
                avgPt2: avgPt2,
                maxPt2: maxPt2,
                minPt2: minPt2,
                avgPt3: avgPt3,
                maxPt3: maxPt3,
                minPt3: minPt3,
                avgPh: avgPh,
                maxPh: maxPh,
                minPh: minPh,
                avgTds: avgTds,
                maxTds: maxTds,
                minTds: minTds
            })
        } catch (e) {
            this.setState({
                fetchProcessing: false
            })
            message.info(e)
        }
    }

    onFieldChange = async (target, value) => {
        const currentState = {...this.state}
        switch (target) {
            case "endDate":
                if (value) {
                    currentState.startDate = value.subtract(13, 'day')
                    currentState.endDate = value
                }
                break
            case "waterLevel":
                currentState.waterLevel = value
                break
            default:
                break
        }
        this.setState({
            ...currentState,
        })
        await this.fetchData()
    }

    async componentDidMount() {
        await this.fetchData()
    }

    FilterChangeAction = async (data) => {
        const picked = data.kioskData.find((o) => o.id === data.kioskSelect)
        await this.setState({
            kioskFilter: data?.kioskFilter,
            kioskSelect: data?.kioskSelect,
            kioskName: picked?.name
        })
        await this.fetchData()
    }

    render() {
        return (
            <MainLayout titlePage={`IoT Chart Report`} fullWidth={true} breadcrumbPath={`Report`} breadcrumbMenu={`/ IoT Chart Report`} defaultSelectedKeys={['m-report-iot-chart']} defaultOpenKeys={['sm-report']}>
                <div className={`m-10`}>
                    <Row>
                        <Col xl={{span: 2, order: 1}} xs={{span: 24, order: 2}} className={`p-5`} style={{marginTop: 3}}>
                            <i className={`extra-small-text-grey`}> Filter</i><br/>
                            <KioskFilter token={this.state.token} formChildAction={this.FilterChangeAction} kioskSelect={this.state.kioskSelect}/>
                        </Col>
                        <Col xl={{span: 4, order: 2}} xs={{span: 24, order: 3}} className={`p-5`} order={2}>
                            <Form.Item>
                                <i className={`extra-small-text-grey`}>Date</i><br/>
                                <DatePicker value={dayjs(this.state.endDate, 'YYYY/MM/DD')} onChange={event => this.onFieldChange("endDate", event)} format={'YYYY-MM-DD'} style={{width: "100%"}}/>
                            </Form.Item>
                        </Col>
                        <Col xl={{span: 4, order: 2}} xs={{span: 24, order: 3}} className={`p-5`} order={2}>
                            <Form.Item>
                                <i className={`small-text-grey`}>Water Level</i><br/>
                                <Select
                                    onChange={value => this.onFieldChange("waterLevel", value)}
                                    value={this.state.waterLevel}
                                    style={{width: "100%"}}
                                    placeholder={`Water Level`}
                                >
                                    <Select.Option key={`ALL`} value={`ALL`}>{`ALL`}</Select.Option>
                                    <Select.Option key={`FULL`} value={`FULL`}>{`FULL`}</Select.Option>
                                    <Select.Option key={`LOW`} value={`LOW`}>{`LOW`}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                    </Row>
                </div>

                <Divider className={`divider-thin`}/>

                <div style={(this.state.kioskSelect === "ALL") ? {textAlign: "center", fontSize: "125%"} : {display: 'none'}}>
                    Please select the kiosk to be monitored in the filter section.
                </div>
                <div style={(this.state.kioskSelect !== "ALL") ? {} : {display: 'none'}}>
                    <h5 style={{fontSize: "125%", color: "gray", textAlign: "center", marginBottom: 20}}>Chart IoT {this.state.kioskName} | {dayjs(this.state.startDate).format("YYYY-MM-DD")} - {dayjs(this.state.endDate).format("YYYY-MM-DD")} </h5>

                    <div style={this.state.fetchProcessing ? {} : {display: 'none'}} className={`spinCenter`}>
                        <Spin size="large"/>
                    </div>
                    <div style={!this.state.fetchProcessing ? {} : {display: 'none'}}>
                        <Row>
                            <Col xs={24} xl={12} style={{padding: 10}}>
                                <Card size="small" hoverable={true} style={{margin: 10}}>
                                    <div style={{paddingBottom: 5}}>
                                        {this.state.kioskName} - PT Inlet <span style={{float: "right", cursor: "pointer"}}><CloudDownloadOutlined onClick={this.linePt1DownloadImage}/></span>
                                    </div>
                                    <Divider style={{padding: 10, margin: 0}}/>
                                    <div style={{height: 380, width: "100%"}}>
                                        <Line
                                            ref={this.linePt1}
                                            data={{
                                                labels: this.state.lineLabel,
                                                datasets: [
                                                    {
                                                        label: 'Avg Pressure',
                                                        data: this.state.avgPt1,
                                                        borderColor: '#009c82',
                                                        backgroundColor: '#009c82',
                                                    },
                                                    {
                                                        label: 'Max Pressure',
                                                        data: this.state.maxPt1,
                                                        borderColor: '#c95050',
                                                        backgroundColor: '#c95050',
                                                    },
                                                    {
                                                        label: 'Min Pressure',
                                                        data: this.state.minPt1,
                                                        borderColor: '#5450c9',
                                                        backgroundColor: '#5450c9',
                                                    },
                                                ],
                                            }}
                                            options={{
                                                responsive: true,
                                                maintainAspectRatio: false,
                                                plugins: {title: {display: true, text: 'Water Pressure PT Inlet (Bar)', font: {size: 15}}}
                                            }}
                                        />
                                    </div>
                                </Card>
                            </Col>
                            <Col xs={24} xl={12} style={{padding: 10}}>
                                <Card size="small" hoverable={true} style={{margin: 10}}>
                                    <div style={{paddingBottom: 5}}>
                                        {this.state.kioskName} - PT Feed <span style={{float: "right", cursor: "pointer"}}><CloudDownloadOutlined onClick={this.linePt2DownloadImage}/></span>
                                    </div>
                                    <Divider style={{padding: 10, margin: 0}}/>
                                    <div style={{height: 380, width: "100%"}}>
                                        <Line
                                            ref={this.linePt2}
                                            data={{
                                                labels: this.state.lineLabel,
                                                datasets: [
                                                    {
                                                        label: 'Avg Pressure',
                                                        data: this.state.avgPt2,
                                                        borderColor: '#009c82',
                                                        backgroundColor: '#009c82',
                                                    },
                                                    {
                                                        label: 'Max Pressure',
                                                        data: this.state.maxPt2,
                                                        borderColor: '#c95050',
                                                        backgroundColor: '#c95050',
                                                    },
                                                    {
                                                        label: 'Min Pressure',
                                                        data: this.state.minPt2,
                                                        borderColor: '#5450c9',
                                                        backgroundColor: '#5450c9',
                                                    },
                                                ],
                                            }}
                                            options={{
                                                responsive: true,
                                                maintainAspectRatio: false,
                                                plugins: {title: {display: true, text: 'Water Pressure PT Feed (Bar)', font: {size: 15}}}
                                            }}
                                        />
                                    </div>
                                </Card>
                            </Col>
                            <Col xs={24} xl={12} style={{padding: 10}}>
                                <Card size="small" hoverable={true} style={{margin: 10}}>
                                    <div style={{paddingBottom: 5}}>
                                        {this.state.kioskName} - PT HPP <span style={{float: "right", cursor: "pointer"}}><CloudDownloadOutlined onClick={this.linePt3DownloadImage}/></span>
                                    </div>
                                    <Divider style={{padding: 10, margin: 0}}/>
                                    <div style={{height: 380, width: "100%"}}>
                                        <Line
                                            ref={this.linePt3}
                                            data={{
                                                labels: this.state.lineLabel,
                                                datasets: [
                                                    {
                                                        label: 'Avg Pressure',
                                                        data: this.state.avgPt3,
                                                        borderColor: '#009c82',
                                                        backgroundColor: '#009c82',
                                                    },
                                                    {
                                                        label: 'Max Pressure',
                                                        data: this.state.maxPt3,
                                                        borderColor: '#c95050',
                                                        backgroundColor: '#c95050',
                                                    },
                                                    {
                                                        label: 'Min Pressure',
                                                        data: this.state.minPt3,
                                                        borderColor: '#5450c9',
                                                        backgroundColor: '#5450c9',
                                                    },
                                                ],
                                            }}
                                            options={{
                                                responsive: true,
                                                maintainAspectRatio: false,
                                                plugins: {title: {display: true, text: 'Water Pressure PT HPP (Bar)', font: {size: 15}}}
                                            }}
                                        />
                                    </div>
                                </Card>
                            </Col>
                            <Col xs={24} xl={12} style={{padding: 10}}>
                                <Card size="small" hoverable={true} style={{margin: 10}}>
                                    <div style={{paddingBottom: 5}}>
                                        {this.state.kioskName} - PH <span style={{float: "right", cursor: "pointer"}}><CloudDownloadOutlined onClick={this.linePhDownloadImage}/></span>
                                    </div>
                                    <Divider style={{padding: 10, margin: 0}}/>
                                    <div style={{height: 380, width: "100%"}}>
                                        <Line
                                            ref={this.linePh}
                                            data={{
                                                labels: this.state.lineLabel,
                                                datasets: [
                                                    {
                                                        label: 'Avg PH',
                                                        data: this.state.avgPh,
                                                        borderColor: '#009c82',
                                                        backgroundColor: '#009c82',
                                                    },
                                                    {
                                                        label: 'Max PH',
                                                        data: this.state.maxPh,
                                                        borderColor: '#c95050',
                                                        backgroundColor: '#c95050',
                                                    },
                                                    {
                                                        label: 'Min PH',
                                                        data: this.state.minPh,
                                                        borderColor: '#5450c9',
                                                        backgroundColor: '#5450c9',
                                                    },
                                                ],
                                            }}
                                            options={{
                                                responsive: true,
                                                maintainAspectRatio: false,
                                                plugins: {title: {display: true, text: 'PH', font: {size: 15}}}
                                            }}
                                        />
                                    </div>
                                </Card>
                            </Col>
                            <Col xs={24} xl={12} style={{padding: 10}}>
                                <Card size="small" hoverable={true} style={{margin: 10}}>
                                    <div style={{paddingBottom: 5}}>
                                        {this.state.kioskName} - TDS <span style={{float: "right", cursor: "pointer"}}><CloudDownloadOutlined onClick={this.lineTdsDownloadImage}/></span>
                                    </div>
                                    <Divider style={{padding: 10, margin: 0}}/>
                                    <div style={{height: 380, width: "100%"}}>
                                        <Line
                                            ref={this.lineTds}
                                            data={{
                                                labels: this.state.lineLabel,
                                                datasets: [
                                                    {
                                                        label: 'Avg PH',
                                                        data: this.state.avgTds,
                                                        borderColor: '#009c82',
                                                        backgroundColor: '#009c82',
                                                    },
                                                    {
                                                        label: 'Max PH',
                                                        data: this.state.maxTds,
                                                        borderColor: '#c95050',
                                                        backgroundColor: '#c95050',
                                                    },
                                                    {
                                                        label: 'Min PH',
                                                        data: this.state.minTds,
                                                        borderColor: '#5450c9',
                                                        backgroundColor: '#5450c9',
                                                    },
                                                ],
                                            }}
                                            options={{
                                                responsive: true,
                                                maintainAspectRatio: false,
                                                plugins: {title: {display: true, text: 'TDS', font: {size: 15}}}
                                            }}
                                        />
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </MainLayout>
        )
    }
}

export default withAuth(ReportIotChart, 21)