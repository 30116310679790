import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

export default async function exportPdf(params) {
    const { title, generatedTime, data, fileName } = params;

    const doc = new jsPDF();

    doc.setFontSize(14);
    doc.text(title, 105, 20, { align: 'center' });
    doc.setFontSize(8);
    doc.text(generatedTime, 200, 30, { align: 'right' });

    autoTable(doc, {startY: 35, body: data, styles: { fontSize: 8 }});

    doc.save(`${fileName}.pdf`);
}

/*
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {saveAs} from 'file-saver';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function transformData(data) {
    return data.map(row => row.map(cell => ({text: cell.toString(), fontSize: 8})));
}

export default async function exportPdf(params) {
    const {title, generatedTime, data, fileName} = params;

    const docDefinition = {
        content: [
            {text: title, fontSize: 14, bold: true, alignment: 'center'},
            {text: generatedTime, fontSize: 10, alignment: 'right'},
            {
                table: {
                    headerRows: 1,
                    body: transformData(data)
                }
            }
        ]
    };

    const pdfDoc = pdfMake.createPdf(docDefinition);
    pdfDoc.getBuffer(buffer => {
        const blob = new Blob([buffer], {type: 'application/pdf'});
        saveAs(blob, `${fileName}.pdf`);
    });
}*/
