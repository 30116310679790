import React, {Component} from 'react'
import Cookies from 'universal-cookie'
import {Buffer} from "buffer"
import withAuth from "../../hoc/withAuth"
import MainLayout from "../../components/layout/MainLayout"
import {Col, message, Row, Tabs, Spin, Button, Modal, Form, Collapse, Alert, Input, Popover, Divider} from "antd"
import {kioskApiService} from "../../services/wiloKiosk/KioskApiService"
import {QuestionCircleOutlined, SaveOutlined} from "@ant-design/icons"
import CropBackgroundImage from "../../components/general/CropBackgroundImage"

const {Panel} = Collapse

class MasterApp extends Component {
    constructor(props) {
        super(props)
        this.cookies = new Cookies()
        this.initState = {
            token: (this.cookies.get('_wk-t')) ? JSON.parse(Buffer.from(this.cookies.get('_wk-t'), 'base64').toString()) : '',
            fetchProcessing: true,
            showModalConfirmTimeout: false,
            showModalConfirmContent: false,
            confirmLoading: false,
            appData: {},

            showModalUploadApp: false,
            freezeStatus: false,
            base64BackgroundImage: "",
            timeoutQrPayment: 30,
            timeoutSuccessfulPayment: 3,
            timeoutFailedPayment: 3,
            timeoutAutomaticWashing: 30,
            timeoutAutomaticFilling: 30,
            timeoutAutomaticFillingWrongPosition: 30,
            timeoutCompleteFilling: 3,
            timeoutCompleteFeedback: 15,
            timeoutThankYouNote: 3,

            videoPraWashing: "",
            videoPraFilling: "",
            videoAdsFilling: "",
            imageAdsFilling: "",
        }
        this.state = {...this.initState}
    }

    fetchData = async () => {
        try {
            await kioskApiService.setToken(this.state.token)
            const appData = await kioskApiService.readMasterApp()
            this.setState({
                fetchProcessing: false,
                appData: appData.data.data,
                timeoutQrPayment: appData.data.data.timeout_qr_payment,
                timeoutSuccessfulPayment: appData.data.data.timeout_successful_payment,
                timeoutFailedPayment: appData.data.data.timeout_failed_payment,
                timeoutAutomaticWashing: appData.data.data.timeout_automatic_washing,
                timeoutAutomaticFilling: appData.data.data.timeout_automatic_filling,
                timeoutAutomaticFillingWrongPosition: appData.data.data.timeout_automatic_filling_wrong_position,
                timeoutCompleteFilling: appData.data.data.timeout_complete_filling,
                timeoutCompleteFeedback: appData.data.data.timeout_complete_feedback,
                timeoutThankYouNote: appData.data.data.timeout_thank_you_note,
                videoPraWashing: `https://www.youtube.com/watch?v=${appData.data.data.video_pra_washing}`,
                videoPraFilling: `https://www.youtube.com/watch?v=${appData.data.data.video_pra_filling}`,
                videoAdsFilling: `https://www.youtube.com/watch?v=${appData.data.data.video_ads_filling}`,
                imageAdsFilling: appData.data.data.image_ads_filling,
            })
        } catch (e) {
            this.setState({
                processing: false,
                appData: [],
            })
            message.info(e)
        }
    }

    async componentDidMount() {
        await this.fetchData()
    }

    onCropAppImage = async (base64DataImagePng) => {
        await this.setState({
            base64BackgroundImage: base64DataImagePng,
        })
    }

    updateTimeout = async () => {
        await this.setState({
            confirmLoading: true
        })

        const dataUpdate = {
            type: "UPDATE_TIMEOUT",
            timeout_qr_payment: Number(this.state.timeoutQrPayment),
            timeout_successful_payment: Number(this.state.timeoutSuccessfulPayment),
            timeout_failed_payment: Number(this.state.timeoutFailedPayment),
            timeout_automatic_washing: Number(this.state.timeoutAutomaticWashing),
            timeout_automatic_filling: Number(this.state.timeoutAutomaticFilling),
            timeout_automatic_filling_wrong_position: Number(this.state.timeoutAutomaticFillingWrongPosition),
            timeout_complete_filling: Number(this.state.timeoutCompleteFilling),
            timeout_complete_feedback: Number(this.state.timeoutCompleteFeedback),
            timeout_thank_you_note: Number(this.state.timeoutThankYouNote),
        }

        try {
            await kioskApiService.updateMasterApp(dataUpdate)
            Modal.success({title: 'Update Success', content: 'Update Timeout Success'})
            this.setState({confirmLoading: false, showModalConfirmTimeout: false})
            await this.fetchData()
        } catch (e) {
            this.setState({confirmLoading: false})
            return Modal.error({title: 'Update Failed', content: e})
        }
    }

    updateContent = async () => {
        const idYoutubePraWashing = this.state.videoPraWashing.split('v=')[1].split('&')[0];
        if (!idYoutubePraWashing) {
            return Modal.error({title: 'Update Failed', content: "Youtube Video Pra Washing not valid"})
        }
        const idYoutubePraFilling = this.state.videoPraFilling.split('v=')[1].split('&')[0];
        if (!idYoutubePraFilling) {
            return Modal.error({title: 'Update Failed', content: "Youtube Video Pra Filling not valid"})
        }
        const idYoutubeAdsFilling = this.state.videoAdsFilling.split('v=')[1].split('&')[0];
        if (!idYoutubeAdsFilling) {
            return Modal.error({title: 'Update Failed', content: "Youtube Video Ads Filling not valid"})
        }

        await this.setState({
            confirmLoading: true
        })

        const dataUpdate = {
            type: "UPDATE_CONTENT",
            video_pra_washing: idYoutubePraWashing,
            video_pra_filling: idYoutubePraFilling,
            video_ads_filling: idYoutubeAdsFilling,
            image_ads_filling: this.state.imageAdsFilling,
        }

        try {
            await kioskApiService.updateMasterApp(dataUpdate)
            Modal.success({title: 'Update Success', content: 'Update Content Success'})
            this.setState({confirmLoading: false, showModalConfirmContent: false})
            await this.fetchData()
        } catch (e) {
            this.setState({confirmLoading: false})
            return Modal.error({title: 'Update Failed', content: e})
        }
    }

    saveAppImage = async () => {
        if (this.state.base64BackgroundImage?.length < 10) {
            Modal.error({title: 'Error', content: "Terjadi error pada saat proses cropping, mohon lakukan upload image sekali lagi"})
            return
        }

        this.setState({freezeStatus: true})

        try {
            await kioskApiService.updateMasterApp({
                type: "UPDATE_BACKGROUND_IMAGE",
                background_url: this.state.base64BackgroundImage
            })
            Modal.success({title: 'Update Success', content: 'Update app Success'})
            this.setState({freezeStatus: false, base64BackgroundImage: "", showModalUploadApp: false})
            await this.fetchData()
        } catch (e) {
            this.setState({freezeStatus: false})
            return Modal.error({title: 'Update Failed', content: e})
        }
    }

    onFieldChange = (target, value) => {
        const currentState = {...this.state}
        switch (target) {
            case "timeoutQrPayment":
                currentState.timeoutQrPayment = value.replace(/[^0-9.]/g, '')
                break
            case "timeoutSuccessfulPayment":
                currentState.timeoutSuccessfulPayment = value.replace(/[^0-9.]/g, '')
                break
            case "timeoutFailedPayment":
                currentState.timeoutFailedPayment = value.replace(/[^0-9.]/g, '')
                break
            case "timeoutAutomaticWashing":
                currentState.timeoutAutomaticWashing = value.replace(/[^0-9.]/g, '')
                break
            case "timeoutAutomaticFilling":
                currentState.timeoutAutomaticFilling = value.replace(/[^0-9.]/g, '')
                break
            case "timeoutAutomaticFillingWrongPosition":
                currentState.timeoutAutomaticFillingWrongPosition = value.replace(/[^0-9.]/g, '')
                break
            case "timeoutCompleteFilling":
                currentState.timeoutCompleteFilling = value.replace(/[^0-9.]/g, '')
                break
            case "timeoutCompleteFeedback":
                currentState.timeoutCompleteFeedback = value.replace(/[^0-9.]/g, '')
                break
            case "timeoutThankYouNote":
                currentState.timeoutThankYouNote = value.replace(/[^0-9.]/g, '')
                break
            case "videoPraWashing":
                currentState.videoPraWashing = value.trim()
                break
            case "videoPraFilling":
                currentState.videoPraFilling = value.trim()
                break
            case "videoAdsFilling":
                currentState.videoAdsFilling = value.trim()
                break
            case "imageAdsFilling":
                currentState.imageAdsFilling = value.trim()
                break
            default:
                break
        }
        this.setState({
            ...currentState,
        })
    }

    render() {
        const contentTimoutQrPayment = (
            <div>
                <p className={`mb-5`}><b>Timeout QR Payment (seconds)</b></p>
                <p className={`mb-5`}>The duration given to consumers <br/> to make payments through QR Scan (QRIS).</p>
                <p className={`mb-5`}><i>Lama waktu yang diberikan kepada konsumen <br/>untuk melakukan pembayaran melalui QR Scan (QRIS)</i></p>
            </div>
        )
        const timeoutSuccessfulPayment = (
            <div>
                <p className={`mb-5`}><b>Timeout Successful Payment (seconds)</b></p>
                <p className={`mb-5`}>The duration given to consumers <br/> to view the successful payment status, and it will proceed to the next process.</p>
                <p className={`mb-5`}><i>Lama waktu yang diberikan kepada konsumen <br/>untuk melihat status pembayaran sukses, dan akan dilanjutkan ke proses selanjutnya</i></p>
            </div>
        )
        const timeoutFailedPayment = (
            <div>
                <p className={`mb-5`}><b>Timeout Failed Payment (seconds)</b></p>
                <p className={`mb-5`}>The duration given to consumers <br/> to view the failed payment status, and it will proceed to the next process.</p>
                <p className={`mb-5`}><i>Lama waktu yang diberikan kepada konsumen <br/>untuk melihat status pembayaran gagal, dan akan dilanjutkan ke proses selanjutnya</i></p>
            </div>
        )
        const timeoutAutomaticWashing = (
            <div>
                <p className={`mb-5`}><b>Timeout Automatic Washing (seconds)</b></p>
                <p className={`mb-5`}>The duration given to consumers <br/> To proceed to the washing process, if the start washing button is not pressed.</p>
                <p className={`mb-5`}><i>Lama waktu yang diberikan kepada konsumen <br/>untuk melanjutkan ke proses pencucian, jika tidak menekan tombol mulai pencucian</i></p>
            </div>
        )
        const timeoutAutomaticFilling = (
            <div>
                <p className={`mb-5`}><b>Timeout Automatic Filling (seconds)</b></p>
                <p className={`mb-5`}>The duration given to consumers <br/> To proceed to the filling process, if the start filling button is not pressed.</p>
                <p className={`mb-5`}><i>Lama waktu yang diberikan kepada konsumen <br/>untuk melanjutkan ke proses pengisian, jika tidak menekan tombol mulai pengisian</i></p>
            </div>
        )
        const timeoutAutomaticFillingWrongPosition = (
            <div>
                <p className={`mb-5`}><b>Timeout Automatic Filling From Wrong Position (seconds)</b></p>
                <p className={`mb-5`}>The duration given to consumers <br/> To proceed to the filling process (From Wrong Position), if the start filling button is not pressed.</p>
                <p className={`mb-5`}><i>Lama waktu yang diberikan kepada konsumen <br/>untuk melanjutkan ke proses pengisian (Dari Posisi Yang Salah), jika tidak menekan tombol mulai pengisian</i></p>
            </div>
        )
        const timeoutCompleteFilling = (
            <div>
                <p className={`mb-5`}><b>Timeout Complete Filling (seconds)</b></p>
                <p className={`mb-5`}>The duration given to consumers <br/> to proceed to the Rating / Feedback Page.</p>
                <p className={`mb-5`}><i>Lama waktu yang diberikan kepada konsumen <br/>untuk dilanjutkan ke halaman Rating / Feedback.</i></p>
            </div>
        )
        const timeoutRating = (
            <div>
                <p className={`mb-5`}><b>Timeout Rating (seconds)</b></p>
                <p className={`mb-5`}>The duration given to consumers <br/> To proceed to the thank you page if the consumer does not provide a rating.</p>
                <p className={`mb-5`}><i>Lama waktu yang diberikan kepada konsumen <br/>untuk dilanjutkan ke ucapan terima kasih, jika konsumen tidak memberikan rating.</i></p>
            </div>
        )
        const timeoutThankYouNote = (
            <div>
                <p className={`mb-5`}><b>Timeout Thank you note (seconds)</b></p>
                <p className={`mb-5`}>The duration given to consumers <br/> To read the thank-you message and the page will be returned to the kiosk page.</p>
                <p className={`mb-5`}><i>Lama waktu yang diberikan kepada konsumen <br/>untuk membaca ucapan terimakasih dan halaman akan dikembalikan ke halaman awal kiosk.</i></p>
            </div>
        )

        const infoPraWashing = (
            <div>
                <p className={`mb-5`}><b>Youtube Video Pra Washing</b></p>
                <p className={`mb-5`}>The video informs about how to wash gallon containers at the kiosk.</p>
                <p className={`mb-5`}><i>Video yang menginformasikan cara melakukan pencucian galon pada kiosk.</i></p>
            </div>
        )

        const infoPraFilling = (
            <div>
                <p className={`mb-5`}><b>Youtube Video Pra Filling</b></p>
                <p className={`mb-5`}>The video informs about the correct position of the bottle/gallon before starting the filling process.</p>
                <p className={`mb-5`}><i>Video yang menginformasikan posisi botol/galon yang benar sebelum memulai pengisian.</i></p>
            </div>
        )

        const adsVideo = (
            <div>
                <p className={`mb-5`}><b>Youtube Video Ads</b></p>
                <p className={`mb-5`}>The video displays an advertisement for a product, shown during the filling process.</p>
                <p className={`mb-5`}><i>Video yang menampilkan iklan suatu product, ditampilkan saat pengisian.</i></p>
            </div>
        )

        const adsImage = (
            <div>
                <p className={`mb-5`}><b>Image Ads</b></p>
                <p className={`mb-5`}>The image displays an advertisement for a product, shown during the filling process.</p>
                <p className={`mb-5`}><i>Image yang menampilkan iklan suatu product, ditampilkan saat pengisian.</i></p>
            </div>
        )

        return (
            <MainLayout titlePage={`Master App`} breadcrumbPath={`Setting`} breadcrumbMenu={`/ Master App`} defaultSelectedKeys={['m-master-app']} defaultOpenKeys={['sm-setting']}>
                <Tabs
                    activeKey={this.state.activeTabKey}
                    items={[
                        {
                            label: 'Change Kiosk Timeout',
                            key: '1',
                            children:
                                <>
                                    <Alert message="Warning" description="Adjusting the timeout value can change the waiting time on the vending machine." type="warning" showIcon closable style={{marginTop: 10, marginBottom: 30}}/>
                                    <>
                                        <div style={this.state.fetchProcessing ? {} : {display: 'none'}} className={`spinCenter`}>
                                            <Spin size="large"/>
                                        </div>
                                        <div style={!this.state.fetchProcessing ? {} : {display: 'none'}}>
                                            <Row>
                                                <Col style={{paddingLeft: 20, paddingRight: 20}} xl={{span: 8}} lg={{span: 8}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                                    <Form.Item>
                                                        <i className={`small-text-grey`}>Timeout QR Payment</i>
                                                        <Popover content={contentTimoutQrPayment} trigger="hover">
                                                            <QuestionCircleOutlined style={{cursor: "help", paddingLeft: 5}}/>
                                                        </Popover><br/>
                                                        <Input value={this.state.timeoutQrPayment} placeholder={`Timeout QR Payment`} onChange={event => this.onFieldChange("timeoutQrPayment", event.target.value)}/>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <i className={`small-text-grey`}>Timeout Successful Payment</i>
                                                        <Popover content={timeoutSuccessfulPayment} trigger="hover">
                                                            <QuestionCircleOutlined style={{cursor: "help", paddingLeft: 5}}/>
                                                        </Popover><br/>
                                                        <Input value={this.state.timeoutSuccessfulPayment} placeholder={`Timeout Successful Payment`} onChange={event => this.onFieldChange("timeoutSuccessfulPayment", event.target.value)}/>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <i className={`small-text-grey`}>Timeout Failed Payment</i>
                                                        <Popover content={timeoutFailedPayment} trigger="hover">
                                                            <QuestionCircleOutlined style={{cursor: "help", paddingLeft: 5}}/>
                                                        </Popover><br/>
                                                        <Input value={this.state.timeoutFailedPayment} placeholder={`Timeout Failed Payment`} onChange={event => this.onFieldChange("timeoutFailedPayment", event.target.value)}/>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <i className={`small-text-grey`}>Timeout Automatic Washing</i>
                                                        <Popover content={timeoutAutomaticWashing} trigger="hover">
                                                            <QuestionCircleOutlined style={{cursor: "help", paddingLeft: 5}}/>
                                                        </Popover><br/>
                                                        <Input value={this.state.timeoutAutomaticWashing} placeholder={`Timeout Automatic Washing`} onChange={event => this.onFieldChange("timeoutAutomaticWashing", event.target.value)}/>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <i className={`small-text-grey`}>Timeout Automatic Filling</i>
                                                        <Popover content={timeoutAutomaticFilling} trigger="hover">
                                                            <QuestionCircleOutlined style={{cursor: "help", paddingLeft: 5}}/>
                                                        </Popover><br/>
                                                        <Input value={this.state.timeoutAutomaticFilling} placeholder={`Timeout Automatic Filling`} onChange={event => this.onFieldChange("timeoutAutomaticFilling", event.target.value)}/>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <i className={`small-text-grey`}>Timeout Automatic Filling (from wrong position)</i>
                                                        <Popover content={timeoutAutomaticFillingWrongPosition} trigger="hover">
                                                            <QuestionCircleOutlined style={{cursor: "help", paddingLeft: 5}}/>
                                                        </Popover><br/>
                                                        <Input value={this.state.timeoutAutomaticFillingWrongPosition} placeholder={`Timeout Automatic Filling (from wrong position)`} onChange={event => this.onFieldChange("timeoutAutomaticFillingWrongPosition", event.target.value)}/>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <i className={`small-text-grey`}>Timeout Complete Filling</i>
                                                        <Popover content={timeoutCompleteFilling} trigger="hover">
                                                            <QuestionCircleOutlined style={{cursor: "help", paddingLeft: 5}}/>
                                                        </Popover><br/>
                                                        <Input value={this.state.timeoutCompleteFilling} placeholder={`Timeout Complete Filling`} onChange={event => this.onFieldChange("timeoutCompleteFilling", event.target.value)}/>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <i className={`small-text-grey`}>Timeout Rating</i>
                                                        <Popover content={timeoutRating} trigger="hover">
                                                            <QuestionCircleOutlined style={{cursor: "help", paddingLeft: 5}}/>
                                                        </Popover><br/>
                                                        <Input value={this.state.timeoutCompleteFeedback} placeholder={`Timeout Feedback`} onChange={event => this.onFieldChange("timeoutCompleteFeedback", event.target.value)}/>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <i className={`small-text-grey`}>Timeout Thank You Note</i>
                                                        <Popover content={timeoutThankYouNote} trigger="hover">
                                                            <QuestionCircleOutlined style={{cursor: "help", paddingLeft: 5}}/>
                                                        </Popover><br/>
                                                        <Input value={this.state.timeoutThankYouNote} placeholder={`Timeout Thank You Note`} onChange={event => this.onFieldChange("timeoutThankYouNote", event.target.value)}/>
                                                    </Form.Item>

                                                    <Button className={`mt-20`} type={`primary`} onClick={() => this.setState({showModalConfirmTimeout: true})}> <SaveOutlined/> Update Data</Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </>
                                </>
                        },
                        {
                            label: 'Change Kiosk Content',
                            key: '2',
                            children:
                                <>
                                    <Alert message="Warning" description="Adjusting the content value can change the content on the vending machine." type="warning" showIcon closable style={{marginTop: 10, marginBottom: 30}}/>
                                    <>
                                        <div style={this.state.fetchProcessing ? {} : {display: 'none'}} className={`spinCenter`}>
                                            <Spin size="large"/>
                                        </div>
                                        <div style={!this.state.fetchProcessing ? {} : {display: 'none'}}>
                                            <Row>
                                                <Col style={{paddingLeft: 20, paddingRight: 20}} xl={{span: 8}} lg={{span: 8}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                                    <Divider orientation={"left"}>Information Videos</Divider>
                                                    <Form.Item>
                                                        <i className={`small-text-grey`}>Youtube Video Pra Washing </i>
                                                        <Popover content={infoPraWashing} trigger="hover">
                                                            <QuestionCircleOutlined style={{cursor: "help", paddingLeft: 5}}/>
                                                        </Popover><br/>
                                                        <Input value={this.state.videoPraWashing} placeholder={`Youtube ID Pra Washing`} onChange={event => this.onFieldChange("videoPraWashing", event.target.value)}/>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <i className={`small-text-grey`}>Youtube Video Pra Filling </i>
                                                        <Popover content={infoPraFilling} trigger="hover">
                                                            <QuestionCircleOutlined style={{cursor: "help", paddingLeft: 5}}/>
                                                        </Popover><br/>
                                                        <Input value={this.state.videoPraFilling} placeholder={`Youtube ID Pra Filling`} onChange={event => this.onFieldChange("videoPraFilling", event.target.value)}/>
                                                    </Form.Item>

                                                    <Button className={`mt-20`} type={`primary`} onClick={() => this.setState({showModalConfirmContent: true})}> <SaveOutlined/> Update Data</Button>
                                                </Col>
                                                <Col style={{paddingLeft: 20, paddingRight: 20}} xl={{span: 8}} lg={{span: 8}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                                    <Divider orientation={"left"}>Ads Video and Ads Image</Divider>
                                                    <Form.Item>
                                                        <i className={`small-text-grey`}>Youtube Ads Filling </i>
                                                        <Popover content={adsVideo} trigger="hover">
                                                            <QuestionCircleOutlined style={{cursor: "help", paddingLeft: 5}}/>
                                                        </Popover><br/>
                                                        <Input value={this.state.videoAdsFilling} placeholder={`Youtube ID Ads Filling`} onChange={event => this.onFieldChange("videoAdsFilling", event.target.value)}/>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <i className={`small-text-grey`}>Url Image Ads Image </i>
                                                        <Popover content={adsImage} trigger="hover">
                                                            <QuestionCircleOutlined style={{cursor: "help", paddingLeft: 5}}/>
                                                        </Popover><br/>
                                                        <Input value={this.state.imageAdsFilling} placeholder={`Url Image Ads Image`} onChange={event => this.onFieldChange("imageAdsFilling", event.target.value)}/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </div>
                                    </>
                                </>
                        },
                        /*{
                            label: 'Change Kiosk Background',
                            key: '3',
                            children:
                                <>
                                    <div style={this.state.fetchProcessing ? {} : {display: 'none'}} className={`spinCenter`}>
                                        <Spin size="large"/>
                                    </div>
                                    <div style={!this.state.fetchProcessing ? {} : {display: 'none'}}>
                                        <Row>
                                            <Col style={{paddingLeft: 20, paddingRight: 20}} xl={{span: 8}} lg={{span: 8}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                                Kiosk Background: <br/>
                                                <Image crossOrigin={`anonymous`} width={200} src={this.state.appData.background_url}/>
                                            </Col>
                                            <Col style={{paddingLeft: 20, paddingRight: 20}} xl={{span: 1}} lg={{span: 1}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                                &nbsp;
                                            </Col>
                                            <Col style={{paddingLeft: 20, paddingRight: 20}} xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                                                <Button className={`m-10`} type={`primary`} onClick={() => this.setState({
                                                    showModalUploadApp: true,
                                                    freezeStatus: false,
                                                    base64BackgroundImage: ""
                                                })}> <CloudUploadOutlined/> Change Background Image</Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </>
                        }*/
                    ]}
                />

                <Modal
                    title="Lengkapi Data Background"
                    style={{top: 2}}
                    maskClosable={false}
                    open={this.state.showModalUploadApp}
                    onCancel={() => this.setState({showModalUploadApp: false})}
                    footer={[
                        <Button key={'saveCustomer'} type="primary" onClick={this.saveAppImage}> Simpan </Button>,
                        <Button key={'closeCustomer'} type="default" onClick={() => this.setState({showModalUploadApp: false})}> Close </Button>,
                    ]}
                >
                    <Form layout="vertical">
                        <Collapse collapsible="header" defaultActiveKey={['1']}>
                            <Panel header="Cara Melengkapi Data" key={(this.state.base64BackgroundImage) ? "0" : "1"}>
                                <ul style={{fontSize: "80%", marginLeft: 25}}>
                                    <li>Klik Tombol Upload Gambar</li>
                                    <li>Jika gambar telah sesuai, klik Tombol Crop Image</li>
                                    <li>Tekan Tombol Simpan</li>
                                </ul>
                            </Panel>
                        </Collapse><br/>
                        <Form.Item>
                            <CropBackgroundImage params={this.state} aspectRatio={9 / 16} onCropImage={this.onCropAppImage}/>
                        </Form.Item>
                    </Form>
                </Modal>

                <Modal
                    open={this.state.freezeStatus}
                    closeIcon={true}
                    footer={null}
                >
                    <div style={{textAlign: "center", padding: 20, minHeight: 100}}>
                        <Spin style={{marginTop: 20}} size={'large'} tip="Processing..."/>
                    </div>

                    <div style={{textAlign: "center", padding: 20}}>
                        Mohon menunggu beberapa saat, kami sedang memproses permintaan anda.
                    </div>
                </Modal>


                <Modal
                    title={"Update Data Confirm"}
                    open={this.state.showModalConfirmTimeout}
                    onCancel={() => {
                        this.setState({showModalConfirmTimeout: false})
                    }}
                    onOk={() => {
                        this.setState({showModalConfirmTimeout: false})
                    }}
                    footer={[
                        <Button loading={this.state.confirmLoading} key="update1" type={"primary"} style={{backgroundColor: "#64b48f", borderColor: "#64b48f"}} onClick={() => {
                            this.updateTimeout()
                        }}>Update Data</Button>,
                        <Button key="close" onClick={() => {
                            this.setState({showModalConfirmTimeout: false})
                        }}>Cancel</Button>
                    ]}
                >
                    <Divider/>
                    Are you sure you want to change this data? Changing data will result in altering the waiting time on the vending machine.
                    <Divider/>
                </Modal>

                <Modal
                    title={"Update Data Confirm"}
                    open={this.state.showModalConfirmContent}
                    onCancel={() => {
                        this.setState({showModalConfirmContent: false})
                    }}
                    onOk={() => {
                        this.setState({showModalConfirmContent: false})
                    }}
                    footer={[
                        <Button loading={this.state.confirmLoading} key="update2" type={"primary"} style={{backgroundColor: "#64b48f", borderColor: "#64b48f"}} onClick={() => {
                            this.updateContent()
                        }}>Update Data</Button>,
                        <Button key="close" onClick={() => {
                            this.setState({showModalConfirmContent: false})
                        }}>Cancel</Button>
                    ]}
                >
                    <Divider/>
                    Are you sure you want to change this data? Changing data can change the content on the vending machine.
                    <Divider/>
                </Modal>
            </MainLayout>
        )
    }
}

export default withAuth(MasterApp, 14)