import React, {Component} from 'react'
import Cookies from 'universal-cookie'
import {Buffer} from "buffer"
import withAuth from "../../hoc/withAuth"
import currencyFormat from "../../utils/currencyFormat"
import {EditOutlined, MoreOutlined} from '@ant-design/icons'
import MainLayout from "../../components/layout/MainLayout"
import {Dropdown, Image, message, Modal, Table} from "antd"
import {kioskApiService} from "../../services/wiloKiosk/KioskApiService"
import MasterProductForm from "../../components/form/MasterProductForm"
import MasterProductImageForm from "../../components/form/MasterProductImageForm";

class MasterProduct extends Component {
    constructor(props) {
        super(props)
        this.cookies = new Cookies()
        this.initState = {
            token: (this.cookies.get('_wk-t')) ? JSON.parse(Buffer.from(this.cookies.get('_wk-t'), 'base64').toString()) : '',
            fetchProcessing: true,
            tableData: [],
            productUpdate: {},
            showModalForm: false,
            showModalUpdateImage: false,
            modalTitle: "",
            modalType: "",
        }
        this.state = {...this.initState}
    }

    fetchData = async () => {
        try {
            await kioskApiService.setToken(this.state.token)
            const productData = await kioskApiService.readMasterProduct()
            this.setState({
                fetchProcessing: false,
                tableData: productData.data.data
            })
        } catch (e) {
            this.setState({
                processing: false,
                tableData: [],
            })
            message.info(e)
        }
    }

    formChildAction = (data) => {
        if (data.reloadData) {
            this.fetchData()
        }
        if (data.closeModal) {
            this.setState({showModalForm: false, showModalUpdateImage: false})
        }
    }

    async componentDidMount() {
        await this.fetchData()
    }

    render() {
        return (
            <MainLayout titlePage={`Master Product`} fullWidth={true} breadcrumbPath={`Setting`} breadcrumbMenu={`/ Master Product`} defaultSelectedKeys={['m-master-product']} defaultOpenKeys={['sm-setting']}>
                <Table
                    size="small"
                    rowKey={`code`}
                    scroll={{x: 'calc(500px + 50%)'}}
                    dataSource={this.state.tableData}
                    loading={this.state.fetchProcessing}
                    columns={[
                        {
                            title: 'Code',
                            dataIndex: 'code',
                            width: 320,
                            render: ((value, row) => {
                                return <>{value}<br/>Active: {(row.active) ? "true" : "false"}</>
                            })
                        }, {
                            title: 'Name',
                            dataIndex: 'name',
                            width: 320,
                            render: ((value, row) => {
                                return <>{value}<br/><span className={`small-text-grey`}>{row.description}</span></>
                            })
                        }, {
                            title: 'Price & Volume(L)',
                            dataIndex: 'price',
                            width: 320,
                            render: ((value, row) => {
                                return <>{currencyFormat(value)}<br/><span className={`small-text-grey`}>{row.volume} Liter</span></>
                            })
                        }, {
                            title: 'Image',
                            dataIndex: 'thumbnail_url',
                            width: 320,
                            render: ((value, row) => {
                                return <><Image crossOrigin={`anonymous`} alt={`logo`} style={{width: 60}} src={value}/></>
                            })
                        }, {
                            title: 'Status',
                            dataIndex: 'active',
                            width: 120,
                            render: ((value, row) => {
                                return <>{(value.toString() === "true") ? "Enable" : "Disable"}</>
                            })
                        }, {
                            title: 'Action',
                            dataIndex: 'id',
                            align: "center",
                            fixed: 'right',
                            width: 100,
                            render: ((value, row) => {
                                const items = [
                                    {key: '1', label: (<span onClick={() => this.setState({productUpdate: row, showModalForm: true, modalTitle: "Update Product", modalType: "UPDATE"})}> <EditOutlined/> Update Product</span>)},
                                    {key: '2', label: (<span onClick={() => this.setState({productUpdate: row, showModalUpdateImage: true, modalTitle: "Update Image"})}> <EditOutlined/> Update Image</span>)},
                                ]
                                return <>
                                    <span className={`font-90`}>
                                        <Dropdown menu={{items}} placement="bottomLeft" arrow>
                                            <MoreOutlined style={{fontWeight: "bolder", cursor: "pointer"}}/>
                                        </Dropdown>
                                    </span>
                                </>
                            })
                        }
                    ]}
                />

                <Modal
                    open={this.state.showModalForm}
                    title={this.state.modalTitle}
                    style={{top: 20}}
                    onCancel={() => this.setState({showModalForm: false})}
                    footer={[]}
                >
                    <MasterProductForm formChildAction={this.formChildAction} type={this.state.modalType} productData={this.state.productUpdate} token={this.state.token}/>
                </Modal>

                <Modal
                    open={this.state.showModalUpdateImage}
                    title={'Update Product Image'}
                    style={{top: 20}}
                    onCancel={() => this.setState({showModalUpdateImage: false})}
                    footer={[]}
                >
                    <MasterProductImageForm formChildAction={this.formChildAction} type={this.state.modalType} productData={this.state.productUpdate} token={this.state.token}/>
                </Modal>
            </MainLayout>
        )
    }
}

export default withAuth(MasterProduct, 11)