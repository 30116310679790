import React, {Component} from 'react'
import Cookies from 'universal-cookie'
import {Buffer} from "buffer"
import withAuth from "../../hoc/withAuth"
import {EditOutlined, MoreOutlined} from '@ant-design/icons'
import MainLayout from "../../components/layout/MainLayout"
import {Alert, Dropdown, message, Modal, Table, Tabs} from "antd"
import {kioskApiService} from "../../services/wiloKiosk/KioskApiService"
import MasterCalibrationForm from "../../components/form/MasterCalibrationForm";

class MasterCalibration extends Component {
    constructor(props) {
        super(props)
        this.cookies = new Cookies()
        this.initState = {
            token: (this.cookies.get('_wk-t')) ? JSON.parse(Buffer.from(this.cookies.get('_wk-t'), 'base64').toString()) : '',
            fetchProcessing: true,
            tableData: [],
            groupingTableData: [],
            kioskUpdate: {},
            showModalForm: false,
            modalTitle: "",
            modalType: "",
            updateType: "",
        }
        this.state = {...this.initState}
    }

    fetchData = async () => {
        try {
            await kioskApiService.setToken(this.state.token)
            const kioskData = await kioskApiService.readMasterCalibration()

            const groupingTableData = []
            for (const item of kioskData.data.data) {
                if (!groupingTableData.some(element => element.kiosk_id === item.kiosk_id)) {
                    groupingTableData.push(item)
                }
            }

            this.setState({
                fetchProcessing: false,
                tableData: kioskData.data.data,
                groupingTableData: groupingTableData
            })
        } catch (e) {
            this.setState({
                fetchProcessing: false,
                tableData: [],
            })
            message.info(e)
        }
    }

    formChildAction = (data) => {
        if (data.reloadData) {
            this.fetchData()
        }
        if (data.closeModal) {
            this.setState({showModalForm: false})
        }
    }

    async componentDidMount() {
        await this.fetchData()
    }

    render() {
        return (
            <MainLayout titlePage={`Master Calibration`} breadcrumbPath={`Setting`} breadcrumbMenu={`/ Master Calibration`} defaultSelectedKeys={['m-master-calibration']} defaultOpenKeys={['sm-setting']}>
                <Tabs
                    activeKey={this.state.activeTabKey}
                    items={[
                        {
                            label: 'WaterFlow Product Calibration',
                            key: '1',
                            children:
                                <>
                                    <Alert message="Warning" description="Adjusting the calibration value can change the volume of water that comes out from vending machine." type="warning" showIcon closable style={{marginTop: 10, marginBottom: 10}}/>

                                    <Table
                                        size="small"
                                        rowKey={`id`}
                                        scroll={{x: 'calc(500px + 50%)'}}
                                        dataSource={this.state.tableData}
                                        loading={this.state.fetchProcessing}
                                        columns={[
                                            {
                                                title: 'Kiosk',
                                                dataIndex: 'kiosk_code',
                                                render: ((value, row) => {
                                                    return <>{value}<br/><span className={`small-text-grey`}>{row.kiosk_name}</span></>
                                                })
                                            }, {
                                                title: 'Product',
                                                dataIndex: 'product_code',
                                                render: ((value, row) => {
                                                    return <>{value}<br/><span className={`small-text-grey`}>{row.product_name}</span></>
                                                })
                                            },
                                            {
                                                title: 'FlowMeter Calibration (Flow pulse (6.6 * Q) Q = L / Min 3%)',
                                                dataIndex: 'flowmeter_calibration',
                                                render: ((value, row) => {
                                                    return <>{value}</>
                                                })
                                            }, {
                                                title: 'Action',
                                                dataIndex: 'id',
                                                align: "center",
                                                fixed: 'right',
                                                width: 100,
                                                render: ((value, row) => {
                                                    const items = [
                                                        {key: '1', label: (<span onClick={() => this.setState({kioskUpdate: row, updateType: "UPDATE_FLOWMETER_CALIBRATION", showModalForm: true, modalTitle: "Update Water Flow Product Calibration", modalType: "UPDATE"})}> <EditOutlined/> Update Water Flow Product Calibration</span>)},
                                                    ]
                                                    return <>
                                                        <span className={`font-90`}>
                                                            <Dropdown menu={{items}} placement="bottomLeft" arrow>
                                                                <MoreOutlined style={{fontWeight: "bolder", cursor: "pointer"}}/>
                                                            </Dropdown>
                                                        </span>
                                                    </>
                                                })
                                            }
                                        ]}
                                    />
                                </>
                        },
                        {
                            label: 'Kiosk Calibration',
                            key: '2',
                            children:
                                <>
                                    <Alert message="Warning" description="Adjusting the calibration value can change the system from vending machine." type="warning" showIcon closable style={{marginTop: 10, marginBottom: 10}}/>

                                    <Table
                                        size="small"
                                        rowKey={`id`}
                                        scroll={{x: 'calc(500px + 50%)'}}
                                        dataSource={this.state.groupingTableData}
                                        loading={this.state.fetchProcessing}
                                        columns={[
                                            {
                                                title: 'Kiosk',
                                                dataIndex: 'kiosk_code',
                                                render: ((value, row) => {
                                                    return <>{value}<br/><span className={`small-text-grey`}>{row.kiosk_name}</span></>
                                                })
                                            }, {
                                                title: 'PT Inlet (Bar)',
                                                dataIndex: 'pressure_1_calibration',
                                                render: ((value, row) => {
                                                    return <>{value}</>
                                                })
                                            }, {
                                                title: 'PT Feed (Bar)',
                                                dataIndex: 'pressure_2_calibration',
                                                render: ((value, row) => {
                                                    return <>{value}</>
                                                })
                                            }, {
                                                title: 'PT HPP (Bar)',
                                                dataIndex: 'pressure_3_calibration',
                                                render: ((value, row) => {
                                                    return <>{value}</>
                                                })
                                            }, {
                                                title: 'TDS (uS/cm)',
                                                dataIndex: 'tds_calibration',
                                                render: ((value, row) => {
                                                    return <>{value}</>
                                                })
                                            }, {
                                                title: 'PH (pH)',
                                                dataIndex: 'ph_calibration',
                                                render: ((value, row) => {
                                                    return <>{value}</>
                                                })
                                            }, {
                                                title: 'Action',
                                                dataIndex: 'id',
                                                align: "center",
                                                fixed: 'right',
                                                width: 100,
                                                render: ((value, row) => {
                                                    const items = [
                                                        {key: '2', label: (<span onClick={() => this.setState({kioskUpdate: row, updateType: "UPDATE_CALIBRATION", showModalForm: true, modalTitle: "Update Calibration", modalType: "UPDATE"})}> <EditOutlined/> Update Kiosk Calibration</span>)},
                                                    ]
                                                    return <>
                                                        <span className={`font-90`}>
                                                            <Dropdown menu={{items}} placement="bottomLeft" arrow>
                                                                <MoreOutlined style={{fontWeight: "bolder", cursor: "pointer"}}/>
                                                            </Dropdown>
                                                        </span>
                                                    </>
                                                })
                                            }
                                        ]}
                                    />
                                </>
                        },
                        {
                            label: 'Kiosk SetValue',
                            key: '3',
                            children:
                                <>
                                    <Alert message="Warning" description="Adjusting the setvalue value can change the system from vending machine." type="warning" showIcon closable style={{marginTop: 10, marginBottom: 10}}/>

                                    <Table
                                        size="small"
                                        rowKey={`id`}
                                        scroll={{x: 'calc(500px + 50%)'}}
                                        dataSource={this.state.groupingTableData}
                                        loading={this.state.fetchProcessing}
                                        columns={[
                                            {
                                                title: 'Kiosk',
                                                dataIndex: 'kiosk_code',
                                                render: ((value, row) => {
                                                    return <>{value}<br/><span className={`small-text-grey`}>{row.kiosk_name}</span></>
                                                })
                                            }, {
                                                title: 'PT Inlet (Bar)',
                                                dataIndex: 'pressure_1_setvalue',
                                                render: ((value, row) => {
                                                    return <>{value}</>
                                                })
                                            }, {
                                                title: 'PT Feed (Bar)',
                                                dataIndex: 'pressure_2_setvalue',
                                                render: ((value, row) => {
                                                    return <>{value}</>
                                                })
                                            }, {
                                                title: 'PT HPP (Bar)',
                                                dataIndex: 'pressure_3_setvalue',
                                                render: ((value, row) => {
                                                    return <>{value}</>
                                                })
                                            },{
                                                title: 'PT HPP Warning (Bar)',
                                                dataIndex: 'pressure_3_warning_setvalue',
                                                render: ((value, row) => {
                                                    return <>{value}</>
                                                })
                                            }, {
                                                title: 'TDS (uS/cm)',
                                                dataIndex: 'tds_setvalue',
                                                render: ((value, row) => {
                                                    return <>{value}</>
                                                })
                                            },
                                            // {
                                            //     title: 'PH',
                                            //     dataIndex: 'ph_setvalue',
                                            //     render: ((value, row) => {
                                            //         return <>{value}</>
                                            //     })
                                            // },
                                            {
                                                title: 'PH Min (pH)',
                                                dataIndex: 'ph_min_setvalue',
                                                render: ((value, row) => {
                                                    return <>{value}</>
                                                })
                                            },
                                            {
                                                title: 'PH Max (pH)',
                                                dataIndex: 'ph_max_setvalue',
                                                render: ((value, row) => {
                                                    return <>{value}</>
                                                })
                                            },{
                                                title: 'Wash (time in second)',
                                                dataIndex: 'wash_setvalue',
                                                render: ((value, row) => {
                                                    return <>{value}</>
                                                })
                                            }, {
                                                title: 'Action',
                                                dataIndex: 'id',
                                                align: "center",
                                                fixed: 'right',
                                                width: 100,
                                                render: ((value, row) => {
                                                    const items = [
                                                        {key: '2', label: (<span onClick={() => this.setState({kioskUpdate: row, updateType: "UPDATE_SETVALUE", showModalForm: true, modalTitle: "Update Setvalue", modalType: "UPDATE"})}> <EditOutlined/> Update Kiosk Setvalue</span>)},
                                                    ]
                                                    return <>
                                                        <span className={`font-90`}>
                                                            <Dropdown menu={{items}} placement="bottomLeft" arrow>
                                                                <MoreOutlined style={{fontWeight: "bolder", cursor: "pointer"}}/>
                                                            </Dropdown>
                                                        </span>
                                                    </>
                                                })
                                            }
                                        ]}
                                    />
                                </>
                        }
                    ]}
                />

                <Modal open={this.state.showModalForm} title={this.state.modalTitle} style={{top: 20}} onCancel={() => this.setState({showModalForm: false})} footer={[]}>
                    <MasterCalibrationForm formChildAction={this.formChildAction} updateType={this.state.updateType} type={this.state.modalType} kioskData={this.state.kioskUpdate} token={this.state.token}/>
                </Modal>
            </MainLayout>
        )
    }
}

export default withAuth(MasterCalibration, 16)