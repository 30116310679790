import React, {Component} from 'react'
import {Button, Divider, Form, Input, Modal, Select} from "antd"
import {kioskApiService} from "../../services/wiloKiosk/KioskApiService"
import CurrencyInput from "react-currency-input-field"

class MasterProductForm extends Component {
    constructor(props) {
        super(props)
        this.initState = {
            processing: false,
            type: "",
            code: "",
            name: "",
            description: "",
            price: "",
            volume: 0,
            washingProcessStatus: "ACTIVE",
            activeStatus: "ACTIVE",
        }
        this.state = {...this.initState}
    }

    onFieldChange = (target, value) => {
        const currentState = {...this.state}
        switch (target) {
            case "name":
                currentState.name = value
                break
            case "description":
                currentState.description = value
                break
            case "price":
                currentState.price = value
                break
            case "volume":
                currentState.volume = value.replace(/[^0-9.]/g, '')
                break
            case "washingProcessStatus":
                currentState.washingProcessStatus = value
                break
            case "activeStatus":
                currentState.activeStatus = value
                break
            default:
                break
        }
        this.setState({
            ...currentState,
        })
    }

    componentDidMount() {
        this.fillComponent()
    }

    componentDidUpdate(prevProps) {
        if (this.props.type !== prevProps.type || (this.props.productData && this.props.productData.code !== prevProps.productData?.code)) {
            this.fillComponent()
        }
    }

    fillComponent = () => {
        this.setState({
            processing: false,
            type: this.props.type,
            code: this.props.productData.code,
            name: this.props.productData.name,
            description: this.props.productData.description,
            price: this.props.productData.price,
            volume: parseFloat(this.props.productData.volume),
            washingProcessStatus: (!this.props.productData.washing_process) ? "UNACTIVE" : "ACTIVE",
            activeStatus: (!this.props.productData.active) ? "UNACTIVE" : "ACTIVE",
        })
    }

    closeAction = () => {
        this.props.formChildAction({closeModal: true, reloadData: false})
    }

    saveAction = async () => {
        const {type, name, description, price, volume, washingProcessStatus, activeStatus} = this.state
        await kioskApiService.setToken(this.props.token)
        if (type === "CREATE") {
            alert("error cant create product")
        } else if (type === "UPDATE") {
            if(!name){
                return Modal.error({title: 'Update product Failed', content: "name required"})
            }
            if(!price){
                return Modal.error({title: 'Update product Failed', content: "price required"})
            }
            if(!volume){
                return Modal.error({title: 'Update product Failed', content: "volume required"})
            }

            try {
                await kioskApiService.updateMasterProduct({
                    id: this.props.productData.id,
                    name: name.trim(),
                    description: description.trim(),
                    price: price,
                    volume: volume,
                    washing_process: (washingProcessStatus === "ACTIVE"),
                    active: (activeStatus === "ACTIVE")
                })
                Modal.success({title: 'Update Success', content: 'Update product Success'})
            } catch (e) {
                this.setState({processing: false})
                return Modal.error({title: 'Update product Failed', content: e})
            }
        } else {
            return Modal.error({title: 'App Error', content: "Error, Please reload this page"})
        }

        this.props.formChildAction({closeModal: true, reloadData: true})
    }

    render() {
        return (
            <>
                <Divider/>
                <Form.Item>
                    <i className={`small-text-grey`}>Code</i><br/>
                    <Input disabled={(this.props.type === "UPDATE")} value={this.state.code} placeholder={`Product Code`}/>
                </Form.Item>
                <Form.Item>
                    <i className={`small-text-grey`}>Name</i><br/>
                    <Input value={this.state.name} placeholder={`Product Name`} onChange={event => this.onFieldChange("name", event.target.value)}/>
                </Form.Item>
                <Form.Item>
                    <i className={`small-text-grey`}>Description</i><br/>
                    <Input value={this.state.description} placeholder={`Product Description`} onChange={event => this.onFieldChange("description", event.target.value)}/>
                </Form.Item>
                <Form.Item>
                    <i className={`small-text-grey`}>Price</i><br/>
                    <CurrencyInput
                        className={'ant-input-css'}
                        allowDecimals={true}
                        decimalsLimit={2}
                        groupSeparator={","}
                        decimalSeparator={"."}
                        prefix={"Rp "}
                        value={this.state.price}
                        onValueChange={(value) => this.onFieldChange("price", value ? parseFloat(value) : 0)}
                    />
                </Form.Item>
                <Form.Item>
                    <i className={`small-text-grey`}>Volume (Liter)</i><br/>
                    <Input value={this.state.volume} placeholder={`Calibration Value`} onChange={event => this.onFieldChange("volume", event.target.value)}/>
                </Form.Item>
                <Form.Item>
                    <i className={`small-text-grey`}>Washing Process</i><br/>
                    <Select
                        onChange={value => this.onFieldChange("washingProcessStatus", value)}
                        value={this.state.washingProcessStatus}
                        style={{width: "100%"}}
                        placeholder={`Washing Process`}
                    >
                        <Select.Option key={`ACTIVE`} value={`ACTIVE`}>{`Enable`}</Select.Option>
                        <Select.Option key={`UNACTIVE`} value={`UNACTIVE`}>{`Disable`}</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item>
                    <i className={`small-text-grey`}>Active Status</i><br/>
                    <Select
                        onChange={value => this.onFieldChange("activeStatus", value)}
                        value={this.state.activeStatus}
                        style={{width: "100%"}}
                        placeholder={`User Active`}
                    >
                        <Select.Option key={`ACTIVE`} value={`ACTIVE`}>{`Enable`}</Select.Option>
                        <Select.Option key={`UNACTIVE`} value={`UNACTIVE`}>{`Disable`}</Select.Option>
                    </Select>
                </Form.Item>
                <Divider/>
                <div style={{textAlign: "right"}}>
                    <Button className={`m-5`} onClick={this.closeAction}> Cancel</Button>
                    <Button className={`m-5`} onClick={this.saveAction} loading={this.state.processing} type={'primary'}> Save</Button>
                </div>
            </>
        )
    }
}

export default MasterProductForm