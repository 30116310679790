import React from "react"
import {BrowserRouter as Router, Route, Routes} from "react-router-dom"
import {ConfigProvider} from 'antd'

import Login from "./pages/auth/Login"
import MyAccount from "./pages/auth/MyAccount"
import Dashboard from "./pages/dashboard/Dashboard"
import MonitoringKiosk from "./pages/liveMonitoring/MonitoringKiosk"
import ReportSales from "./pages/report/ReportSales"
import ReportPerformance from "./pages/report/ReportPerformance"
import ReportChart from "./pages/report/ReportChart"
import ReportIotChart from "./pages/report/ReportIotChart"
import ReportCustomerRating from "./pages/report/ReportCustomerRating"
import ReportWaterFlow from "./pages/report/ReportWaterFlow"
import ReportWaterWaste from "./pages/report/ReportWaterWaste"
import LogKiosk from "./pages/logActivity/LogKiosk"
import LogUser from "./pages/logActivity/LogUser"
import LogIot from "./pages/logActivity/LogIot"
import LogPaymentgateway from "./pages/logActivity/LogPaymentgateway"
import MasterLocation from "./pages/setting/MasterLocation"
import MasterKiosk from "./pages/setting/MasterKiosk"
import MasterCalibration from "./pages/setting/MasterCalibration"
import MasterProduct from "./pages/setting/MasterProduct"
import MasterAd from "./pages/setting/MasterAd"
import MasterUser from "./pages/setting/MasterUser"
import MasterApp from "./pages/setting/MasterApp"
import Refund from "./pages/refund/Refund"

function App() {
    return (
        <ConfigProvider theme={{token: {colorPrimary: '#009c82'}}}>
            <Router>
                <Routes>
                    <Route path="/login" caseSensitive={false} element={<Login/>}/>
                    <Route path="/my-account" caseSensitive={false} element={<MyAccount/>}/>
                    <Route path="/dashboard" caseSensitive={false} element={<Dashboard/>}/>
                    <Route path="/monitoring/kiosk" caseSensitive={false} element={<MonitoringKiosk/>}/>
                    <Route path="/report/sales" caseSensitive={false} element={<ReportSales/>}/>
                    <Route path="/report/performance" caseSensitive={false} element={<ReportPerformance/>}/>
                    <Route path="/report/chart" caseSensitive={false} element={<ReportChart/>}/>
                    <Route path="/report/iot-chart" caseSensitive={false} element={<ReportIotChart/>}/>
                    <Route path="/report/customer-rating" caseSensitive={false} element={<ReportCustomerRating/>}/>
                    <Route path="/report/water-flow" caseSensitive={false} element={<ReportWaterFlow/>}/>
                    <Route path="/report/water-waste" caseSensitive={false} element={<ReportWaterWaste/>}/>
                    <Route path="/log/kiosk" caseSensitive={false} element={<LogKiosk/>}/>
                    <Route path="/log/user" caseSensitive={false} element={<LogUser/>}/>
                    <Route path="/log/iot" caseSensitive={false} element={<LogIot/>}/>
                    <Route path="/log/payment-gateway" caseSensitive={false} element={<LogPaymentgateway/>}/>
                    <Route path="/master/location" caseSensitive={false} element={<MasterLocation/>}/>
                    <Route path="/master/kiosk" caseSensitive={false} element={<MasterKiosk/>}/>
                    <Route path="/master/calibration" caseSensitive={false} element={<MasterCalibration/>}/>
                    <Route path="/master/product" caseSensitive={false} element={<MasterProduct/>}/>
                    <Route path="/master/ad" caseSensitive={false} element={<MasterAd/>}/>
                    <Route path="/master/user" caseSensitive={false} element={<MasterUser/>}/>
                    <Route path="/master/app" caseSensitive={false} element={<MasterApp/>}/>
                    <Route path="/refund/refund" caseSensitive={false} element={<Refund/>}/>
                    <Route path="/" caseSensitive={false} element={<Dashboard/>}/>
                </Routes>
            </Router>
        </ConfigProvider>
    )
}

export default App