import React, {Component} from 'react'
import {Button, Divider, Form, Input, Modal, Tree} from "antd"
import {kioskApiService} from "../../services/wiloKiosk/KioskApiService"

class MasterUserMenu extends Component {
    constructor(props) {
        super(props)
        this.initState = {
            processing: false,
            type: "",
            name: "",
            email: "",
            expandedKeys: [],
            checkedKeys: [],
            selectedKeys: [],
            autoExpandParent: true,
        }
        this.state = {...this.initState}
    }

    componentDidMount() {
        this.fillComponent()
    }

    componentDidUpdate(prevProps) {
        if (this.props.type !== prevProps.type || (this.props.userData && this.props.userData.email !== prevProps.userData?.email)) {
            this.fillComponent()
        }
    }

    fillComponent = () => {
        this.setState({
            processing: false,
            type: this.props.type,
            name: this.props.userData.name ?? "",
            email: this.props.userData.email ?? "",
            checkedKeys: this.props.userData.admin_menu?.map(String) ?? []
        })
    }

    closeAction = () => {
        this.props.formChildAction({closeModal: true, reloadData: false})
    }

    saveAction = async () => {
        const {checkedKeys} = this.state
        const filteredArr = checkedKeys.filter(item => !item.startsWith("m-"))
        await kioskApiService.setToken(this.props.token)
        try {
            await kioskApiService.updateMasterUser({
                id: this.props.userData.id,
                active: this.props.userData.active,
                admin_menu: filteredArr
            })
            Modal.success({title: 'Update Success', content: 'Update user Success'})
        } catch (e) {
            this.setState({processing: false})
            return Modal.error({title: 'Update Failed', content: e})
        }

        this.props.formChildAction({closeModal: true, reloadData: true})
    }

    onExpand = (data) => {
        this.setState({
            expandedKeys: data,
            autoExpandParent: false
        })
    }

    onCheck = (data) => {
        this.setState({
            checkedKeys: data
        })
    }

    onSelect = (data) => {
        this.setState({
            selectedKeys: data
        })
    }

    render() {
        const treeData = [
            {
                title: 'Dashboard',
                key: '1',
            },
            {
                title: 'Live Monitoring',
                key: 'm-2',
                children: [
                    {
                        title: 'Kiosk Monitoring',
                        key: '2',
                    }
                ],
            },
            {
                title: 'Refund',
                key: 'm-18',
                children: [
                    {
                        title: 'Refund',
                        key: '18',
                    }
                ],
            },
            {
                title: 'Report',
                key: 'm-34',
                children: [
                    {
                        title: 'Sales Report',
                        key: '3',
                    }, {
                        title: 'Performance Report',
                        key: '4',
                    }, {
                        title: 'Sales Chart Report',
                        key: '15',
                    },{
                        title: 'IoT Chart Report',
                        key: '21',
                    }, {
                        title: 'Rating Report',
                        key: '17',
                    }
                ],
            },
            {
                title: 'Log Activity',
                key: 'm-5678',
                children: [
                    {
                        title: 'Log Kiosk',
                        key: '5',
                    }, {
                        title: 'Log IoT',
                        key: '6',
                    }, {
                        title: 'Log User',
                        key: '7',
                    }, {
                        title: 'Log Payment Gateway',
                        key: '8',
                    }
                ],
            },
            {
                title: 'Setting',
                key: 'm-910111213',
                children: [
                    {
                        title: 'Master Location',
                        key: '9',
                    }, {
                        title: 'Master Kiosk',
                        key: '10',
                    }, {
                        title: 'Master Product',
                        key: '11',
                    }, {
                        title: 'Master Calibration',
                        key: '16',
                    },
                    // {
                    //     title: 'Master Ads',
                    //     key: '12',
                    // },
                    {
                        title: 'Master App',
                        key: '14',
                    }, {
                        title: 'Master User',
                        key: '13',
                    },
                ],
            }
        ]
        return (
            <>
                <Divider/>
                <Form.Item>
                    <i className={`small-text-grey`}>Email</i><br/>
                    <Input disabled={true} type={'email'} value={this.state.email} placeholder={`User Email`}/>
                </Form.Item>
                <Form.Item>
                    <i className={`small-text-grey`}>Name</i><br/>
                    <Input disabled={true} value={this.state.name} placeholder={`User Name`}/>
                </Form.Item>
                <Form.Item>
                    <i className={`small-text-grey`}>Menu</i><br/>
                    <Tree
                        checkable
                        onExpand={this.onExpand}
                        expandedKeys={this.state.expandedKeys}
                        autoExpandParent={this.state.autoExpandParent}
                        checkedKeys={this.state.checkedKeys}
                        selectedKeys={this.state.selectedKeys}
                        onCheck={this.onCheck}
                        onSelect={this.onSelect}
                        treeData={treeData}
                    />
                </Form.Item>
                <Divider/>
                <div style={{textAlign: "right"}}>
                    <Button className={`m-5`} onClick={this.closeAction}> Cancel</Button>
                    <Button className={`m-5`} onClick={this.saveAction} loading={this.state.processing} type={'primary'}> Save</Button>
                </div>
            </>
        )
    }
}

export default MasterUserMenu