import React, {Component} from 'react'
import Cookies from 'universal-cookie'
import {Buffer} from "buffer"
import withAuth from "../../hoc/withAuth"
import MainLayout from "../../components/layout/MainLayout"
import {Divider, Row, Form, Col, Input, Button, Modal, Avatar} from "antd"
import {kioskApiService} from "../../services/wiloKiosk/KioskApiService"

class MyAccount extends Component {
    constructor(props) {
        super(props)
        this.cookies = new Cookies()
        this.initState = {
            token: JSON.parse(Buffer.from(this.cookies.get('_wk-t'), "base64").toString()) ?? "",
            processing: false,
            userData: {},
            currentPassword: "",
            newPassword: "",
            reTypeNewPassword: ""
        }
        this.state = {...this.initState}
    }

    onFieldChange = (target, value) => {
        const currentState = {...this.state}
        switch (target) {
            case "currentPassword":
                currentState.currentPassword = value
                break
            case "newPassword":
                currentState.newPassword = value
                break
            case "reTypeNewPassword":
                currentState.reTypeNewPassword = value
                break
            default:
                break
        }
        this.setState({
            ...currentState,
        })
    }

    changePassword = async () => {
        const currentPassword = this.state.currentPassword
        const newPassword = this.state.newPassword
        const reTypeNewPassword = this.state.reTypeNewPassword

        if (!currentPassword || !newPassword || !reTypeNewPassword) {
            Modal.error({title: 'Update Failed', content: 'please complete all fields'})
            return
        }
        if (newPassword.trim() !== reTypeNewPassword.trim()) {
            Modal.error({title: 'Update Failed', content: 'The new password and the retyped new password do not match'})
            return
        }
        if (newPassword.length < 8) {
            Modal.error({title: 'Update Failed', content: 'Minimum password length is 8 characters'})
            return
        }

        await this.promisedSetState({
            processing: true,
        })

        try {
            await kioskApiService.setToken(this.state.token)
            await kioskApiService.updateMyPassword({
                current_password: this.state.currentPassword,
                new_password: this.state.newPassword
            })
            Modal.success({title: 'Change Password Success', content: 'your password has change'})
            this.setState({
                processing: false,
                currentPassword: "",
                newPassword: "",
                reTypeNewPassword: "",
            })
        } catch (e) {
            Modal.error({title: 'Change Password Failed', content: e})
            this.setState({processing: false})
        }
    }

    promisedSetState = (newState) => new Promise((resolve) => this.setState(newState, resolve))

    async componentDidMount() {
        try {
            await kioskApiService.setToken(this.state.token)
            const myProfile = await kioskApiService.getMyProfile()
            this.setState({
                userData: myProfile.data.data
            })
        } catch (e) {
            Modal.error({title: 'Error', content: e})
        }
    }

    render() {
        return (
            <MainLayout hideMainMenu={true} titlePage={``} breadcrumbPath={``} breadcrumbMenu={`my-account`} defaultSelectedKeys={['m-dashboard']}>
                <Row>
                    <Col style={{paddingLeft: 20, paddingRight: 20}} xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <Divider orientation={"left"}>My Account</Divider>
                        <Row>
                            <Col xs={4} xl={2} style={{paddingTop: 20, textAlign: "center"}}>
                                <Avatar style={(!this.props.dataDetailUser?.selfie_image) ? {} : {display: 'none'}}>{this.state?.userData?.name?.charAt(0)?.toUpperCase()}</Avatar>

                                <a href={this.props.dataDetailUser?.selfie_image} style={(this.props.dataDetailUser?.selfie_image) ? {} : {display: 'none'}} target={"_blank"} rel="noreferrer">
                                    <Avatar src={this.props.dataDetailUser?.selfie_image} style={{cursor: "pointer"}}/>
                                </a>
                            </Col>
                            <Col xs={18} xl={18} style={{padding: 5}}>
                                <span className={`gray-80`}>Name: <span style={{paddingLeft: 39, color: "#bb1919", fontWeight: 500}}>{this.state?.userData?.name}</span></span><br/>
                                <span className={`gray-80`}>Email: <span style={{paddingLeft: 43, color: "#bb1919", fontWeight: 500}}>{this.state?.userData?.email}</span></span><br/>
                                <span className={`gray-80`}>Phone: <span style={{paddingLeft: 38, color: "#bb1919", fontWeight: 500}}>{this.state?.userData?.phone_number}</span></span>
                            </Col>
                        </Row>
                    </Col>
                    <Col style={{paddingLeft: 20, paddingRight: 20}} xl={{span: 12}} lg={{span: 12}} md={{span: 24}} sm={{span: 24}} xs={{span: 24}}>
                        <Divider orientation={"left"}>Change Password</Divider>
                        <Form.Item>
                            <i className={`small-text-grey`}>Current Password</i><br/>
                            <Input type={'password'} value={this.state.currentPassword} placeholder={`Current User Password`} onChange={event => this.onFieldChange("currentPassword", event.target.value)}/>
                        </Form.Item>
                        <Form.Item>
                            <i className={`small-text-grey`}>New Password</i><br/>
                            <Input type={'password'} value={this.state.newPassword} placeholder={`New Password`} onChange={event => this.onFieldChange("newPassword", event.target.value)}/>
                        </Form.Item>
                        <Form.Item>
                            <i className={`small-text-grey`}>Retype New Password</i><br/>
                            <Input type={'password'} value={this.state.reTypeNewPassword} placeholder={`Re Type New Password`} onChange={event => this.onFieldChange("reTypeNewPassword", event.target.value)}/>
                        </Form.Item>
                        <Divider/>
                        <div style={{margin: '25px 0'}}>
                            <Button onClick={this.changePassword} loading={this.state.processing} type={'primary'} htmlType={'submit'}> Change Password</Button>
                        </div>
                    </Col>
                </Row>
            </MainLayout>
        )
    }
}

export default withAuth(MyAccount, 1)