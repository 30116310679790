import React, {Component} from 'react'
import Cookies from 'universal-cookie'
import {Buffer} from "buffer"
import withAuth from "../../hoc/withAuth";
import {CheckCircleTwoTone, DesktopOutlined, HddOutlined} from '@ant-design/icons'
import MainLayout from "../../components/layout/MainLayout";
import {Table, Avatar, List, Tooltip, message} from "antd";
import {kioskApiService} from "../../services/wiloKiosk/KioskApiService"
import dayjs from "dayjs"
import socket from 'socket.io-client'
import {socketToken, baseUrlSocketKiosk} from "../../config/apiConfig"

class MonitoringKiosk extends Component {
    constructor(props) {
        super(props)
        this.cookies = new Cookies()
        this.initState = {
            token: (this.cookies.get('_wk-t')) ? JSON.parse(Buffer.from(this.cookies.get('_wk-t'), 'base64').toString()) : '',
            kiosksData: [],
            limitSensor: {},
            processing: true,
            socketConnected: false
        }
        this.state = {...this.initState}
    }

    fetchKiosks = async () => {
        try {
            await kioskApiService.setToken(this.state.token)
            const kiosks = await kioskApiService.readKioskState()
            this.setState({
                processing: false,
                kiosksData: kiosks.data.data
            })
        } catch (e) {
            this.setState({
                processing: false,
                kiosksData: [],
            })
            message.info(e)
        }
    }

    async componentDidMount() {
        if (!this.state.socketConnected) {
            await this.promisedSetState({socketConnected: true})
            this.socketClient = socket(baseUrlSocketKiosk, {query: {token: socketToken}})
        }
        this.socketClient.on('deviceDataUpdated', async (socketData) => {
            if (socketData.state.toUpperCase() === "IOT_HEARTBEAT" || socketData.state.toUpperCase() === "IOT_PUMP_REPORT") {
                await this.fetchKiosks();
            }
        })

        await kioskApiService.setToken(this.state.token)
        const kioskData = await kioskApiService.readMasterCalibration()
        const groupingTableData = []
        const limitSensor = {}
        for (const item of kioskData.data.data) {
            if (!groupingTableData.some(element => element.kiosk_id === item.kiosk_id)) {
                groupingTableData.push(item)
                limitSensor[item.kiosk_id] = {
                    pressure_1_setvalue: item?.pressure_1_setvalue,
                    pressure_2_setvalue: item?.pressure_2_setvalue,
                    pressure_3_setvalue: item?.pressure_3_setvalue,
                    tds_setvalue: item?.tds_setvalue,
                    ph_setvalue: item?.ph_setvalue,
                    ph_max_setvalue: item?.ph_max_setvalue,
                    ph_min_setvalue: item?.ph_min_setvalue
                }
            }
        }
        await this.promisedSetState({limitSensor: limitSensor})

        this.fetchInterval = setInterval(async () => {
            await this.fetchKiosks();
        }, 120000);

        await this.fetchKiosks()
    }

    async componentWillUnmount() {
        if (this.socketClient) {
            await this.socketClient.close()
        }
        clearInterval(this.fetchInterval);
    }

    promisedSetState = (newState) => new Promise((resolve) => this.setState(newState, resolve))

    render() {
        return (
            <MainLayout titlePage={`Monitoring Kiosk`} fullWidth={true} breadcrumbPath={`Live Monitoring`} breadcrumbMenu={`/ Monitoring Kiosk`} defaultSelectedKeys={['m-monitoring-kiosk']} defaultOpenKeys={['sm-monitoring']}>
                <Table
                    rowKey={`id`}
                    dataSource={this.state.kiosksData}
                    loading={this.state.processing}
                    scroll={{x: 'calc(500px + 50%)'}}
                    columns={[
                        {
                            title: 'Name',
                            dataIndex: 'name',
                            key: 'name',
                            render: ((value, row) => {
                                let kioskStatus = row.kiosk_status
                                let deviceStatus = row.device_status
                                let latestKioskHeartBeat = row.latest_kiosk_heartbeat?.replace("T", " ")?.substring(0, 19)
                                let latestDeviceHeartBeat = row.latest_device_heartbeat?.replace("T", " ")?.substring(0, 19)
                                const currentTime = dayjs()
                                const diffKioskInMinutes = currentTime.diff(dayjs(latestKioskHeartBeat), 'minute')
                                const diffDeviceInMinutes = currentTime.diff(dayjs(latestDeviceHeartBeat), 'minute')
                                if (diffKioskInMinutes > 15) {
                                    kioskStatus = "DISCONNECTED"
                                }
                                if (diffDeviceInMinutes > 15) {
                                    deviceStatus = "DISCONNECTED"
                                }

                                return <>
                                    <List style={{padding: 0, margin: 0}}>
                                        <List.Item style={{padding: 0, margin: 0}}>
                                            <List.Item.Meta
                                                style={{padding: 0, margin: 0}}
                                                avatar={<Avatar size="large" style={{marginTop: 8}} src={((deviceStatus === "NORMAL") && (kioskStatus === "READY" || kioskStatus === "NORMAL" || kioskStatus === "WORKING")) ? `/image/kiosk-active.png` : `/image/kiosk-inactive.png`}/>}
                                                title={
                                                    <>
                                                        <b className={`primary-color`}>{value}</b>
                                                        <Tooltip title={(row?.active) ? "Active" : "Not Active"}>
                                                            <CheckCircleTwoTone style={{paddingLeft: 5, fontSize: "80%"}} twoToneColor={(row?.active) ? "#52c41a" : "#bebebe"}/> <br/>
                                                        </Tooltip>
                                                    </>
                                                }
                                                description={row.location_name}
                                            />
                                        </List.Item>
                                    </List>
                                    <div style={{paddingLeft: 10, marginTop: 7}}>
                                        <Tooltip title={'Machine Last HeartBeat'}>
                                            <HddOutlined style={{color: "grey"}}/>
                                        </Tooltip>
                                        <span style={{paddingLeft: 7, color: "grey", fontSize: "90%"}}>{latestDeviceHeartBeat}</span>
                                    </div>
                                    <div style={{paddingLeft: 10, marginTop: 7}}>
                                        <Tooltip title={'Kiosk App Last HeartBeat'}>
                                            <DesktopOutlined style={{color: "grey"}}/>
                                        </Tooltip>
                                        <span style={{paddingLeft: 7, color: "grey", fontSize: "90%"}}>{latestKioskHeartBeat}</span>
                                    </div>
                                </>
                            })
                        }, {
                            title: 'Latest State',
                            dataIndex: 'id',
                            key: 'id',
                            render: ((value, row) => {
                                let kioskStatus = row.kiosk_status
                                let deviceStatus = row.device_status
                                let latestKioskHeartBeat = row.latest_kiosk_heartbeat?.replace("T", " ")?.substring(0, 19)
                                let latestDeviceHeartBeat = row.latest_device_heartbeat?.replace("T", " ")?.substring(0, 19)
                                const currentTime = dayjs()
                                const diffKioskInMinutes = currentTime.diff(dayjs(latestKioskHeartBeat), 'minute')
                                const diffDeviceInMinutes = currentTime.diff(dayjs(latestDeviceHeartBeat), 'minute')

                                if (diffKioskInMinutes > 15) {
                                    kioskStatus = "DISCONNECTED"
                                }
                                if (diffDeviceInMinutes > 15) {
                                    deviceStatus = "DISCONNECTED"
                                }

                                return <table>
                                    <tbody>
                                    <tr>
                                        <td style={{width: 100}}><span className={`small-text-grey`}>IoT Machine State</span></td>
                                        <td><span style={{paddingLeft: 7, color: "grey", fontSize: "80%"}}>{deviceStatus === "READY" || deviceStatus === "WORKING" || deviceStatus === "NORMAL" ? (<span>🟢 {deviceStatus}</span>) : (<span>🔴 {deviceStatus}</span>)}</span></td>
                                    </tr>
                                    <tr>
                                        <td style={{width: 100}}><span className={`small-text-grey`}>Kiosk State</span></td>
                                        <td><span style={{paddingLeft: 7, color: "grey", fontSize: "80%"}}>{kioskStatus === "READY" || kioskStatus === "WORKING" || kioskStatus === "NORMAL" ? (<span>🟢 {kioskStatus}</span>) : (<span>🔴 {kioskStatus}</span>)}</span></td>
                                    </tr>
                                    <tr>
                                        <td style={{width: 100}}><span className={`small-text-grey`}>Kiosk Detail State</span></td>
                                        <td><span style={{paddingLeft: 7, color: "grey", fontSize: "80%"}}>{row?.working_detail_status}</span></td>
                                    </tr>
                                    </tbody>
                                </table>
                            })
                        }, {
                            title: 'Analog Status',
                            dataIndex: 'working_detail_status',
                            key: 'working_detail_status',
                            render: ((value, row) => {
                                return <table>
                                    <tbody>
                                    <tr>
                                        <td style={{width: 100}}><span className={`small-text-grey`}>PT Inlet</span></td>
                                        <td style={{color: row.water_pressure_1 < this.state.limitSensor?.[row?.id]?.pressure_1_setvalue ? 'red' : 'black'}}>{parseFloat(row.water_pressure_1).toFixed(2)} bar</td>
                                    </tr>
                                    <tr>
                                        <td><span className={`small-text-grey`}>PT Feed</span></td>
                                        <td style={{color: row.water_pressure_2 < this.state.limitSensor?.[row?.id]?.pressure_2_setvalue ? 'red' : 'black'}}>{parseFloat(row.water_pressure_2).toFixed(2)} bar</td>
                                    </tr>
                                    <tr>
                                        <td><span className={`small-text-grey`}>PT HPP</span></td>
                                        <td style={{color: row.water_pressure_3 > this.state.limitSensor?.[row?.id]?.pressure_3_setvalue ? 'red' : 'black'}}>{parseFloat(row.water_pressure_3).toFixed(2)} bar</td>
                                    </tr>
                                    <tr>
                                        <td><span className={`small-text-grey`}>PH</span></td>
                                        <td style={{color: (row.water_ph < this.state.limitSensor?.[row?.id]?.ph_min_setvalue || row.water_ph > this.state.limitSensor?.[row?.id]?.ph_max_setvalue) ? 'red' : 'black'}}>{parseFloat(row.water_ph).toFixed(2)} pH</td>
                                    </tr>
                                    <tr>
                                        <td><span className={`small-text-grey`}>TDS</span></td>
                                        <td style={{color: row.water_tds > this.state.limitSensor?.[row?.id]?.tds_setvalue_setvalue ? 'red' : 'black'}}>{parseFloat(row.water_tds).toFixed(2)} uS/cm</td>
                                    </tr>
                                    </tbody>
                                </table>
                            })
                        }, {
                            title: 'Digital Status',
                            dataIndex: 'working_detail_status',
                            key: 'working_detail_status',
                            render: ((value, row) => {
                                return <table>
                                    <tbody>
                                    <tr>
                                        <td><span className={`small-text-grey`}>Feed Pump </span></td>
                                        <td style={{color: row?.feed_pump === "TRIP" ? 'red' : 'black'}}>{(row?.feed_pump)}</td>
                                    </tr>
                                    <tr>
                                        <td><span className={`small-text-grey`}>HP Pump </span></td>
                                        <td style={{color: row?.hp_pump === "TRIP" ? 'red' : 'black'}}>{(row?.hp_pump)}</td>
                                    </tr>
                                    <tr>
                                        <td><span className={`small-text-grey`}>Wash Pump </span></td>
                                        <td style={{color: row?.wash_pump === "TRIP" ? 'red' : 'black'}}>{(row?.wash_pump)}</td>
                                    </tr>
                                    <tr>
                                        <td><span className={`small-text-grey`}>Water Level</span></td>
                                        <td style={{color: row.water_level === 0 ? 'red' : 'black'}}>{(row.water_level) ? "FULL" : "LOW"}</td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    </tbody>
                                </table>
                            })
                        }, {
                            title: 'Reminder',
                            dataIndex: 'reminder_filter',
                            key: 'reminder_filter',
                            render: ((value, row) => {
                                const deviceStatusDetailWithBr = row?.device_status_detail?.replace(/\n/g, "<br/>");
                                let daysLeft = dayjs((value?.substring(0, 10))).diff(dayjs(), 'day')
                                daysLeft = (daysLeft < 1) ? "NEED REPLACE" : daysLeft + " days"

                                return <div>
                                    <span className={`small-text-grey`} style={{paddingRight: 10}}>Filter Replace</span>
                                    {value?.substring(0, 10)} <br/>
                                    <span className={`small-text-grey`} style={{paddingRight: 10}}>Replacement Within</span>
                                    {daysLeft}
                                    <div style={{marginTop: 7, fontSize: "85%", color: "red"}} dangerouslySetInnerHTML={{__html: deviceStatusDetailWithBr}}/>
                                </div>
                            })
                        }
                    ]}
                />
            </MainLayout>
        )
    }
}

export default withAuth(MonitoringKiosk, 2)