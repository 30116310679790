const baseUrlBackendKiosk = "https://api.wilowaterkiosk.com/v1"
const baseUrlSocketKiosk = "https://api.wilowaterkiosk.com"

// const baseUrlBackendKiosk = "http://54.169.38.136:8000/v1"
// const baseUrlSocketKiosk = "http://54.169.38.136:8000"

// const baseUrlBackendKiosk = "http://localhost:8000/v1"
// const baseUrlSocketKiosk = "http://localhost:8000"

const socketToken = "token-dev-888"
export {baseUrlBackendKiosk, baseUrlSocketKiosk, socketToken}