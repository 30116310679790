import React from "react"
import {Buffer} from "buffer"
import Cookies from 'universal-cookie'
import {kioskApiService} from "../services/wiloKiosk/KioskApiService"
import MainLayout from "../components/layout/MainLayout";
import {Spin} from "antd";

export default function withAuth(Component, pageCode) {
    const cookies = new Cookies()

    class InnerHoc extends Component {
        constructor(props) {
            super(props)

            this.state = {
                authenticated: false,
                authenticating: true,
            }
        }

        checkAuth = async () => {
            await this.setState({
                authenticating: true,
            })

            let currentState = {...this.state}
            const token = (cookies.get('_wk-t')) ? JSON.parse(Buffer.from(cookies.get('_wk-t'), 'base64').toString()) : ''
            if (!token) {
                currentState.authenticated = false
            } else {
                try {
                    await kioskApiService.setToken(token)
                    await kioskApiService.checkPage(pageCode);
                    currentState.authenticated = true;
                } catch (e) {
                    currentState.authenticated = false;
                }
            }
            currentState.authenticating = false
            await this.setState({...currentState})
        }

        componentDidMount = () => {
            this.checkAuth().then()
        }

        render = () => {
            if (this.state.authenticating) {
                if (window.location.pathname === "/my-account") {
                    return <div className={`loading-div`}><Spin style={{marginTop: 20}} size={'large'}/><br/>Loading...</div>
                } else {
                    return <MainLayout>
                        <div className={`loading-div`}><Spin style={{marginTop: 20}} size={'large'}/><br/>Loading...</div>
                    </MainLayout>
                }
            } else {
                if (this.state.authenticated) {
                    return (<Component {...this.props} />)
                } else {
                    cookies.remove('_wk-t')
                    cookies.remove('_wk-e')
                    cookies.remove('_wk-n')
                    cookies.remove('_wk-l')
                    cookies.remove('_wk-m')
                    setTimeout(() => {
                        window.location.href = "/login"
                    }, 200)
                }
            }
        }
    }

    return InnerHoc
}