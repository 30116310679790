import React, {Component} from 'react'
import {Button, Divider, Form, Input, Modal} from "antd"
import {kioskApiService} from "../../services/wiloKiosk/KioskApiService"
import CropBackgroundImage from "../general/CropBackgroundImage";

class MasterProductImageForm extends Component {
    constructor(props) {
        super(props)
        this.initState = {
            processing: false,
            base64Image: "",
        }
        this.state = {...this.initState}
    }

    componentDidMount() {
        this.fillComponent()
    }

    componentDidUpdate(prevProps) {
        if (this.props.type !== prevProps.type || (this.props.productData && this.props.productData.code !== prevProps.productData?.code)) {
            this.fillComponent()
        }
    }

    fillComponent = () => {
        this.setState({
            processing: false,
            base64Image: ""
        })
    }

    closeAction = () => {
        this.props.formChildAction({closeModal: true, reloadData: false})
    }

    saveAction = async () => {

        if (this.state.base64Image.length < 10) {
            return Modal.error({title: 'Error', content: 'Image Ads Required'})
        }

        try {
            await kioskApiService.updateMasterProduct({
                id: this.props.productData.id,
                content_image: this.state.base64Image
            })
            Modal.success({title: 'Update Success', content: 'Update product Success'})
        } catch (e) {
            this.setState({processing: false})
            return Modal.error({title: 'Update product Failed', content: e})
        }

        this.props.formChildAction({closeModal: true, reloadData: true})
    }

    onCropAdImage = async (base64DataImagePng) => {
        await this.setState({
            base64Image: base64DataImagePng,
        })
    }

    render() {
        return (
            <>
                <Divider/>
                <Form.Item>
                    <i className={`small-text-grey`}>Code</i><br/>
                    <Input disabled={true} value={this.props.productData.code} placeholder={`Product Code`}/>
                </Form.Item>
                <Form.Item>
                    <i className={`small-text-grey`}>Name</i><br/>
                    <Input disabled={true} value={this.props.productData.name} placeholder={`Product Name`}/>
                </Form.Item>
                <Form.Item>
                    <i className={`small-text-grey`}>Upload Image</i><br/>
                    <CropBackgroundImage params={this.state} aspectRatio={361 / 457} onCropImage={this.onCropAdImage}/>
                </Form.Item>
                <Divider/>
                <div style={{textAlign: "right"}}>
                    <Button className={`m-5`} onClick={this.closeAction}> Cancel</Button>
                    <Button className={`m-5`} onClick={this.saveAction} loading={this.state.processing} type={'primary'}> Save</Button>
                </div>
            </>
        )
    }
}

export default MasterProductImageForm