import React, {Component} from 'react'
import {Button, Divider, Form, Input, Modal} from "antd"
import {kioskApiService} from "../../services/wiloKiosk/KioskApiService"

class MasterCalibrationForm extends Component {
    constructor(props) {
        super(props)
        this.initState = {
            processing: false,
            type: "",
            flowmeter_calibration: 0,
            pressure_1_calibration: 0,
            pressure_2_calibration: 0,
            pressure_3_calibration: 0,
            tds_calibration: 0,
            ph_calibration: 0,
            pressure_1_setvalue: 0,
            pressure_2_setvalue: 0,
            pressure_3_setvalue: 0,
            pressure_3_warning_setvalue: 0,
            tds_setvalue: 0,
            ph_setvalue: 0,
            ph_min_setvalue: 0,
            ph_max_setvalue: 0,
            wash_setvalue: 0,
        }
        this.state = {...this.initState}
    }

    onFieldChange = (target, value) => {
        const currentState = {...this.state}
        switch (target) {
            case "flowmeter_calibration":
                currentState.flowmeter_calibration = value.replace(/[^0-9.-]/g, '')
                break

            case "pressure_1_calibration":
                currentState.pressure_1_calibration = value.replace(/[^0-9.-]/g, '')
                break
            case "pressure_2_calibration":
                currentState.pressure_2_calibration = value.replace(/[^0-9.-]/g, '')
                break
            case "pressure_3_calibration":
                currentState.pressure_3_calibration = value.replace(/[^0-9.-]/g, '')
                break
            case "tds_calibration":
                currentState.tds_calibration = value.replace(/[^0-9.-]/g, '')
                break
            case "ph_calibration":
                currentState.ph_calibration = value.replace(/[^0-9.-]/g, '')
                break
            case "pressure_1_setvalue":
                currentState.pressure_1_setvalue = value.replace(/[^0-9.-]/g, '')
                break
            case "pressure_2_setvalue":
                currentState.pressure_2_setvalue = value.replace(/[^0-9.-]/g, '')
                break
            case "pressure_3_setvalue":
                currentState.pressure_3_setvalue = value.replace(/[^0-9.-]/g, '')
                break
            case "pressure_3_warning_setvalue":
                currentState.pressure_3_warning_setvalue = value.replace(/[^0-9.-]/g, '')
                break
            case "tds_setvalue":
                currentState.tds_setvalue = value.replace(/[^0-9.-]/g, '')
                break
            case "ph_setvalue":
                currentState.ph_setvalue = value.replace(/[^0-9.-]/g, '')
                break
            case "ph_min_setvalue":
                currentState.ph_min_setvalue = value.replace(/[^0-9.-]/g, '')
                break
            case "ph_max_setvalue":
                currentState.ph_max_setvalue = value.replace(/[^0-9.-]/g, '')
                break
            case "wash_setvalue":
                currentState.wash_setvalue = value.replace(/[^0-9.-]/g, '')
                break
            default:
                break
        }
        this.setState({
            ...currentState,
        })
    }

    async componentDidMount() {
        this.fillComponent()
    }

    componentDidUpdate(prevProps) {
        if (this.props.type !== prevProps.type || this.props.updateType !== prevProps.updateType || (this.props.kioskData && this.props.kioskData.id !== prevProps.kioskData?.id)) {
            this.fillComponent()
        }
    }

    fillComponent = () => {
        this.setState({
            processing: false,
            type: this.props.type,
            flowmeter_calibration: this.props.kioskData.flowmeter_calibration,
            pressure_1_calibration: this.props.kioskData.pressure_1_calibration,
            pressure_2_calibration: this.props.kioskData.pressure_2_calibration,
            pressure_3_calibration: this.props.kioskData.pressure_3_calibration,
            tds_calibration: this.props.kioskData.tds_calibration,
            ph_calibration: this.props.kioskData.ph_calibration,
            pressure_1_setvalue: this.props.kioskData.pressure_1_setvalue,
            pressure_2_setvalue: this.props.kioskData.pressure_2_setvalue,
            pressure_3_setvalue: this.props.kioskData.pressure_3_setvalue,
            pressure_3_warning_setvalue: this.props.kioskData.pressure_3_warning_setvalue,
            tds_setvalue: this.props.kioskData.tds_setvalue,
            ph_setvalue: this.props.kioskData.ph_setvalue,
            ph_min_setvalue: this.props.kioskData.ph_min_setvalue,
            ph_max_setvalue: this.props.kioskData.ph_max_setvalue,
            wash_setvalue: this.props.kioskData.wash_setvalue,
        })
    }

    closeAction = () => {
        this.props.formChildAction({closeModal: true, reloadData: false})
    }

    saveAction = async () => {
        const {type} = this.state
        await kioskApiService.setToken(this.props.token)
        if (type === "CREATE") {
            alert("error cant create kiosk")
        } else if (type === "UPDATE") {
            let dataUpdate = {}
            if (this.props.updateType === "UPDATE_FLOWMETER_CALIBRATION") {
                dataUpdate = {
                    type: this.props.updateType,
                    id: this.props.kioskData.id,
                    flowmeter_calibration: this.state.flowmeter_calibration
                }
            }
            if (this.props.updateType === "UPDATE_CALIBRATION") {
                dataUpdate = {
                    type: this.props.updateType,
                    kiosk_id: this.props.kioskData.kiosk_id,
                    pressure_1_calibration: this.state.pressure_1_calibration,
                    pressure_2_calibration: this.state.pressure_2_calibration,
                    pressure_3_calibration: this.state.pressure_3_calibration,
                    tds_calibration: this.state.tds_calibration,
                    ph_calibration: this.state.ph_calibration,
                }
            }
            if (this.props.updateType === "UPDATE_SETVALUE") {
                if (this.state.pressure_3_warning_setvalue > this.state.pressure_3_setvalue) {
                    return Modal.error({title: 'Update failed', content: "PT HPP Warning should not be more than PT HPP"})
                }

                dataUpdate = {
                    type: this.props.updateType,
                    kiosk_id: this.props.kioskData.kiosk_id,
                    pressure_1_setvalue: this.state.pressure_1_setvalue,
                    pressure_2_setvalue: this.state.pressure_2_setvalue,
                    pressure_3_setvalue: this.state.pressure_3_setvalue,
                    pressure_3_warning_setvalue: this.state.pressure_3_warning_setvalue,
                    tds_setvalue: this.state.tds_setvalue,
                    ph_setvalue: this.state.ph_setvalue,
                    ph_min_setvalue: this.state.ph_min_setvalue,
                    ph_max_setvalue: this.state.ph_max_setvalue,
                    wash_setvalue: this.state.wash_setvalue,
                }
            }

            try {
                await kioskApiService.updateMasterCalibration(dataUpdate)
                Modal.success({title: 'Update Success', content: 'Update calibration Success'})
            } catch (e) {
                this.setState({processing: false})
                return Modal.error({title: 'Update kiosk calibration', content: e})
            }
        } else {
            return Modal.error({title: 'App Error', content: "Error, Please reload this page"})
        }

        this.props.formChildAction({closeModal: true, reloadData: true})
    }

    render() {
        return (
            <>
                <Divider/>

                <div style={(this.props.updateType === "UPDATE_FLOWMETER_CALIBRATION") ? {} : {display: 'none'}}>
                    <Form.Item>
                        <i className={`small-text-grey`}>Calibration Value</i><br/>
                        <Input value={this.state.flowmeter_calibration} placeholder={`Calibration Value`} onChange={event => this.onFieldChange("flowmeter_calibration", event.target.value)}/>
                    </Form.Item>
                </div>

                <div style={(this.props.updateType === "UPDATE_CALIBRATION") ? {} : {display: 'none'}}>
                    <Form.Item>
                        <i className={`small-text-grey`}>PT Inlet (Bar)</i><br/>
                        <Input value={this.state.pressure_1_calibration} placeholder={`Calibration Value`} onChange={event => this.onFieldChange("pressure_1_calibration", event.target.value)}/>
                    </Form.Item>
                    <Form.Item>
                        <i className={`small-text-grey`}>PT Feed (Bar)</i><br/>
                        <Input value={this.state.pressure_2_calibration} placeholder={`Calibration Value`} onChange={event => this.onFieldChange("pressure_2_calibration", event.target.value)}/>
                    </Form.Item>
                    <Form.Item>
                        <i className={`small-text-grey`}>PT HPP (Bar)</i><br/>
                        <Input value={this.state.pressure_3_calibration} placeholder={`Calibration Value`} onChange={event => this.onFieldChange("pressure_3_calibration", event.target.value)}/>
                    </Form.Item>
                    <Form.Item>
                        <i className={`small-text-grey`}>TDS (uS/cm)</i><br/>
                        <Input value={this.state.tds_calibration} placeholder={`Calibration Value`} onChange={event => this.onFieldChange("tds_calibration", event.target.value)}/>
                    </Form.Item>
                    <Form.Item>
                        <i className={`small-text-grey`}>PH (pH)</i><br/>
                        <Input value={this.state.ph_calibration} placeholder={`Calibration Value`} onChange={event => this.onFieldChange("ph_calibration", event.target.value)}/>
                    </Form.Item>
                </div>

                <div style={(this.props.updateType === "UPDATE_SETVALUE") ? {} : {display: 'none'}}>
                    <Form.Item>
                        <i className={`small-text-grey`}>PT Inlet (Bar)</i><br/>
                        <Input value={this.state.pressure_1_setvalue} placeholder={`Calibration Value`} onChange={event => this.onFieldChange("pressure_1_setvalue", event.target.value)}/>
                    </Form.Item>
                    <Form.Item>
                        <i className={`small-text-grey`}>PT Feed (Bar)</i><br/>
                        <Input value={this.state.pressure_2_setvalue} placeholder={`Calibration Value`} onChange={event => this.onFieldChange("pressure_2_setvalue", event.target.value)}/>
                    </Form.Item>
                    <Form.Item>
                        <i className={`small-text-grey`}>PT HPP (Bar)</i><br/>
                        <Input value={this.state.pressure_3_setvalue} placeholder={`Calibration Value`} onChange={event => this.onFieldChange("pressure_3_setvalue", event.target.value)}/>
                    </Form.Item>
                    <Form.Item>
                        <i className={`small-text-grey`}>PT HPP Warning (Bar)</i><br/>
                        <Input value={this.state.pressure_3_warning_setvalue} placeholder={`Calibration Value`} onChange={event => this.onFieldChange("pressure_3_warning_setvalue", event.target.value)}/>
                    </Form.Item>
                    <Form.Item>
                        <i className={`small-text-grey`}>TDS (uS/cm)</i><br/>
                        <Input value={this.state.tds_setvalue} placeholder={`Calibration Value`} onChange={event => this.onFieldChange("tds_setvalue", event.target.value)}/>
                    </Form.Item>
                    {/*<Form.Item>*/}
                    {/*    <i className={`small-text-grey`}>PH</i><br/>*/}
                    {/*    <Input value={this.state.ph_setvalue} placeholder={`Calibration Value`} onChange={event => this.onFieldChange("ph_setvalue", event.target.value)}/>*/}
                    {/*</Form.Item>*/}
                    <Form.Item>
                        <i className={`small-text-grey`}>PH Min (pH)</i><br/>
                        <Input value={this.state.ph_min_setvalue} placeholder={`Calibration Value`} onChange={event => this.onFieldChange("ph_min_setvalue", event.target.value)}/>
                    </Form.Item>
                    <Form.Item>
                        <i className={`small-text-grey`}>PH Max (pH)</i><br/>
                        <Input value={this.state.ph_max_setvalue} placeholder={`Calibration Value`} onChange={event => this.onFieldChange("ph_max_setvalue", event.target.value)}/>
                    </Form.Item>
                    <Form.Item>
                        <i className={`small-text-grey`}>Wash (time in second)</i><br/>
                        <Input value={this.state.wash_setvalue} placeholder={`Calibration Value`} onChange={event => this.onFieldChange("wash_setvalue", event.target.value)}/>
                    </Form.Item>
                </div>

                <Divider/>
                <div style={{textAlign: "right"}}>
                    <Button className={`m-5`} onClick={this.closeAction}> Cancel</Button>
                    <Button className={`m-5`} onClick={this.saveAction} loading={this.state.processing} type={'primary'}> Save</Button>
                </div>
            </>
        )
    }
}

export default MasterCalibrationForm