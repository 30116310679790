import React, {Component} from 'react'
import Cookies from 'universal-cookie'
import {Buffer} from "buffer"
import withAuth from "../../hoc/withAuth"
import currencyFormat from "../../utils/currencyFormat"
import exportExcel from "../../utils/exportExcel"
import {Col, Row, Dropdown} from 'antd'
import {DownOutlined, FileExcelOutlined} from "@ant-design/icons"
import MainLayout from "../../components/layout/MainLayout"
import {Divider, message, Table} from "antd"
import {kioskApiService} from "../../services/wiloKiosk/KioskApiService"
import dayjs from "dayjs"
import RangeDate from "../../components/general/RangeDate"
import KioskFilter from "../../components/general/KioskFilter"

class LogKiosk extends Component {
    constructor(props) {
        super(props)
        this.cookies = new Cookies()
        this.initState = {
            token: (this.cookies.get('_wk-t')) ? JSON.parse(Buffer.from(this.cookies.get('_wk-t'), 'base64').toString()) : '',
            fetchProcessing: true,
            startDate: dayjs(),
            endDate: dayjs(),
            tableData: [],
            kioskFilter: 0,
            kioskSelect: "ALL",
        }
        this.state = {...this.initState}
    }

    fetchData = async () => {
        await this.setState({
            fetchProcessing: true,
            tableData: []
        })

        try {
            await kioskApiService.setToken(this.state.token)
            const locationData = await kioskApiService.readLogKiosk({
                startDate: dayjs(this.state.startDate).format("YYYY-MM-DD"),
                endDate: dayjs(this.state.endDate).format("YYYY-MM-DD"),
                kioskId: (this.state.kioskSelect === "ALL") ? null : this.state.kioskSelect
            })
            this.setState({
                fetchProcessing: false,
                tableData: locationData.data.data
            })
        } catch (e) {
            this.setState({
                fetchProcessing: false,
                tableData: [],
            })
            message.info(e)
        }
    }

    async componentDidMount() {
        await this.fetchData()
    }

    RangeDateChangeAction = async (data) => {
        await this.setState({startDate: data.startDate, endDate: data.endDate})
        await this.fetchData()
    }

    exportData = async (type = "excel") => {
        const data = this.state.tableData
        if (data.length < 1) {
            message.info('No data available for export')
            return
        }

        if (type === "excel") {
            const xlsData = []
            xlsData.push(['Transaction Time', 'Transaction Code', 'Kiosk Code', 'Kiosk Name', 'Kiosk Status', 'Location Name', 'Product Code', 'Product Name', 'Product Volume', 'Product Price'])
            data.forEach(item => {
                xlsData.push([
                    item.created_at?.replace("T", " ")?.substring(0, 19) ?? "",
                    item.transaction_code ?? "",
                    item.kiosk_code ?? "",
                    item.kiosk_name ?? "",
                    item.kiosk_status ?? "",
                    item.location_name ?? "",
                    item.product_code ?? "",
                    item.product_name ?? "",
                    item.product_volume + " L" ?? "",
                    item.transaction_amount ?? ""
                ])
            })

            await exportExcel({
                title: `Log Kiosk ${dayjs(this.state.startDate).format("YYYY/MM/DD")} - ${dayjs(this.state.endDate).format("YYYY/MM/DD")}`,
                generatedTime: 'GenerateTime: ' + dayjs(this.state.startDate).format('YYYY-MM-DD HH:mm:ss'),
                data: xlsData,
                sheetName: "Sheet1",
                fileName: "WiloKiosk - Log Kiosk"
            })
        }
    }

    FilterChangeAction = async (data) => {
        await this.setState({
            kioskFilter: data.kioskFilter,
            kioskSelect: data.kioskSelect
        })
        await this.fetchData()
    }

    render() {
        const items = [
            {key: '1', label: (<span onClick={() => this.exportData("excel")}><FileExcelOutlined/> Export Excel</span>)},
        ]

        return (
            <MainLayout titlePage={`Log Kiosk`} fullWidth={true} breadcrumbPath={`Log Activity`} breadcrumbMenu={`/ Log Kiosk`} defaultSelectedKeys={['m-log-kiosk']} defaultOpenKeys={['sm-log']}>
                <div className={`m-10`}>
                    <Row>
                        <Col xl={{span: 2, order: 1}} xs={{span: 24, order: 2}} className={`p-5`}>
                            <i className={`extra-small-text-grey`}> Filter</i><br/>
                            <KioskFilter token={this.state.token} formChildAction={this.FilterChangeAction} kioskSelect={this.state.kioskSelect}/>
                        </Col>
                        <Col xl={{span: 4, order: 2}} xs={{span: 24, order: 3}} className={`p-5`} order={2}>
                            <i className={`extra-small-text-grey`}> Range Date</i><br/>
                            <RangeDate formChildAction={this.RangeDateChangeAction} startDate={this.state.startDate} endDate={this.state.endDate}/>
                        </Col>
                        <Col xl={{span: 14, order: 3}} xs={{span: 24, order: 4}} className={`p-5`}/>
                        <Col xl={{span: 4, order: 4}} xs={{span: 24, order: 1}} className={`p-5`} order={1}>
                            <div style={{float: 'right'}}>
                                <Dropdown.Button
                                    type="primary"
                                    onClick={event => this.exportData("excel")}
                                    icon={<DownOutlined/>}
                                    trigger={['click']}
                                    menu={{items}}
                                ><FileExcelOutlined/></Dropdown.Button>
                            </div>
                        </Col>
                    </Row>
                </div>

                <Divider className={`divider-thin`}/>
                <Table
                    size="small"
                    rowKey={`id`}
                    pagination={false}
                    scroll={{x: 'calc(500px + 50%)'}}
                    dataSource={this.state.tableData}
                    loading={this.state.fetchProcessing}
                    columns={[
                        {
                            title: 'Transaction Time',
                            dataIndex: 'created_at',
                            render: ((value, row) => {
                                return <>{value?.replace("T", " ")?.substring(0, 19)}</>
                            })
                        },
                        {
                            title: 'Transaction Code',
                            dataIndex: 'transaction_code',
                            render: ((value, row) => {
                                return <>{value}</>
                            })
                        },
                        {
                            title: 'Kiosk Code',
                            dataIndex: 'kiosk_code',
                            render: ((value, row) => {
                                return <>{value}</>
                            })
                        },
                        {
                            title: 'Kiosk Name',
                            dataIndex: 'kiosk_name',
                            render: ((value, row) => {
                                return <>{value}</>
                            })
                        },
                        {
                            title: 'Kiosk Status',
                            dataIndex: 'kiosk_status',
                            render: ((value, row) => {
                                return <>{value}</>
                            })
                        },
                        {
                            title: 'Location Name',
                            dataIndex: 'location_name',
                            render: ((value, row) => {
                                return <>{value}</>
                            })
                        },
                        {
                            title: 'Product Code',
                            dataIndex: 'product_code',
                            render: ((value, row) => {
                                return <>{value}</>
                            })
                        },
                        {
                            title: 'Product Name',
                            dataIndex: 'product_name',
                            render: ((value, row) => {
                                return <>{value}</>
                            })
                        },
                        {
                            title: 'Product Volume',
                            dataIndex: 'product_volume',
                            render: ((value, row) => {
                                return <>{value} Liter</>
                            })
                        },
                        {
                            title: 'Product Price',
                            dataIndex: 'transaction_amount',
                            render: ((value, row) => {
                                return <>{currencyFormat(value)}</>
                            })
                        }
                    ]}
                />
            </MainLayout>
        )
    }
}

export default withAuth(LogKiosk, 5)