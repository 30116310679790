export const cityKemendagri = [
	{"city_code": "31.01","city_name": "kab adm kep seribu"},
	{"city_code": "31.71","city_name": "kota adm jakarta pusat"},
	{"city_code": "31.72","city_name": "kota adm jakarta utara"},
	{"city_code": "31.73","city_name": "kota adm jakarta barat"},
	{"city_code": "31.74","city_name": "kota adm jakarta selatan"},
	{"city_code": "31.75","city_name": "kota adm jakarta timur"},
	{"city_code": "36.01","city_name": "kab pandeglang"},
	{"city_code": "36.02","city_name": "kab lebak"},
	{"city_code": "36.03","city_name": "kab tangerang"},
	{"city_code": "36.04","city_name": "kab serang"},
	{"city_code": "36.71","city_name": "kota tangerang"},
	{"city_code": "36.72","city_name": "kota cilegon"},
	{"city_code": "36.73","city_name": "kota serang"},
	{"city_code": "36.74","city_name": "kota tangerang selatan"},
	{"city_code": "32.01","city_name": "kab bogor"},
	{"city_code": "32.02","city_name": "kab sukabumi"},
	{"city_code": "32.03","city_name": "kab cianjur"},
	{"city_code": "32.04","city_name": "kab bandung"},
	{"city_code": "32.05","city_name": "kab garut"},
	{"city_code": "32.06","city_name": "kab tasikmalaya"},
	{"city_code": "32.07","city_name": "kab ciamis"},
	{"city_code": "32.08","city_name": "kab kuningan"},
	{"city_code": "32.09","city_name": "kab cirebon"},
	{"city_code": "32.10","city_name": "kab majalengka"},
	{"city_code": "32.11","city_name": "kab sumedang"},
	{"city_code": "32.12","city_name": "kab indramayu"},
	{"city_code": "32.13","city_name": "kab subang"},
	{"city_code": "32.14","city_name": "kab purwakarta"},
	{"city_code": "32.15","city_name": "kab karawang"},
	{"city_code": "32.16","city_name": "kab bekasi"},
	{"city_code": "32.17","city_name": "kab bandung barat"},
	{"city_code": "32.18","city_name": "kab pangandaran"},
	{"city_code": "32.71","city_name": "kota bogor"},
	{"city_code": "32.72","city_name": "kota sukabumi"},
	{"city_code": "32.73","city_name": "kota bandung"},
	{"city_code": "32.74","city_name": "kota cirebon"},
	{"city_code": "32.75","city_name": "kota bekasi"},
	{"city_code": "32.76","city_name": "kota depok"},
	{"city_code": "32.77","city_name": "kota cimahi"},
	{"city_code": "32.78","city_name": "kota tasikmalaya"},
	{"city_code": "32.79","city_name": "kota banjar"},
	{"city_code": "11.01","city_name": "kab aceh selatan"},
	{"city_code": "11.02","city_name": "kab aceh tenggara"},
	{"city_code": "11.03","city_name": "kab aceh timur"},
	{"city_code": "11.04","city_name": "kab aceh tengah"},
	{"city_code": "11.05","city_name": "kab aceh barat"},
	{"city_code": "11.06","city_name": "kab aceh besar"},
	{"city_code": "11.07","city_name": "kab pidie"},
	{"city_code": "11.08","city_name": "kab aceh utara"},
	{"city_code": "11.09","city_name": "kab simeulue"},
	{"city_code": "11.10","city_name": "kab aceh singkil"},
	{"city_code": "11.11","city_name": "kab bireuen"},
	{"city_code": "11.12","city_name": "kab aceh barat daya"},
	{"city_code": "11.13","city_name": "kab gayo lues"},
	{"city_code": "11.14","city_name": "kab aceh jaya"},
	{"city_code": "11.15","city_name": "kab nagan raya"},
	{"city_code": "11.16","city_name": "kab aceh tamiang"},
	{"city_code": "11.17","city_name": "kab bener meriah"},
	{"city_code": "11.18","city_name": "kab pidie jaya"},
	{"city_code": "11.71","city_name": "kota banda aceh"},
	{"city_code": "11.72","city_name": "kota sabang"},
	{"city_code": "11.73","city_name": "kota lhokseumawe"},
	{"city_code": "11.74","city_name": "kota langsa"},
	{"city_code": "11.75","city_name": "kota subulussalam"},
	{"city_code": "12.01","city_name": "kab tapanuli tengah"},
	{"city_code": "12.02","city_name": "kab tapanuli utara"},
	{"city_code": "12.03","city_name": "kab tapanuli selatan"},
	{"city_code": "12.04","city_name": "kab nias"},
	{"city_code": "12.05","city_name": "kab langkat"},
	{"city_code": "12.06","city_name": "kab karo"},
	{"city_code": "12.07","city_name": "kab deli serdang"},
	{"city_code": "12.08","city_name": "kab simalungun"},
	{"city_code": "12.09","city_name": "kab asahan"},
	{"city_code": "12.10","city_name": "kab labuhanbatu"},
	{"city_code": "12.11","city_name": "kab dairi"},
	{"city_code": "12.12","city_name": "kab toba samosir"},
	{"city_code": "12.13","city_name": "kab mandailing natal"},
	{"city_code": "12.14","city_name": "kab nias selatan"},
	{"city_code": "12.15","city_name": "kab pakpak bharat"},
	{"city_code": "12.16","city_name": "kab humbang hasundutan"},
	{"city_code": "12.17","city_name": "kab samosir"},
	{"city_code": "12.18","city_name": "kab serdang bedagai"},
	{"city_code": "12.19","city_name": "kab batu bara"},
	{"city_code": "12.20","city_name": "kab padang lawas utara"},
	{"city_code": "12.21","city_name": "kab padang lawas"},
	{"city_code": "12.22","city_name": "kab labuhanbatu selatan"},
	{"city_code": "12.23","city_name": "kab labuhanbatu utara"},
	{"city_code": "12.24","city_name": "kab nias utara"},
	{"city_code": "12.25","city_name": "kab nias barat"},
	{"city_code": "12.71","city_name": "kota medan"},
	{"city_code": "12.72","city_name": "kota pematangsiantar"},
	{"city_code": "12.73","city_name": "kota sibolga"},
	{"city_code": "12.74","city_name": "kota tanjung balai"},
	{"city_code": "12.75","city_name": "kota binjai"},
	{"city_code": "12.76","city_name": "kota tebing tinggi"},
	{"city_code": "12.77","city_name": "kota padangsidimpuan"},
	{"city_code": "12.78","city_name": "kota gunungsitoli"},
	{"city_code": "13.01","city_name": "kab pesisir selatan"},
	{"city_code": "13.02","city_name": "kab solok"},
	{"city_code": "13.03","city_name": "kab sijunjung"},
	{"city_code": "13.04","city_name": "kab tanah datar"},
	{"city_code": "13.05","city_name": "kab padang pariaman"},
	{"city_code": "13.06","city_name": "kab agam"},
	{"city_code": "13.07","city_name": "kab lima puluh kota"},
	{"city_code": "13.08","city_name": "kab pasaman"},
	{"city_code": "13.09","city_name": "kab kepulauan mentawai"},
	{"city_code": "13.10","city_name": "kab dharmasraya"},
	{"city_code": "13.11","city_name": "kab solok selatan"},
	{"city_code": "13.12","city_name": "kab pasaman barat"},
	{"city_code": "13.71","city_name": "kota padang"},
	{"city_code": "13.72","city_name": "kota solok"},
	{"city_code": "13.73","city_name": "kota sawahlunto"},
	{"city_code": "13.74","city_name": "kota padang panjang"},
	{"city_code": "13.75","city_name": "kota bukittinggi"},
	{"city_code": "13.76","city_name": "kota payakumbuh"},
	{"city_code": "13.77","city_name": "kota pariaman"},
	{"city_code": "14.01","city_name": "kab kampar"},
	{"city_code": "14.02","city_name": "kab indragiri hulu"},
	{"city_code": "14.03","city_name": "kab bengkalis"},
	{"city_code": "14.04","city_name": "kab indragiri hilir"},
	{"city_code": "14.05","city_name": "kab pelalawan"},
	{"city_code": "14.06","city_name": "kab rokan hulu"},
	{"city_code": "14.07","city_name": "kab rokan hilir"},
	{"city_code": "14.08","city_name": "kab siak"},
	{"city_code": "14.09","city_name": "kab kuantan singingi"},
	{"city_code": "14.10","city_name": "kab kepulauan meranti"},
	{"city_code": "14.71","city_name": "kota pekanbaru"},
	{"city_code": "14.72","city_name": "kota dumai"},
	{"city_code": "15.01","city_name": "kab kerinci"},
	{"city_code": "15.02","city_name": "kab merangin"},
	{"city_code": "15.03","city_name": "kab sarolangun"},
	{"city_code": "15.04","city_name": "kab batanghari"},
	{"city_code": "15.05","city_name": "kab muaro jambi"},
	{"city_code": "15.06","city_name": "kab tanjung jabung barat"},
	{"city_code": "15.07","city_name": "kab tanjung jabung timur"},
	{"city_code": "15.08","city_name": "kab bungo"},
	{"city_code": "15.09","city_name": "kab tebo"},
	{"city_code": "15.71","city_name": "kota jambi"},
	{"city_code": "15.72","city_name": "kota sungai penuh"},
	{"city_code": "16.01","city_name": "kab ogan komering ulu"},
	{"city_code": "16.02","city_name": "kab ogan komering ilir"},
	{"city_code": "16.03","city_name": "kab muara enim"},
	{"city_code": "16.04","city_name": "kab lahat"},
	{"city_code": "16.05","city_name": "kab musi rawas"},
	{"city_code": "16.06","city_name": "kab musi banyuasin"},
	{"city_code": "16.07","city_name": "kab banyuasin"},
	{"city_code": "16.08","city_name": "kab ogan komering ulu timur"},
	{"city_code": "16.09","city_name": "kab ogan komering ulu selatan"},
	{"city_code": "16.10","city_name": "kab ogan ilir"},
	{"city_code": "16.11","city_name": "kab empat lawang"},
	{"city_code": "16.12","city_name": "kab penukal abab lematang ilir"},
	{"city_code": "16.13","city_name": "kab musi rawas utara"},
	{"city_code": "16.71","city_name": "kota palembang"},
	{"city_code": "16.72","city_name": "kota pagar alam"},
	{"city_code": "16.73","city_name": "kota lubuk linggau"},
	{"city_code": "16.74","city_name": "kota prabumulih"},
	{"city_code": "17.01","city_name": "kab bengkulu selatan"},
	{"city_code": "17.02","city_name": "kab rejang lebong"},
	{"city_code": "17.03","city_name": "kab bengkulu utara"},
	{"city_code": "17.04","city_name": "kab kaur"},
	{"city_code": "17.05","city_name": "kab seluma"},
	{"city_code": "17.06","city_name": "kab muko muko"},
	{"city_code": "17.07","city_name": "kab lebong"},
	{"city_code": "17.08","city_name": "kab kepahiang"},
	{"city_code": "17.09","city_name": "kab bengkulu tengah"},
	{"city_code": "17.71","city_name": "kota bengkulu"},
	{"city_code": "18.01","city_name": "kab lampung selatan"},
	{"city_code": "18.02","city_name": "kab lampung tengah"},
	{"city_code": "18.03","city_name": "kab lampung utara"},
	{"city_code": "18.04","city_name": "kab lampung barat"},
	{"city_code": "18.05","city_name": "kab tulang bawang"},
	{"city_code": "18.06","city_name": "kab tanggamus"},
	{"city_code": "18.07","city_name": "kab lampung timur"},
	{"city_code": "18.08","city_name": "kab way kanan"},
	{"city_code": "18.09","city_name": "kab pesawaran"},
	{"city_code": "18.10","city_name": "kab pringsewu"},
	{"city_code": "18.11","city_name": "kab mesuji"},
	{"city_code": "18.12","city_name": "kab tulang bawang barat"},
	{"city_code": "18.13","city_name": "kab pesisir barat"},
	{"city_code": "18.71","city_name": "kota bandar lampung"},
	{"city_code": "18.72","city_name": "kota metro"},
	{"city_code": "19.01","city_name": "kab bangka"},
	{"city_code": "19.02","city_name": "kab belitung"},
	{"city_code": "19.03","city_name": "kab bangka selatan"},
	{"city_code": "19.04","city_name": "kab bangka tengah"},
	{"city_code": "19.05","city_name": "kab bangka barat"},
	{"city_code": "19.06","city_name": "kab belitung timur"},
	{"city_code": "19.71","city_name": "kota pangkal pinang"},
	{"city_code": "21.01","city_name": "kab bintan"},
	{"city_code": "21.02","city_name": "kab karimun"},
	{"city_code": "21.03","city_name": "kab natuna"},
	{"city_code": "21.04","city_name": "kab lingga"},
	{"city_code": "21.05","city_name": "kab kepulauan anambas"},
	{"city_code": "21.71","city_name": "kota batam"},
	{"city_code": "21.72","city_name": "kota tanjung pinang"},
	{"city_code": "33.01","city_name": "kab cilacap"},
	{"city_code": "33.02","city_name": "kab banyumas"},
	{"city_code": "33.03","city_name": "kab purbalingga"},
	{"city_code": "33.04","city_name": "kab banjarnegara"},
	{"city_code": "33.05","city_name": "kab kebumen"},
	{"city_code": "33.06","city_name": "kab purworejo"},
	{"city_code": "33.07","city_name": "kab wonosobo"},
	{"city_code": "33.08","city_name": "kab magelang"},
	{"city_code": "33.09","city_name": "kab boyolali"},
	{"city_code": "33.10","city_name": "kab klaten"},
	{"city_code": "33.11","city_name": "kab sukoharjo"},
	{"city_code": "33.12","city_name": "kab wonogiri"},
	{"city_code": "33.13","city_name": "kab karanganyar"},
	{"city_code": "33.14","city_name": "kab sragen"},
	{"city_code": "33.15","city_name": "kab grobogan"},
	{"city_code": "33.16","city_name": "kab blora"},
	{"city_code": "33.17","city_name": "kab rembang"},
	{"city_code": "33.18","city_name": "kab pati"},
	{"city_code": "33.19","city_name": "kab kudus"},
	{"city_code": "33.20","city_name": "kab jepara"},
	{"city_code": "33.21","city_name": "kab demak"},
	{"city_code": "33.22","city_name": "kab semarang"},
	{"city_code": "33.23","city_name": "kab temanggung"},
	{"city_code": "33.24","city_name": "kab kendal"},
	{"city_code": "33.25","city_name": "kab batang"},
	{"city_code": "33.26","city_name": "kab pekalongan"},
	{"city_code": "33.27","city_name": "kab pemalang"},
	{"city_code": "33.28","city_name": "kab tegal"},
	{"city_code": "33.29","city_name": "kab brebes"},
	{"city_code": "33.71","city_name": "kota magelang"},
	{"city_code": "33.72","city_name": "kota surakarta"},
	{"city_code": "33.73","city_name": "kota salatiga"},
	{"city_code": "33.74","city_name": "kota semarang"},
	{"city_code": "33.75","city_name": "kota pekalongan"},
	{"city_code": "33.76","city_name": "kota tegal"},
	{"city_code": "34.01","city_name": "kab kulon progo"},
	{"city_code": "34.02","city_name": "kab bantul"},
	{"city_code": "34.03","city_name": "kab gunungkidul"},
	{"city_code": "34.04","city_name": "kab sleman"},
	{"city_code": "34.71","city_name": "kota yogyakarta"},
	{"city_code": "35.01","city_name": "kab pacitan"},
	{"city_code": "35.02","city_name": "kab ponorogo"},
	{"city_code": "35.03","city_name": "kab trenggalek"},
	{"city_code": "35.04","city_name": "kab tulungagung"},
	{"city_code": "35.05","city_name": "kab blitar"},
	{"city_code": "35.06","city_name": "kab kediri"},
	{"city_code": "35.07","city_name": "kab malang"},
	{"city_code": "35.08","city_name": "kab lumajang"},
	{"city_code": "35.09","city_name": "kab jember"},
	{"city_code": "35.10","city_name": "kab banyuwangi"},
	{"city_code": "35.11","city_name": "kab bondowoso"},
	{"city_code": "35.12","city_name": "kab situbondo"},
	{"city_code": "35.13","city_name": "kab probolinggo"},
	{"city_code": "35.14","city_name": "kab pasuruan"},
	{"city_code": "35.15","city_name": "kab sidoarjo"},
	{"city_code": "35.16","city_name": "kab mojokerto"},
	{"city_code": "35.17","city_name": "kab jombang"},
	{"city_code": "35.18","city_name": "kab nganjuk"},
	{"city_code": "35.19","city_name": "kab madiun"},
	{"city_code": "35.20","city_name": "kab magetan"},
	{"city_code": "35.21","city_name": "kab ngawi"},
	{"city_code": "35.22","city_name": "kab bojonegoro"},
	{"city_code": "35.23","city_name": "kab tuban"},
	{"city_code": "35.24","city_name": "kab lamongan"},
	{"city_code": "35.25","city_name": "kab gresik"},
	{"city_code": "35.26","city_name": "kab bangkalan"},
	{"city_code": "35.27","city_name": "kab sampang"},
	{"city_code": "35.28","city_name": "kab pamekasan"},
	{"city_code": "35.29","city_name": "kab sumenep"},
	{"city_code": "35.71","city_name": "kota kediri"},
	{"city_code": "35.72","city_name": "kota blitar"},
	{"city_code": "35.73","city_name": "kota malang"},
	{"city_code": "35.74","city_name": "kota probolinggo"},
	{"city_code": "35.75","city_name": "kota pasuruan"},
	{"city_code": "35.76","city_name": "kota mojokerto"},
	{"city_code": "35.77","city_name": "kota madiun"},
	{"city_code": "35.78","city_name": "kota surabaya"},
	{"city_code": "35.79","city_name": "kota batu"},
	{"city_code": "51.01","city_name": "kab jembrana"},
	{"city_code": "51.02","city_name": "kab tabanan"},
	{"city_code": "51.03","city_name": "kab badung"},
	{"city_code": "51.04","city_name": "kab gianyar"},
	{"city_code": "51.05","city_name": "kab klungkung"},
	{"city_code": "51.06","city_name": "kab bangli"},
	{"city_code": "51.07","city_name": "kab karangasem"},
	{"city_code": "51.08","city_name": "kab buleleng"},
	{"city_code": "51.71","city_name": "kota denpasar"},
	{"city_code": "52.01","city_name": "kab lombok barat"},
	{"city_code": "52.02","city_name": "kab lombok tengah"},
	{"city_code": "52.03","city_name": "kab lombok timur"},
	{"city_code": "52.04","city_name": "kab sumbawa"},
	{"city_code": "52.05","city_name": "kab dompu"},
	{"city_code": "52.06","city_name": "kab bima"},
	{"city_code": "52.07","city_name": "kab sumbawa barat"},
	{"city_code": "52.08","city_name": "kab lombok utara"},
	{"city_code": "52.71","city_name": "kota mataram"},
	{"city_code": "52.72","city_name": "kota bima"},
	{"city_code": "53.01","city_name": "kab kupang"},
	{"city_code": "53.02","city_name": "kab timor tengah selatan"},
	{"city_code": "53.03","city_name": "kab timor tengah utara"},
	{"city_code": "53.04","city_name": "kab belu"},
	{"city_code": "53.05","city_name": "kab alor"},
	{"city_code": "53.06","city_name": "kab flores timur"},
	{"city_code": "53.07","city_name": "kab sikka"},
	{"city_code": "53.08","city_name": "kab ende"},
	{"city_code": "53.09","city_name": "kab ngada"},
	{"city_code": "53.10","city_name": "kab manggarai"},
	{"city_code": "53.11","city_name": "kab sumba timur"},
	{"city_code": "53.12","city_name": "kab sumba barat"},
	{"city_code": "53.13","city_name": "kab lembata"},
	{"city_code": "53.14","city_name": "kab rote ndao"},
	{"city_code": "53.15","city_name": "kab manggarai barat"},
	{"city_code": "53.16","city_name": "kab nagekeo"},
	{"city_code": "53.17","city_name": "kab sumba tengah"},
	{"city_code": "53.18","city_name": "kab sumba barat daya"},
	{"city_code": "53.19","city_name": "kab manggarai timur"},
	{"city_code": "53.20","city_name": "kab sabu raijua"},
	{"city_code": "53.21","city_name": "kab malaka"},
	{"city_code": "53.71","city_name": "kota kupang"},
	{"city_code": "61.01","city_name": "kab sambas"},
	{"city_code": "61.02","city_name": "kab mempawah"},
	{"city_code": "61.03","city_name": "kab sanggau"},
	{"city_code": "61.04","city_name": "kab ketapang"},
	{"city_code": "61.05","city_name": "kab sintang"},
	{"city_code": "61.06","city_name": "kab kapuas hulu"},
	{"city_code": "61.07","city_name": "kab bengkayang"},
	{"city_code": "61.08","city_name": "kab landak"},
	{"city_code": "61.09","city_name": "kab sekadau"},
	{"city_code": "61.10","city_name": "kab melawi"},
	{"city_code": "61.11","city_name": "kab kayong utara"},
	{"city_code": "61.12","city_name": "kab kubu raya"},
	{"city_code": "61.71","city_name": "kota pontianak"},
	{"city_code": "61.72","city_name": "kota singkawang"},
	{"city_code": "62.01","city_name": "kab kotawaringin barat"},
	{"city_code": "62.02","city_name": "kab kotawaringin timur"},
	{"city_code": "62.03","city_name": "kab kapuas"},
	{"city_code": "62.04","city_name": "kab barito selatan"},
	{"city_code": "62.05","city_name": "kab barito utara"},
	{"city_code": "62.06","city_name": "kab katingan"},
	{"city_code": "62.07","city_name": "kab seruyan"},
	{"city_code": "62.08","city_name": "kab sukamara"},
	{"city_code": "62.09","city_name": "kab lamandau"},
	{"city_code": "62.10","city_name": "kab gunung mas"},
	{"city_code": "62.11","city_name": "kab pulang pisau"},
	{"city_code": "62.12","city_name": "kab murung raya"},
	{"city_code": "62.13","city_name": "kab barito timur"},
	{"city_code": "62.71","city_name": "kota palangkaraya"},
	{"city_code": "63.01","city_name": "kab tanah laut"},
	{"city_code": "63.02","city_name": "kab kotabaru"},
	{"city_code": "63.03","city_name": "kab banjar"},
	{"city_code": "63.04","city_name": "kab barito kuala"},
	{"city_code": "63.05","city_name": "kab tapin"},
	{"city_code": "63.06","city_name": "kab hulu sungai selatan"},
	{"city_code": "63.07","city_name": "kab hulu sungai tengah"},
	{"city_code": "63.08","city_name": "kab hulu sungai utara"},
	{"city_code": "63.09","city_name": "kab tabalong"},
	{"city_code": "63.10","city_name": "kab tanah bumbu"},
	{"city_code": "63.11","city_name": "kab balangan"},
	{"city_code": "63.71","city_name": "kota banjarmasin"},
	{"city_code": "63.72","city_name": "kota banjarbaru"},
	{"city_code": "64.01","city_name": "kab paser"},
	{"city_code": "64.02","city_name": "kab kutai kartanegara"},
	{"city_code": "64.03","city_name": "kab berau"},
	{"city_code": "64.07","city_name": "kab kutai barat"},
	{"city_code": "64.08","city_name": "kab kutai timur"},
	{"city_code": "64.09","city_name": "kab penajam paser utara"},
	{"city_code": "64.11","city_name": "kab mahakam ulu"},
	{"city_code": "64.71","city_name": "kota balikpapan"},
	{"city_code": "64.72","city_name": "kota samarinda"},
	{"city_code": "64.74","city_name": "kota bontang"},
	{"city_code": "65.01","city_name": "kab bulungan"},
	{"city_code": "65.02","city_name": "kab malinau"},
	{"city_code": "65.03","city_name": "kab nunukan"},
	{"city_code": "65.04","city_name": "kab tana tidung"},
	{"city_code": "65.71","city_name": "kota tarakan"},
	{"city_code": "71.01","city_name": "kab bolaang mongondow"},
	{"city_code": "71.02","city_name": "kab minahasa"},
	{"city_code": "71.03","city_name": "kab kepulauan sangihe"},
	{"city_code": "71.04","city_name": "kab kepulauan talaud"},
	{"city_code": "71.05","city_name": "kab minahasa selatan"},
	{"city_code": "71.06","city_name": "kab minahasa utara"},
	{"city_code": "71.07","city_name": "kab minahasa tenggara"},
	{"city_code": "71.08","city_name": "kab bolaang mongondow utara"},
	{"city_code": "71.09","city_name": "kab kep siau tagulandang biaro"},
	{"city_code": "71.10","city_name": "kab bolaang mongondow timur"},
	{"city_code": "71.11","city_name": "kab bolaang mongondow selatan"},
	{"city_code": "71.71","city_name": "kota manado"},
	{"city_code": "71.72","city_name": "kota bitung"},
	{"city_code": "71.73","city_name": "kota tomohon"},
	{"city_code": "71.74","city_name": "kota kotamobagu"},
	{"city_code": "72.01","city_name": "kab banggai"},
	{"city_code": "72.02","city_name": "kab poso"},
	{"city_code": "72.03","city_name": "kab donggala"},
	{"city_code": "72.04","city_name": "kab toli toli"},
	{"city_code": "72.05","city_name": "kab buol"},
	{"city_code": "72.06","city_name": "kab morowali"},
	{"city_code": "72.07","city_name": "kab banggai kepulauan"},
	{"city_code": "72.08","city_name": "kab parigi moutong"},
	{"city_code": "72.09","city_name": "kab tojo una una"},
	{"city_code": "72.10","city_name": "kab sigi"},
	{"city_code": "72.11","city_name": "kab banggai laut"},
	{"city_code": "72.12","city_name": "kab morowali utara"},
	{"city_code": "72.71","city_name": "kota palu"},
	{"city_code": "73.01","city_name": "kab kepulauan selayar"},
	{"city_code": "73.02","city_name": "kab bulukumba"},
	{"city_code": "73.03","city_name": "kab bantaeng"},
	{"city_code": "73.04","city_name": "kab jeneponto"},
	{"city_code": "73.05","city_name": "kab takalar"},
	{"city_code": "73.06","city_name": "kab gowa"},
	{"city_code": "73.07","city_name": "kab sinjai"},
	{"city_code": "73.08","city_name": "kab bone"},
	{"city_code": "73.09","city_name": "kab maros"},
	{"city_code": "73.10","city_name": "kab pangkajene kepulauan"},
	{"city_code": "73.11","city_name": "kab barru"},
	{"city_code": "73.12","city_name": "kab soppeng"},
	{"city_code": "73.13","city_name": "kab wajo"},
	{"city_code": "73.14","city_name": "kab sidenreng rappang"},
	{"city_code": "73.15","city_name": "kab pinrang"},
	{"city_code": "73.16","city_name": "kab enrekang"},
	{"city_code": "73.17","city_name": "kab luwu"},
	{"city_code": "73.18","city_name": "kab tana toraja"},
	{"city_code": "73.22","city_name": "kab luwu utara"},
	{"city_code": "73.24","city_name": "kab luwu timur"},
	{"city_code": "73.26","city_name": "kab toraja utara"},
	{"city_code": "73.71","city_name": "kota makassar"},
	{"city_code": "73.72","city_name": "kota pare pare"},
	{"city_code": "73.73","city_name": "kota palopo"},
	{"city_code": "74.01","city_name": "kab kolaka"},
	{"city_code": "74.02","city_name": "kab konawe"},
	{"city_code": "74.03","city_name": "kab muna"},
	{"city_code": "74.04","city_name": "kab buton"},
	{"city_code": "74.05","city_name": "kab konawe selatan"},
	{"city_code": "74.06","city_name": "kab bombana"},
	{"city_code": "74.07","city_name": "kab wakatobi"},
	{"city_code": "74.08","city_name": "kab kolaka utara"},
	{"city_code": "74.09","city_name": "kab konawe utara"},
	{"city_code": "74.10","city_name": "kab buton utara"},
	{"city_code": "74.11","city_name": "kab kolaka timur"},
	{"city_code": "74.12","city_name": "kab konawe kepulauan"},
	{"city_code": "74.13","city_name": "kab muna barat"},
	{"city_code": "74.14","city_name": "kab buton tengah"},
	{"city_code": "74.15","city_name": "kab buton selatan"},
	{"city_code": "74.71","city_name": "kota kendari"},
	{"city_code": "74.72","city_name": "kota bau bau"},
	{"city_code": "75.01","city_name": "kab gorontalo"},
	{"city_code": "75.02","city_name": "kab boalemo"},
	{"city_code": "75.03","city_name": "kab bone bolango"},
	{"city_code": "75.04","city_name": "kab pahuwato"},
	{"city_code": "75.05","city_name": "kab gorontalo utara"},
	{"city_code": "75.71","city_name": "kota gorontalo"},
	{"city_code": "76.01","city_name": "kab mamuju utara"},
	{"city_code": "76.02","city_name": "kab mamuju"},
	{"city_code": "76.03","city_name": "kab mamasa"},
	{"city_code": "76.04","city_name": "kab polewali mandar"},
	{"city_code": "76.05","city_name": "kab majene"},
	{"city_code": "76.06","city_name": "kab mamuju tengah"},
	{"city_code": "81.01","city_name": "kab maluku tengah"},
	{"city_code": "81.02","city_name": "kab maluku tenggara"},
	{"city_code": "81.03","city_name": "kab maluku tenggara barat"},
	{"city_code": "81.04","city_name": "kab buru"},
	{"city_code": "81.05","city_name": "kab seram bagian timur"},
	{"city_code": "81.06","city_name": "kab seram bagian barat"},
	{"city_code": "81.07","city_name": "kab kepulauan aru"},
	{"city_code": "81.08","city_name": "kab maluku barat daya"},
	{"city_code": "81.09","city_name": "kab buru selatan"},
	{"city_code": "81.71","city_name": "kota ambon"},
	{"city_code": "81.72","city_name": "kota tual"},
	{"city_code": "82.01","city_name": "kab halmahera barat"},
	{"city_code": "82.02","city_name": "kab halmahera tengah"},
	{"city_code": "82.03","city_name": "kab halmahera utara"},
	{"city_code": "82.04","city_name": "kab halmahera selatan"},
	{"city_code": "82.05","city_name": "kab kepulauan sula"},
	{"city_code": "82.06","city_name": "kab halmahera timur"},
	{"city_code": "82.07","city_name": "kab pulau morotai"},
	{"city_code": "82.08","city_name": "kab pulau taliabu"},
	{"city_code": "82.71","city_name": "kota ternate"},
	{"city_code": "82.72","city_name": "kota tidore kepulauan"},
	{"city_code": "91.01","city_name": "kab merauke"},
	{"city_code": "91.02","city_name": "kab jayawijaya"},
	{"city_code": "91.03","city_name": "kab jayapura"},
	{"city_code": "91.04","city_name": "kab nabire"},
	{"city_code": "91.05","city_name": "kab kepulauan yapen"},
	{"city_code": "91.06","city_name": "kab biak numfor"},
	{"city_code": "91.07","city_name": "kab puncak jaya"},
	{"city_code": "91.08","city_name": "kab paniai"},
	{"city_code": "91.09","city_name": "kab mimika"},
	{"city_code": "91.10","city_name": "kab sarmi"},
	{"city_code": "91.11","city_name": "kab keerom"},
	{"city_code": "91.12","city_name": "kab pegunungan bintang"},
	{"city_code": "91.13","city_name": "kab yahukimo"},
	{"city_code": "91.14","city_name": "kab tolikara"},
	{"city_code": "91.15","city_name": "kab waropen"},
	{"city_code": "91.16","city_name": "kab boven digoel"},
	{"city_code": "91.17","city_name": "kab mappi"},
	{"city_code": "91.18","city_name": "kab asmat"},
	{"city_code": "91.19","city_name": "kab supiori"},
	{"city_code": "91.20","city_name": "kab mamberamo raya"},
	{"city_code": "91.21","city_name": "kab mamberamo tengah"},
	{"city_code": "91.22","city_name": "kab yalimo"},
	{"city_code": "91.23","city_name": "kab lanny jaya"},
	{"city_code": "91.24","city_name": "kab nduga"},
	{"city_code": "91.25","city_name": "kab puncak"},
	{"city_code": "91.26","city_name": "kab dogiyai"},
	{"city_code": "91.27","city_name": "kab intan jaya"},
	{"city_code": "91.28","city_name": "kab deiyai"},
	{"city_code": "91.71","city_name": "kota jayapura"},
	{"city_code": "92.01","city_name": "kab sorong"},
	{"city_code": "92.02","city_name": "kab manokwari"},
	{"city_code": "92.03","city_name": "kab fak fak"},
	{"city_code": "92.04","city_name": "kab sorong selatan"},
	{"city_code": "92.05","city_name": "kab raja ampat"},
	{"city_code": "92.06","city_name": "kab teluk bintuni"},
	{"city_code": "92.07","city_name": "kab teluk wondama"},
	{"city_code": "92.08","city_name": "kab kaimana"},
	{"city_code": "92.09","city_name": "kab tambrauw"},
	{"city_code": "92.10","city_name": "kab maybrat"},
	{"city_code": "92.11","city_name": "kab manokwari selatan"},
	{"city_code": "92.12","city_name": "kab pegunungan arfak"},
	{"city_code": "92.71","city_name": "kota sorong"}
]