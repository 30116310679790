import React, {Component} from 'react'
import Cookies from 'universal-cookie'
import {Buffer} from "buffer"
import withAuth from "../../hoc/withAuth"
import exportExcel from "../../utils/exportExcel"
import {Col, Dropdown, DatePicker, Row, Button} from 'antd'
import {DownOutlined, FileExcelOutlined} from "@ant-design/icons"
import MainLayout from "../../components/layout/MainLayout"
import {Divider, message, Table, Form, TimePicker} from "antd"
import {kioskApiService} from "../../services/wiloKiosk/KioskApiService"
import dayjs from "dayjs"
import KioskFilter from "../../components/general/KioskFilter"

class LogIot extends Component {
    constructor(props) {
        super(props)
        this.cookies = new Cookies()
        this.initState = {
            token: (this.cookies.get('_wk-t')) ? JSON.parse(Buffer.from(this.cookies.get('_wk-t'), 'base64').toString()) : '',
            fetchProcessing: false,
            startDate: dayjs(),
            endDate: dayjs(),
            startTime: dayjs().subtract(2, 'hour'),
            endTime: dayjs(),
            tableData: [],
            kioskFilter: 0,
            kioskSelect: "ALL",
        }
        this.state = {...this.initState}
    }

    fetchData = async () => {
        if (dayjs(this.state.startTime).isAfter(this.state.endTime)) {
            message.info("startTime cannot be greater than endTime")
            return
        }

        if (this.state.kioskSelect === "ALL") {
            message.info("Please choose kiosk at filter")
            return
        }

        await this.setState({
            fetchProcessing: true,
            tableData: []
        })

        try {
            await kioskApiService.setToken(this.state.token)
            const locationData = await kioskApiService.readLogIot({
                startDate: dayjs(this.state.startDate).format("YYYY-MM-DD"),
                endDate: dayjs(this.state.endDate).format("YYYY-MM-DD"),
                startTime: dayjs(this.state.startTime).format("HH:mm"),
                endTime: dayjs(this.state.endTime).format("HH:mm"),
                kioskId: (this.state.kioskSelect === "ALL") ? null : this.state.kioskSelect
            })
            this.setState({
                fetchProcessing: false,
                tableData: locationData.data.data
            })
        } catch (e) {
            this.setState({
                fetchProcessing: false,
                tableData: [],
            })
            message.info(e)
        }
    }

    onFieldChange = async (target, value) => {
        const currentState = {...this.state}
        switch (target) {
            case "startDate":
                if (value) {
                    currentState.startDate = value
                    currentState.endDate = value
                }
                break
            case "rangeTime":
                if (value) {
                    currentState.startTime = value[0]
                    currentState.endTime = value[1]
                }
                break
            default:
                break
        }
        this.setState({
            ...currentState,
        })
    }

    async componentDidMount() {
        await this.fetchData()
    }

    exportData = async (type = "excel") => {
        const data = this.state.tableData
        if (data.length < 1) {
            message.info('No data available for export')
            return
        }

        if (type === "excel") {
            const xlsData = []
            xlsData.push(['Transaction Time', 'Kiosk', 'State', 'PH', 'TDS', 'PT Inlet', 'PT Feed', 'PT HPP', 'Water Level', 'Feed Pump', 'HP Pump', 'Wash Pump'])
            data.forEach(item => {
                xlsData.push([
                    item.created_at?.replace("T", " ")?.substring(0, 19) ?? "",
                    item.kiosk_code ?? "",
                    item.state ?? "",
                    item.water_ph ?? "",
                    item.water_tds ?? "",
                    item.water_pressure_1 ?? "",
                    item.water_pressure_2 ?? "",
                    item.water_pressure_3 ?? "",
                    (item.water_level === 1 || item.water_level === 0) ? (item.water_level) ? "FULL" : "LOW" : "" ?? "",
                    item.feed_pump ?? "",
                    item.hp_pump ?? "",
                    item.wash_pump ?? "",
                ])
            })

            await exportExcel({
                title: `Log IoT ${dayjs(this.state.startDate).format("YYYY/MM/DD")}`,
                generatedTime: 'GenerateTime: ' + dayjs(this.state.startDate).format('YYYY-MM-DD HH:mm:ss'),
                data: xlsData,
                sheetName: "Sheet1",
                fileName: "WiloKiosk - Log IoT"
            })
        }
    }

    FilterChangeAction = async (data) => {
        await this.setState({
            kioskFilter: data.kioskFilter,
            kioskSelect: data.kioskSelect
        })
        // await this.fetchData()
    }

    render() {
        const items = [
            {key: '1', label: (<span onClick={() => this.exportData("excel")}><FileExcelOutlined/> Export Excel</span>)},
        ]

        return (
            <MainLayout titlePage={`Log IoT`} fullWidth={true} breadcrumbPath={`Log Activity`} breadcrumbMenu={`/ Log IoT`} defaultSelectedKeys={['m-log-iot']} defaultOpenKeys={['sm-log']}>
                <div className={`m-10`}>
                    <Row>
                        <Col xl={{span: 2, order: 1}} xs={{span: 24, order: 2}} className={`p-5`} style={{marginTop: 3}}>
                            <i className={`extra-small-text-grey`}> Filter</i><br/>
                            <KioskFilter token={this.state.token} formChildAction={this.FilterChangeAction} kioskSelect={this.state.kioskSelect}/>
                        </Col>
                        <Col xl={{span: 3, order: 2}} xs={{span: 24, order: 3}} className={`p-5`} order={2}>
                            <Form.Item>
                                <i className={`extra-small-text-grey`}>Date</i><br/>
                                <DatePicker value={dayjs(this.state.startDate, 'YYYY/MM/DD')} onChange={event => this.onFieldChange("startDate", event)} format={'YYYY-MM-DD'} style={{width: "100%"}}/>
                            </Form.Item>
                        </Col>
                        <Col xl={{span: 4, order: 3}} xs={{span: 24, order: 4}} className={`p-5`} order={2}>
                            <Form.Item>
                                <i className={`extra-small-text-grey`}>Range Time</i><br/>
                                <TimePicker.RangePicker value={[dayjs(this.state.startTime), dayjs(this.state.endTime)]} onChange={event => this.onFieldChange("rangeTime", event)} format={'HH:mm'}/>
                            </Form.Item>
                        </Col>
                        <Col xl={{span: 2, order: 4}} xs={{span: 24, order: 5}} className={`p-5`} order={2}>
                            <Button style={{marginTop:21}} block={true} onClick={this.fetchData} type={`primary`} >Apply</Button>
                        </Col>
                        <Col xl={{span: 9, order: 5}} xs={{span: 24, order: 6}} className={`p-5`}/>
                        <Col xl={{span: 4, order: 6}} xs={{span: 24, order: 1}} className={`p-5`} order={1}>
                            <div style={{float: 'right'}}>
                                <Dropdown.Button
                                    type="primary"
                                    onClick={event => this.exportData("excel")}
                                    icon={<DownOutlined/>}
                                    trigger={['click']}
                                    menu={{items}}
                                ><FileExcelOutlined/></Dropdown.Button>
                            </div>
                        </Col>
                    </Row>
                </div>

                <Divider className={`divider-thin`}/>

                <div style={(this.state.kioskSelect === "ALL") ? {textAlign: "center", fontSize: "125%"} : {display: 'none'}}>
                    Please select the kiosk to be monitored in the filter section.
                </div>

                <div style={(this.state.kioskSelect !== "ALL") ? {} : {display: 'none'}}>
                    <Table
                        size="small"
                        rowKey={`id`}
                        pagination={false}
                        scroll={{x: 'calc(500px + 50%)'}}
                        dataSource={this.state.tableData}
                        loading={this.state.fetchProcessing}
                        columns={[
                            {
                                title: 'Transaction Time',
                                dataIndex: 'created_at',
                                render: ((value, row) => {
                                    return <>{value?.replace("T", " ")?.substring(0, 19)}</>
                                })
                            }, {
                                title: 'Kiosk',
                                dataIndex: 'kiosk_code',
                                render: ((value, row) => {
                                    return <>{value}</>
                                })
                            }, {
                                title: 'State',
                                dataIndex: 'state',
                                render: ((value, row) => {
                                    return <>{value}</>
                                })
                            }, {
                                title: 'PH',
                                dataIndex: 'water_ph',
                                render: ((value, row) => {
                                    return <>{value}</>
                                })
                            }, {
                                title: 'TDS',
                                dataIndex: 'water_tds',
                                render: ((value, row) => {
                                    return <>{value}</>
                                })
                            }, {
                                title: 'PT Inlet',
                                dataIndex: 'water_pressure_1',
                                render: ((value, row) => {
                                    return <>{value}</>
                                })
                            }, {
                                title: 'PT Feed',
                                dataIndex: 'water_pressure_2',
                                render: ((value, row) => {
                                    return <>{value}</>
                                })
                            }, {
                                title: 'PT HPP',
                                dataIndex: 'water_pressure_3',
                                render: ((value, row) => {
                                    return <>{value}</>
                                })
                            }, {
                                title: 'Water Level',
                                dataIndex: 'water_level',
                                render: ((value, row) => {
                                    return <>{(value === 1 || value === 0) ? (value) ? "FULL" : "LOW" : ""}</>
                                })
                            }, {
                                title: 'Feed Pump',
                                dataIndex: 'feed_pump',
                                render: ((value, row) => {
                                    return <>{value}</>
                                })
                            }, {
                                title: 'HP Pump',
                                dataIndex: 'hp_pump',
                                render: ((value, row) => {
                                    return <>{value}</>
                                })
                            }, {
                                title: 'Wash Pump',
                                dataIndex: 'wash_pump',
                                render: ((value, row) => {
                                    return <>{value}</>
                                })
                            }
                        ]}
                    />
                </div>
            </MainLayout>
        )
    }
}

export default withAuth(LogIot, 6)