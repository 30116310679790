import React, {Component} from 'react'
import {Button, Card, Col, Popover, Row, Form, Select, message} from "antd"
import {FilterOutlined} from "@ant-design/icons"
import {kioskApiService} from "../../services/wiloKiosk/KioskApiService";

class KioskFilter extends Component {
    constructor(props) {
        super(props)
        this.initState = {
            fetchKioskProcessing: true,
            KioskData: [],
            openPopOver: false,
            kioskFilter: 0,
            kioskSelect: "ALL",
        }
        this.state = {...this.initState}
    }

    fetchData = async () => {
        try {
            await kioskApiService.setToken(this.props.token)
            const kioskData = await kioskApiService.readMasterKiosk()
            this.setState({
                fetchKioskProcessing: false,
                KioskData: kioskData.data.data
            })
        } catch (e) {
            this.setState({
                fetchKioskProcessing: false,
                KioskData: [],
            })
            message.info(e)
        }
    }

    async componentDidMount() {
        await this.fetchData()
        this.fillComponent()
    }

    componentDidUpdate(prevProps) {
        if ((this.props?.kioskSelect !== prevProps?.kioskSelect)) {
            this.fillComponent()
        }
    }

    fillComponent = () => {
        this.setState({
            kioskSelect: this.props.kioskSelect,
        })
    }

    handleOpenChange = (newOpen) => {
        this.setState({openPopOver: newOpen})
    }

    applyChange = () => {
        let kioskFilter = 0
        if (this.state.kioskSelect !== "ALL") {
            kioskFilter++
        }
        this.props.formChildAction({kioskSelect: this.state.kioskSelect, kioskFilter: kioskFilter, kioskData: this.state.KioskData})
        this.setState({kioskFilter: kioskFilter, openPopOver: false})
    }

    onFieldChange = (target, value) => {
        const currentState = {...this.state}
        switch (target) {
            case "kioskSelect":
                currentState.kioskSelect = value
                break
            default:
                break
        }
        this.setState({
            ...currentState,
        })
    }

    render() {
        return (
            <>
                <Popover
                    placement="bottom"
                    trigger="click"
                    open={this.state.openPopOver}
                    onOpenChange={this.handleOpenChange}
                    content={(
                        <div style={{width: 280}}>
                            <Row className={`mb-20`}>
                                <Col span={12}>
                                    <Button size={"small"} onClick={() => this.setState({openPopOver: false})}>Cancel</Button>
                                </Col>
                                <Col span={12} style={{textAlign: "right"}}>
                                    <Button onClick={this.applyChange} type={`primary`} size={"small"}>Apply</Button>
                                </Col>
                            </Row>
                            <Form.Item>
                                <i className={`extra-small-text-grey`}>Kiosk</i><br/>
                                <Select
                                    onChange={value => this.onFieldChange("kioskSelect", value)}
                                    value={this.state.kioskSelect}
                                    style={{width: "100%"}}
                                    placeholder={`Date`}
                                    loading={this.state.fetchKioskProcessing}
                                >
                                    <Select.Option key={`ALL`} value={`ALL`}>All Kiosk</Select.Option>
                                    {this.state.KioskData.map((item, i) => (
                                        <Select.Option key={item?.id} value={item?.id}>{item?.name} - {item?.location_name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </div>
                    )}>
                    <Card bodyStyle={{padding: 5, textAlign: "center"}} size="small" hoverable={true}>
                        <span className={`small-text-grey`}> <FilterOutlined/> ({this.state.kioskFilter}) Filter</span>
                    </Card>
                </Popover>
            </>
        )
    }
}

export default KioskFilter