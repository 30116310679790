import React, {Component} from 'react'
import Cookies from 'universal-cookie'
import {Buffer} from "buffer"
import withAuth from "../../hoc/withAuth"
import {Col, DatePicker, Row, Dropdown} from 'antd'
import {DownOutlined, FileExcelOutlined} from '@ant-design/icons'
import MainLayout from "../../components/layout/MainLayout"
import {Divider, message, Table} from "antd"
import {kioskApiService} from "../../services/wiloKiosk/KioskApiService"
import dayjs from "dayjs"
import currencyFormat from "../../utils/currencyFormat"
import exportExcel from "../../utils/exportExcel"
import exportPdf from "../../utils/exportPdf"

class LogUser extends Component {
    constructor(props) {
        super(props)
        this.cookies = new Cookies()
        this.initState = {
            token: (this.cookies.get('_wk-t')) ? JSON.parse(Buffer.from(this.cookies.get('_wk-t'), 'base64').toString()) : '',
            fetchProcessing: true,
            startDate: dayjs(),
            tableData: []
        }
        this.state = {...this.initState}
    }

    fetchData = async () => {
        try {
            await kioskApiService.setToken(this.state.token)
            const locationData = await kioskApiService.readReportPerformance({
                month: dayjs(this.state.startDate).format("YYYY-MM")
            })
            this.setState({
                fetchProcessing: false,
                tableData: locationData.data.data
            })
        } catch (e) {
            this.setState({
                fetchProcessing: false,
                tableData: [],
            })
            message.info(e)
        }
    }

    onFieldChange = async (target, value) => {
        const currentState = {...this.state}
        switch (target) {
            case "startDate":
                currentState.startDate = value
                break
            default:
                break
        }
        this.setState({
            ...currentState,
        })

        await this.fetchData()
    }

    async componentDidMount() {
        await this.fetchData()
    }

    exportData = async (type = "excel") => {
        const data = this.state.tableData
        if (data.length < 1) {
            message.info('No data available for export')
            return
        }

        if (type === "excel") {
            const xlsData = []
            xlsData.push(['Kiosk Code', 'Kiosk Name', 'Kiosk Location', 'Product Code', 'Product Name', 'Succeeded Transaction', 'Value Succeeded Transaction', 'Active Transaction', 'Expire Transaction', 'Count Transaction'])
            data.forEach(item => {
                xlsData.push([
                    item?.kiosk_code ?? "",
                    item?.kiosk_name ?? "",
                    item?.kiosk_location ?? "",
                    item?.product_code ?? "",
                    item?.product_name ?? "",
                    item?.count_succeeded_transaction ?? "",
                    currencyFormat(item?.amount_succeeded_transaction) ?? "",
                    item?.count_active_transaction ?? "",
                    item?.count_expire_transaction ?? "",
                    ((item.count_succeeded_transaction + item.count_active_transaction + item.count_expire_transaction))
                ])
            })

            await exportExcel({
                title: `Performance Report ${dayjs(this.state.startDate).format("YYYY/MM")}`,
                generatedTime: 'GenerateTime: ' + dayjs(this.state.startDate).format('YYYY-MM-DD HH:mm:ss'),
                data: xlsData,
                sheetName: "Sheet1",
                fileName: "WiloKiosk - Performance Report"
            })
        }
        if (type === "pdf") {
            const xlsData = []
            xlsData.push(['Kiosk Code', 'Kiosk Name', 'Kiosk Location', 'Product Code', 'Product Name', 'Succeeded Transaction', 'Value Succeeded Transaction', 'Active Transaction', 'Expire Transaction', 'Count Transaction'])
            data.forEach(item => {
                xlsData.push([
                    item?.kiosk_code ?? "",
                    item?.kiosk_name ?? "",
                    item?.kiosk_location ?? "",
                    item?.product_code ?? "",
                    item?.product_name ?? "",
                    item?.count_succeeded_transaction ?? "",
                    currencyFormat(item?.amount_succeeded_transaction) ?? "",
                    item?.count_active_transaction ?? "",
                    item?.count_expire_transaction ?? "",
                    ((item.count_succeeded_transaction + item.count_active_transaction + item.count_expire_transaction))
                ])
            })

            await exportPdf({
                title: `Performance Report ${dayjs(this.state.startDate).format("YYYY/MM")}`,
                generatedTime: 'GenerateTime: ' + dayjs(this.state.startDate).format('YYYY-MM-DD HH:mm:ss'),
                data: xlsData,
                sheetName: "Sheet1",
                fileName: "WiloKiosk - Performance Report"
            })
        }
    }

    render() {
        const items = [
            {key: '1', label: (<span onClick={() => this.exportData("excel")}><FileExcelOutlined/> Export Excel</span>)},
            {key: '2', label: (<span onClick={() => this.exportData("pdf")}><FileExcelOutlined/> Export PDF</span>)}
        ]

        return (
            <MainLayout titlePage={`Sales Performance`} fullWidth={true} breadcrumbPath={`Report`} breadcrumbMenu={`/ Sales Performance`} defaultSelectedKeys={['m-report-performance']} defaultOpenKeys={['sm-report']}>
                <div className={`m-10`}>
                    <Row>
                        <Col xl={{span: 4, order: 2}} xs={{span: 24, order: 3}} className={`p-5`}>
                            <i className={`extra-small-text-grey`}> Month Period</i><br/>
                            <DatePicker picker="month" defaultValue={dayjs(this.state.startDate, 'YYYY-MM')} onChange={event => this.onFieldChange("startDate", event)} format={'YYYY-MM'}/>
                        </Col>
                        <Col xl={{span: 16, order: 3}} xs={{span: 24, order: 4}} className={`p-5`}/>
                        <Col xl={{span: 4, order: 4}} xs={{span: 24, order: 1}} className={`p-5`} order={1}>
                            <div style={{float: 'right'}}>
                                <Dropdown.Button
                                    type="primary"
                                    onClick={event => this.exportData("excel")}
                                    icon={<DownOutlined/>}
                                    trigger={['click']}
                                    menu={{items}}
                                ><FileExcelOutlined/></Dropdown.Button>
                            </div>
                        </Col>
                    </Row>
                </div>

                <Divider className={`divider-thin`}/>
                <Table
                    size="small"
                    rowKey={`key`}
                    pagination={false}
                    scroll={{x: 'calc(500px + 50%)'}}
                    dataSource={this.state.tableData}
                    loading={this.state.fetchProcessing}
                    columns={[
                        {
                            title: 'Kiosk Code - Name',
                            dataIndex: 'kiosk_code',
                            render: ((value, row) => {
                                return <>{row.kiosk_name} ({value}) <br/><i className={`extra-small-text-grey`}> {row.kiosk_location}</i></>
                            })
                        },
                        {
                            title: 'Product Code - Name',
                            dataIndex: 'product_code',
                            render: ((value, row) => {
                                return <>{row.product_name} ({value})</>
                            })
                        },
                        {
                            title: 'Succeeded Transaction',
                            dataIndex: 'count_active_transaction',
                            render: ((value, row) => {
                                return <>{(row.count_succeeded_transaction)}</>
                            })
                        },
                        {
                            title: 'Value Succeeded Transaction',
                            dataIndex: 'count_active_transaction',
                            render: ((value, row) => {
                                return <>{(currencyFormat(row.amount_succeeded_transaction))}</>
                            })
                        },
                        {
                            title: 'Active Transaction',
                            dataIndex: 'count_active_transaction',
                            render: ((value, row) => {
                                return <>{(row.count_active_transaction)}</>
                            })
                        },
                        {
                            title: 'Expire Transaction',
                            dataIndex: 'count_active_transaction',
                            render: ((value, row) => {
                                return <>{(row.count_expire_transaction)}</>
                            })
                        },
                        {
                            title: 'Count Transaction',
                            dataIndex: 'count_active_transaction',
                            render: ((value, row) => {
                                return <>{(row.count_succeeded_transaction + row.count_active_transaction + row.count_expire_transaction)}</>
                            })
                        },
                    ]}
                />
            </MainLayout>
        )
    }
}

export default withAuth(LogUser, 4)