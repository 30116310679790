import React, {Component} from 'react'
import Cookies from 'universal-cookie'
import {Buffer} from "buffer"
import withAuth from "../../hoc/withAuth"
import {UserAddOutlined, EditOutlined, MoreOutlined} from '@ant-design/icons'
import MainLayout from "../../components/layout/MainLayout"
import {Button, Dropdown, message, Modal, Table} from "antd"
import {kioskApiService} from "../../services/wiloKiosk/KioskApiService"
import MasterUserForm from "../../components/form/MasterUserForm"
import MasterUserMenu from "../../components/form/MasterUserMenu";

class MasterUser extends Component {
    constructor(props) {
        super(props)
        this.cookies = new Cookies()
        this.initState = {
            token: (this.cookies.get('_wk-t')) ? JSON.parse(Buffer.from(this.cookies.get('_wk-t'), 'base64').toString()) : '',
            fetchProcessing: true,
            tableData: [],
            userUpdate: {},
            showModalForm: false,
            showModalMenu: false,
            modalTitle: "",
            modalType: "",
        }
        this.state = {...this.initState}
    }

    fetchData = async () => {
        try {
            await kioskApiService.setToken(this.state.token)
            const userData = await kioskApiService.readMasterUser()
            this.setState({
                fetchProcessing: false,
                tableData: userData.data.data
            })
        } catch (e) {
            this.setState({
                processing: false,
                tableData: [],
            })
            message.info(e)
        }
    }

    formChildAction = (data) => {
        if (data.reloadData) {
            this.fetchData()
        }
        if (data.closeModal) {
            this.setState({showModalForm: false, showModalMenu: false})
        }
    }

    async componentDidMount() {
        await this.fetchData()
    }

    render() {
        return (
            <MainLayout titlePage={`Master User`} fullWidth={true} breadcrumbPath={`Setting`} breadcrumbMenu={`/ Master User`} defaultSelectedKeys={['m-master-user']} defaultOpenKeys={['sm-setting']}>
                <Button className={`m-10`} type={`primary`} onClick={() => this.setState({userUpdate: {active: true}, showModalForm: true, modalTitle: "Register New User", modalType: "CREATE"})}> <UserAddOutlined/> Register New User</Button>
                <Table
                    size="small"
                    rowKey={`email`}
                    scroll={{x: 'calc(500px + 50%)'}}
                    dataSource={this.state.tableData}
                    loading={this.state.fetchProcessing}
                    columns={[
                        {
                            title: 'Name',
                            dataIndex: 'name',
                            width: 320,
                            render: ((value, row) => {
                                return <>{value}</>
                            })
                        }, {
                            title: 'Email',
                            dataIndex: 'email',
                            width: 320,
                            render: ((value, row) => {
                                return <>{value}</>
                            })
                        }, {
                            title: 'Phone',
                            dataIndex: 'phone_number',
                            width: 320,
                            render: ((value, row) => {
                                return <>{value}</>
                            })
                        }, {
                            title: 'Status',
                            dataIndex: 'active',
                            width: 320,
                            render: ((value, row) => {
                                return <>{(value.toString() === "true") ? "Enable" : "Disable"}</>
                            })
                        }, {
                            title: 'Action',
                            dataIndex: 'id',
                            align: "center",
                            fixed: 'right',
                            width: 100,
                            render: ((value, row) => {
                                const items = [
                                    {key: '1', label: (<span onClick={() => this.setState({userUpdate: row, showModalForm: true, modalTitle: "Update User", modalType: "UPDATE"})}> <EditOutlined/> Update User</span>)},
                                    {key: '2', label: (<span onClick={() => this.setState({userUpdate: row, showModalMenu: true, modalTitle: "Update Access Menu", modalType: "UPDATE"})}> <EditOutlined/> Update Access Menu</span>)},
                                ]
                                return <>
                                    <span className={`font-90`}>
                                        <Dropdown menu={{items}} placement="bottomLeft" arrow>
                                            <MoreOutlined style={{fontWeight: "bolder", cursor: "pointer"}}/>
                                        </Dropdown>
                                    </span>
                                </>
                            })
                        }
                    ]}
                />

                <Modal
                    open={this.state.showModalForm}
                    title={this.state.modalTitle}
                    style={{top: 20}}
                    onCancel={() => this.setState({showModalForm: false})}
                    footer={[]}
                >
                    <MasterUserForm formChildAction={this.formChildAction} type={this.state.modalType} userData={this.state.userUpdate} token={this.state.token}/>
                </Modal>

                <Modal
                    open={this.state.showModalMenu}
                    title={this.state.modalTitle}
                    style={{top: 20}}
                    onCancel={() => this.setState({showModalMenu: false})}
                    footer={[]}
                >
                    <MasterUserMenu formChildAction={this.formChildAction} userData={this.state.userUpdate} token={this.state.token}/>
                </Modal>
            </MainLayout>
        )
    }
}

export default withAuth(MasterUser, 13)