import React, {Component} from 'react'
import {Button, Divider, Form, Input, Modal, Select} from "antd"
import {kioskApiService} from "../../services/wiloKiosk/KioskApiService"
import CropBackgroundImage from "../../components/general/CropBackgroundImage"

class MasterAdForm extends Component {
    constructor(props) {
        super(props)
        this.initState = {
            processing: false,
            type: "",
            title: "",
            description: "",
            contentUrl: "",
            activeStatus: "ACTIVE",
            typeAd: "VIDEO",
            base64Image: "",
        }
        this.state = {...this.initState}
    }

    onFieldChange = (target, value) => {
        const currentState = {...this.state}
        switch (target) {
            case "title":
                currentState.title = value
                break
            case "description":
                currentState.description = value
                break
            case "contentUrl":
                currentState.contentUrl = value
                break
            case "activeStatus":
                currentState.activeStatus = value
                break
            case "typeAd":
                currentState.typeAd = value
                break
            default:
                break
        }
        this.setState({
            ...currentState,
        })
    }

    componentDidMount() {
        this.fillComponent()
    }

    componentDidUpdate(prevProps) {
        if (this.props.type !== prevProps.type || (this.props.adData && this.props.adData.title !== prevProps.adData?.title)) {
            this.fillComponent()
        }
    }

    fillComponent = () => {
        this.setState({
            processing: false,
            type: this.props.type,
            typeAd: this.props.adData.type ?? "VIDEO",
            title: this.props.adData.title ?? "",
            description: this.props.adData.description ?? "",
            contentUrl: this.props.adData.content_url ?? "",
            base64Image: "",
        })
    }

    closeAction = () => {
        this.props.formChildAction({closeModal: true, reloadData: false})
    }

    saveAction = async () => {
        const {title, description, type, activeStatus, typeAd, contentUrl} = this.state
        if (!title.trim() || !description.trim()) {
            return Modal.error({title: 'Error', content: 'Please fill in all required fields'})
        }

        await kioskApiService.setToken(this.props.token)
        if (type === "CREATE") {

            if (typeAd === "IMAGE") {
                if (this.state.base64Image.length < 10) {
                    return Modal.error({title: 'Error', content: 'Image Ads Required'})
                }
            } else {
                if (!contentUrl) {
                    return Modal.error({title: 'Error', content: 'Please fill in all required fields'})
                }
            }

            try {
                const dataCreate = {
                    title: title.trim(),
                    description: description.trim(),
                    type: typeAd,
                    content_url: contentUrl?.trim(),
                    active: (activeStatus === "ACTIVE"),
                }
                if (typeAd === "IMAGE") {
                    dataCreate.content_image = this.state.base64Image
                }
                await kioskApiService.createMasterAds(dataCreate)
                Modal.success({title: 'Register Success', content: 'Register new ad Success'})
            } catch (e) {
                this.setState({processing: false})
                return Modal.error({title: 'Register Failed', content: e})
            }
        } else if (type === "UPDATE") {
            let updateData = {}
            if (this.state.typeAd === "VIDEO") {
                updateData = {
                    id: this.props.adData.id,
                    title: title.trim(),
                    description: description.trim(),
                    type: typeAd,
                    content_url: contentUrl.trim(),
                    active: (activeStatus === "ACTIVE")
                }
            } else {
                updateData = {
                    id: this.props.adData.id,
                    active: (activeStatus === "ACTIVE")
                }
            }

            try {
                await kioskApiService.updateMasterAds(updateData)
                Modal.success({title: 'Update Success', content: 'Update new ad Success'})
            } catch (e) {
                this.setState({processing: false})
                return Modal.error({title: 'Register Failed', content: e})
            }
        } else {
            return Modal.error({title: 'App Error', content: "Error, Please reload this page"})
        }

        this.props.formChildAction({closeModal: true, reloadData: true})
    }

    onCropAdImage = async (base64DataImagePng) => {
        await this.setState({
            base64Image: base64DataImagePng,
        })
    }

    render() {
        return (
            <>
                <Divider/>
                <Form.Item>
                    <i className={`small-text-grey`}>Title</i><br/>
                    <Input value={this.state.title} placeholder={`Input Title`} onChange={event => this.onFieldChange("title", event.target.value)}/>
                </Form.Item>
                <Form.Item>
                    <i className={`small-text-grey`}>Description</i><br/>
                    <Input value={this.state.description} placeholder={`Input Description`} onChange={event => this.onFieldChange("description", event.target.value)}/>
                </Form.Item>
                <Form.Item>
                    <i className={`small-text-grey`}>Type</i><br/>
                    <Select
                        onChange={value => this.onFieldChange("typeAd", value)}
                        value={this.state.typeAd}
                        style={{width: "100%"}}
                        placeholder={`Type`}
                        disabled={(this.props.type === "UPDATE")}
                    >
                        <Select.Option key={`VIDEO`} value={`VIDEO`}>{`VIDEO`}</Select.Option>
                        <Select.Option key={`IMAGE`} value={`IMAGE`}>{`IMAGE`}</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item style={(this.state.typeAd === "IMAGE") ? {display: 'none'} : {}}>
                    <i className={`small-text-grey`}>Content URL</i><br/>
                    <Input value={this.state.contentUrl} placeholder={`Input URL Content`} onChange={event => this.onFieldChange("contentUrl", event.target.value)}/>
                </Form.Item>
                <Form.Item>
                    <i className={`small-text-grey`}>Active Status</i><br/>
                    <Select
                        onChange={value => this.onFieldChange("activeStatus", value)}
                        value={this.state.activeStatus}
                        style={{width: "100%"}}
                        placeholder={`User Active`}
                    >
                        <Select.Option key={`ACTIVE`} value={`ACTIVE`}>{`Enable`}</Select.Option>
                        <Select.Option key={`UNACTIVE`} value={`UNACTIVE`}>{`Disable`}</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item style={(this.props.type === "CREATE" && this.state.typeAd === "IMAGE") ? {} : {display: 'none'}}>
                    <i className={`small-text-grey`}>Upload Image</i><br/>
                    <CropBackgroundImage params={this.state} aspectRatio={16 / 9} onCropImage={this.onCropAdImage}/>
                </Form.Item>
                <Divider/>
                <div style={{textAlign: "right"}}>
                    <Button className={`m-5`} onClick={this.closeAction}> Cancel</Button>
                    <Button className={`m-5`} onClick={this.saveAction} loading={this.state.processing} type={'primary'}> Save</Button>
                </div>
            </>
        )
    }
}

export default MasterAdForm