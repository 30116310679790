import React, {Component} from 'react'
import Cookies from 'universal-cookie'
import {Buffer} from "buffer"
import withAuth from "../../hoc/withAuth"
import {EditOutlined, MoreOutlined, WarningOutlined} from '@ant-design/icons'
import MainLayout from "../../components/layout/MainLayout"
import {Button, Divider, Dropdown, message, Modal, Table} from "antd"
import {kioskApiService} from "../../services/wiloKiosk/KioskApiService"
import MasterKioskForm from "../../components/form/MasterKioskForm"
import MasterKioskReminderForm from "../../components/form/MasterKioskReminderForm"
import dayjs from "dayjs";

class MasterKiosk extends Component {
    constructor(props) {
        super(props)
        this.cookies = new Cookies()
        this.initState = {
            token: (this.cookies.get('_wk-t')) ? JSON.parse(Buffer.from(this.cookies.get('_wk-t'), 'base64').toString()) : '',
            fetchProcessing: true,
            tableData: [],
            kioskUpdate: {},
            showModalForm: false,
            showModalForm2: false,
            showModalForm3: false,
            resetCode: "",
            loadingReset: false,
            modalTitle: "",
            modalType: "",
        }
        this.state = {...this.initState}
    }

    fetchData = async () => {
        try {
            await kioskApiService.setToken(this.state.token)
            const kioskData = await kioskApiService.readMasterKiosk()
            this.setState({
                fetchProcessing: false,
                tableData: kioskData.data.data
            })
        } catch (e) {
            this.setState({
                fetchProcessing: false,
                tableData: [],
            })
            message.info(e)
        }
    }

    formChildAction = (data) => {
        if (data.reloadData) {
            this.fetchData()
        }
        if (data.closeModal) {
            this.setState({showModalForm: false, showModalForm2: false})
        }
    }

    confirmResetIoT = async () => {
        await this.promisedSetState({
            loadingReset: true,
        })

        try {
            await kioskApiService.resetIotValue({
                code: this.state.resetCode
            })
            Modal.success({title: 'Reset Success', content: 'Reset Success'})
            await this.fetchData()
            await this.setState({
                loadingReset: false,
                showModalForm3: false
            })
        } catch (e) {
            this.setState({loadingReset: false})
            return Modal.error({title: 'Reset Failed', content: e})
        }
    }

    async componentDidMount() {
        await this.fetchData()
    }

    promisedSetState = (newState) => new Promise((resolve) => this.setState(newState, resolve))

    render() {
        return (
            <MainLayout titlePage={`Master Kiosk`} fullWidth={true} breadcrumbPath={`Setting`} breadcrumbMenu={`/ Master Kiosk`} defaultSelectedKeys={['m-master-kiosk']} defaultOpenKeys={['sm-setting']}>
                <Table
                    size="small"
                    rowKey={`code`}
                    scroll={{x: 'calc(500px + 50%)'}}
                    dataSource={this.state.tableData}
                    loading={this.state.fetchProcessing}
                    columns={[
                        {
                            title: 'Code',
                            dataIndex: 'code',
                            width: 320,
                            render: ((value, row) => {
                                return <>{value}</>
                            })
                        }, {
                            title: 'Name',
                            dataIndex: 'name',
                            width: 320,
                            render: ((value, row) => {
                                return <>{value}<br/><span className={`small-text-grey`}>{row.location_name}</span></>
                            })
                        }, {
                            title: 'Filter Reminder',
                            dataIndex: 'reminder_filter',
                            width: 320,
                            render: ((value, row) => {
                                let daysLeft = dayjs((value?.substring(0, 10))).diff(dayjs(), 'day')
                                daysLeft = (daysLeft < 1) ? "NEED REPLACE" : daysLeft + " days"

                                return <>
                                    {value?.substring(0, 10)}<br/><span className={`small-text-grey`} style={{paddingRight: 10}}>Replacement Within</span>
                                    {daysLeft}
                                </>
                            })
                        }, {
                            title: 'Status',
                            dataIndex: 'active',
                            width: 120,
                            render: ((value, row) => {
                                return <>{(value.toString() === "true") ? "Enable" : "Disable"}</>
                            })
                        }, {
                            title: 'Action',
                            dataIndex: 'id',
                            align: "center",
                            fixed: 'right',
                            width: 100,
                            render: ((value, row) => {
                                const items = [
                                    {key: '1', label: (<div onClick={() => this.setState({kioskUpdate: row, showModalForm: true, modalTitle: "Update Kiosk", modalType: "UPDATE"})}><EditOutlined/> Update Kiosk</div>)},
                                    {key: '2', label: (<div onClick={() => this.setState({kioskUpdate: row, showModalForm2: true})}><EditOutlined/> Update Filter Reminder</div>)},
                                    {key: '3', label: (<div onClick={() => this.setState({showModalForm3: true, resetCode: row.code})}><WarningOutlined/> Reset IoT Value</div>)},
                                ]
                                return <>
                                    <span className={`font-90`}>
                                        <Dropdown menu={{items}} placement="bottomLeft" arrow>
                                            <MoreOutlined style={{fontWeight: "bolder", cursor: "pointer"}}/>
                                        </Dropdown>
                                    </span>
                                </>
                            })
                        }
                    ]}
                />

                <Modal
                    open={this.state.showModalForm}
                    title={this.state.modalTitle}
                    style={{top: 20}}
                    onCancel={() => this.setState({showModalForm: false})}
                    footer={[]}
                >
                    <MasterKioskForm formChildAction={this.formChildAction} type={this.state.modalType} kioskData={this.state.kioskUpdate} token={this.state.token}/>
                </Modal>

                <Modal
                    open={this.state.showModalForm2}
                    title={"Update Filter Reminder"}
                    style={{top: 20}}
                    onCancel={() => this.setState({showModalForm2: false})}
                    footer={[]}
                >
                    <MasterKioskReminderForm formChildAction={this.formChildAction} type={this.state.modalType} kioskData={this.state.kioskUpdate} token={this.state.token}/>
                </Modal>

                <Modal
                    open={this.state.showModalForm3}
                    title={"Reset IoT Kiosk Value"}
                    style={{top: 20}}
                    onCancel={() => this.setState({showModalForm3: false})}
                    footer={[
                        <Button loading={this.state.loadingReset} type={"primary"} danger={true} key="1" onClick={this.confirmResetIoT}>Reset</Button>,
                        <Button key="2" onClick={() => this.setState({showModalForm3: false})}>Close</Button>,
                    ]}
                >
                    <Divider/>
                    Are you sure you want to Reset IoT Kiosk Value?
                    <Divider/>
                </Modal>
            </MainLayout>
        )
    }
}

export default withAuth(MasterKiosk, 10)