import React, {Component} from 'react'
import {Button, Divider, Form, Input, Modal, Select} from "antd"
import {kioskApiService} from "../../services/wiloKiosk/KioskApiService"

class MasterUserForm extends Component {
    constructor(props) {
        super(props)
        this.initState = {
            processing: false,
            type: "",
            name: "",
            email: "",
            phoneNumber: "",
            activeStatus: "ACTIVE",
            password: "",
            reTypePassword: "",
        }
        this.state = {...this.initState}
    }

    onFieldChange = (target, value) => {
        const currentState = {...this.state}
        switch (target) {
            case "name":
                currentState.name = value
                break
            case "email":
                currentState.email = value
                break
            case "phoneNumber":
                currentState.phoneNumber = value
                break
            case "activeStatus":
                currentState.activeStatus = value
                break
            case "password":
                currentState.password = value
                break
            case "reTypePassword":
                currentState.reTypePassword = value
                break
            default:
                break
        }
        this.setState({
            ...currentState,
        })
    }

    componentDidMount() {
        this.fillComponent()
    }

    componentDidUpdate(prevProps) {
        if (this.props.type !== prevProps.type || (this.props.userData && this.props.userData.email !== prevProps.userData?.email)) {
            this.fillComponent()
        }
    }

    fillComponent = () => {
        this.setState({
            processing: false,
            type: this.props.type,
            name: this.props.userData.name ?? "",
            email: this.props.userData.email ?? "",
            phoneNumber: this.props.userData.phone_number ?? "",
            activeStatus: (!this.props.userData.active) ? "UNACTIVE" : "ACTIVE",
            password: "",
            reTypePassword: "",
        })
    }

    closeAction = () => {
        this.props.formChildAction({closeModal: true, reloadData: false})
    }

    saveAction = async () => {
        const {name, email, phoneNumber, type, password, reTypePassword, activeStatus} = this.state
        if (!name.trim() || !email.trim() || !phoneNumber.trim()) {
            return Modal.error({title: 'Error', content: 'Please fill in all required fields'})
        }
        if (type === "CREATE") {
            if (!password.trim() || !reTypePassword.trim()) {
                return Modal.error({title: 'Error', content: 'Please fill in all required fields'})
            }
            if (password.trim() !== reTypePassword.trim()) {
                return Modal.error({title: 'error', content: 'The new password and the retyped new password do not match'})
            }
            if (password.trim().length < 8) {
                return Modal.error({title: 'Update Failed', content: 'Minimum password length is 8 characters'})
            }
        }

        await kioskApiService.setToken(this.props.token)
        if (type === "CREATE") {
            try {
                await kioskApiService.createMasterUser({
                    email: email.trim(),
                    password: password.trim(),
                    name: name.trim(),
                    phone_number: phoneNumber.trim()
                })
                Modal.success({title: 'Register Success', content: 'Register new user Success'})
            } catch (e) {
                this.setState({processing: false})
                return Modal.error({title: 'Register Failed', content: e})
            }
        } else if (type === "UPDATE") {
            try {
                await kioskApiService.updateMasterUser({
                    id: this.props.userData.id,
                    email: email.trim(),
                    name: name.trim(),
                    phone_number: phoneNumber.trim(),
                    active: (activeStatus === "ACTIVE")
                })
                Modal.success({title: 'Update Success', content: 'Update user Success'})
            } catch (e) {
                this.setState({processing: false})
                return Modal.error({title: 'Update Failed', content: e})
            }
        } else {
            return Modal.error({title: 'App Error', content: "Error, Please reload this page"})
        }

        this.props.formChildAction({closeModal: true, reloadData: true})
    }

    render() {
        return (
            <>
                <Divider/>
                <Form.Item>
                    <i className={`small-text-grey`}>Email</i><br/>
                    <Input disabled={(this.props.type === "UPDATE")} type={'email'} value={this.state.email} placeholder={`User Email`} onChange={event => this.onFieldChange("email", event.target.value)}/>
                </Form.Item>
                <Form.Item>
                    <i className={`small-text-grey`}>Name</i><br/>
                    <Input value={this.state.name} placeholder={`User Name`} onChange={event => this.onFieldChange("name", event.target.value)}/>
                </Form.Item>
                <Form.Item>
                    <i className={`small-text-grey`}>Phone Number</i><br/>
                    <Input value={this.state.phoneNumber} placeholder={`User Phone Number`} onChange={event => this.onFieldChange("phoneNumber", event.target.value)}/>
                </Form.Item>
                <Form.Item style={this.props.type === "CREATE" ? {} : {display: 'none'}}>
                    <i className={`small-text-grey`}>Password</i><br/>
                    <Input type={`password`} value={this.state.password} placeholder={`User Password`} onChange={event => this.onFieldChange("password", event.target.value)}/>
                </Form.Item>
                <Form.Item style={this.props.type === "CREATE" ? {} : {display: 'none'}}>
                    <i className={`small-text-grey`}>Retype Password</i><br/>
                    <Input type={`password`} value={this.state.reTypePassword} placeholder={`ReType Password`} onChange={event => this.onFieldChange("reTypePassword", event.target.value)}/>
                </Form.Item>
                <Form.Item>
                    <i className={`small-text-grey`}>Active Status</i><br/>
                    <Select
                        onChange={value => this.onFieldChange("activeStatus", value)}
                        value={this.state.activeStatus}
                        style={{width: "100%"}}
                        placeholder={`User Active`}
                    >
                        <Select.Option key={`ACTIVE`} value={`ACTIVE`}>{`Enable`}</Select.Option>
                        <Select.Option key={`UNACTIVE`} value={`UNACTIVE`}>{`Disable`}</Select.Option>
                    </Select>
                </Form.Item>
                <Divider/>
                <div style={{textAlign: "right"}}>
                    <Button className={`m-5`} onClick={this.closeAction}> Cancel</Button>
                    <Button className={`m-5`} onClick={this.saveAction} loading={this.state.processing} type={'primary'}> Save</Button>
                </div>
            </>
        )
    }
}

export default MasterUserForm